import { Injectable } from '@angular/core';
import { DiscoverFilteringOption, DiscoverSortingOption } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { DiscoverShopsFiltersDialogResponse } from './discover-shops-filters-dialog.types';

@Injectable()
export class DiscoverShopsFiltersDialogService {
  // We need to maintain this state since, the shop filtering is applied, when we submit the dialog.
  private readonly sortingOptionsSource = new BehaviorSubject<DiscoverSortingOption[]>([]);
  private readonly filteringOptionsSource = new BehaviorSubject<DiscoverFilteringOption[]>([]);

  public readonly sortingOptions$ = this.sortingOptionsSource.asObservable();
  public readonly filteringOptions$ = this.filteringOptionsSource.asObservable();

  public setSortingOptions(sortingOptions: DiscoverSortingOption[]): void {
    this.sortingOptionsSource.next(sortingOptions);
  }

  public getSortingOptions(): DiscoverSortingOption[] {
    return this.sortingOptionsSource.getValue();
  }

  public setFilteringOptions(filteringOptions: DiscoverFilteringOption[]): void {
    this.filteringOptionsSource.next(filteringOptions);
  }

  public getFilteringOptions(): DiscoverFilteringOption[] {
    return this.filteringOptionsSource.getValue();
  }

  public selectSortingOption(option: DiscoverSortingOption): void {
    const currentOptions = this.sortingOptionsSource.getValue();
    this.sortingOptionsSource.next(currentOptions.map((o) => ({ ...o, checked: o.slug === option.slug })));
  }

  public changeFilteringOption(option: DiscoverFilteringOption, checked: boolean): void {
    const currentOptions = this.filteringOptionsSource.getValue();
    const currentOption: DiscoverFilteringOption = currentOptions.find((o) => o.slug === option.slug);
    if (currentOption) currentOption.checked = checked;
    this.setFilteringOptions(currentOptions);
  }

  public getDialogResponse(): DiscoverShopsFiltersDialogResponse {
    const sortingOptions = this.sortingOptionsSource.getValue();
    const filteringOptions = this.filteringOptionsSource.getValue();
    return {
      selectedSortingOption: sortingOptions.find((option) => option.checked),
      selectedFilteringOptions: filteringOptions.filter((option) => option.checked)
    };
  }
}
