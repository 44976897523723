<div class="discover-search-result-items-shop">
  <shop-tile-compact [shop]="shop" (click)="onShopCardClick(shop)" class="hoverable"></shop-tile-compact>
</div>
<div *ngIf="items" class="discover-search-result-items-container">
  <discover-search-result-item
    *ngFor="let item of firstGroup"
    [item]="item"
    (click)="onItemClick(item)"
    (add)="onItemClick($event)"
    class="hoverable"
  ></discover-search-result-item>
  <ng-container *ngIf="expanded">
    <discover-search-result-item
      *ngFor="let item of secondGroup"
      [item]="item"
      (click)="onItemClick(item)"
      (add)="onItemClick($event)"
      class="hoverable"
    ></discover-search-result-item>
  </ng-container>
</div>
<div class="discover-search-result-items-buttons">
  <button
    *ngIf="showExpansionButton && !expanded"
    box-button
    theme="insignificant"
    class="discover-search-result-items-button"
    size="small"
    (click)="onExpansionToggle()"
  >
    {{ expandButtonText | translate }}
  </button>
  <button
    *ngIf="showShopButton && expanded"
    box-button
    theme="insignificant"
    class="discover-search-result-items-button"
    size="small"
    [routerLink]="['/delivery', shop.locationKey, shop.vanity_url]"
  >
    {{ 'see_the_shop' | translate }}
  </button>
</div>
