import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DiscoverSearchService } from '@box-discover/services';
import { DiscoverSearchSubCategoryType, DiscoverSearchResults, BOXNavOptions } from '@box-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'discover-search-navigation',
  templateUrl: './discover-search-navigation.component.html',
  styleUrls: ['./discover-search-navigation.component.scss']
})
export class DiscoverSearchNavigationComponent implements OnInit, OnDestroy {
  public navOptions: BOXNavOptions = { separators: false };
  @HostBinding('class') public hostClass = 'discover-search-navigation';

  public shopsCount: number;
  public productsCount: number;
  public offersCount: number;
  public selectedSubCategory: DiscoverSearchSubCategoryType;
  public searchResults: DiscoverSearchResults;
  public showOnlyShops: boolean;

  private subCategorySubscription: Subscription;
  private searchResultsSubscription: Subscription;
  private searchShowOnlyShopsSubscription: Subscription;

  constructor(private discoverSearchService: DiscoverSearchService) {}

  ngOnInit(): void {
    this.selectedSubCategory = 'shops';
    this.setSubCategorySubscription();
    this.setSearchResultsSubscription();
    this.setSearchShowOnlyShopsSubscription();
  }

  ngOnDestroy(): void {
    if (this.subCategorySubscription) this.subCategorySubscription.unsubscribe();
    if (this.searchResultsSubscription) this.searchResultsSubscription.unsubscribe();
    if (this.searchShowOnlyShopsSubscription) this.searchShowOnlyShopsSubscription.unsubscribe();
  }

  public onSubCategoryChange(subCategory: DiscoverSearchSubCategoryType): void {
    this.discoverSearchService.setSubCategory(subCategory);
  }

  private setSubCategorySubscription(): void {
    this.subCategorySubscription = this.discoverSearchService.subCategory.subscribe(
      (subCategory) => (this.selectedSubCategory = subCategory)
    );
  }

  private setSearchResultsSubscription(): void {
    this.searchResultsSubscription = this.discoverSearchService.searchResults.subscribe((searchResults) => {
      this.searchResults = searchResults;
      this.shopsCount = searchResults?.shopResults.length || 0;
      this.productsCount = searchResults.productsCount;
      this.offersCount = searchResults.offersCount;
    });
  }

  private setSearchShowOnlyShopsSubscription(): void {
    this.searchShowOnlyShopsSubscription = this.discoverSearchService.showOnlyShops.subscribe((showOnlyShops) => {
      this.showOnlyShops = showOnlyShops;
      if (showOnlyShops && this.selectedSubCategory !== 'shops') this.discoverSearchService.setSubCategory('shops');
    });
  }
}
