import { Component, OnChanges, SimpleChanges, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';

const DEFAULT_VOLUME = 0;
const DEFAULT_MAX_VOLUME = 100;

@Component({
  selector: 'market-cart-volume',
  templateUrl: './market-cart-volume.component.html',
  styleUrls: ['./market-cart-volume.component.scss']
})
export class MarketCartVolumeComponent implements OnChanges {
  @ViewChild('volumeBar', { static: true })
  private volumeBar: ElementRef<HTMLDivElement>;
  @Input() public volume = DEFAULT_VOLUME;
  @Input() public maxVolume = DEFAULT_MAX_VOLUME;

  public volumeBarPercentage: number;
  public volumeBarClass: string;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.volume) this.volume = changes.volume.currentValue || DEFAULT_VOLUME;
    if (changes.maxVolume) this.maxVolume = changes.maxVolume.currentValue || DEFAULT_MAX_VOLUME;
    this.volumeBarPercentage = this.getVolumePercentage();
    this.handleVolumeBarWidth();
    this.volumeBarClass = this.getVolumeBarClass(this.volumeBarPercentage);
  }

  private getVolumePercentage(): number {
    const percentage: number = (this.volume / this.maxVolume) * 100;
    if (percentage > 100) return 100;
    return percentage;
  }

  private getVolumeBarClass(percentage: number): string {
    if (percentage < 90) return 'low-volume';
    if (percentage < 99) return 'medium-volume';
    return 'full-volume';
  }

  private handleVolumeBarWidth(): void {
    this.renderer.setStyle(this.volumeBar.nativeElement, 'width', this.volumeBarPercentage + '%');
  }
}
