import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { DiscoverTilesService } from './discover-tiles.service';
import { DiscoverTilesGroup, DiscoverTilesOptions, getCollectionDiscoverCuisinesImpressionGAConfig } from '@box/utils';
import { SwiperOptions } from 'swiper/types';
import { MatButton } from '@angular/material/button';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Mousewheel, Navigation } from 'swiper/modules';
import { AnalyticsService } from '@box-core/services';
import { BusinessVertical, CollapsibleTile, CollapsibleTileSize } from '@box-types';
import { LocationsService } from '@box-delivery/services';

@Component({
  selector: 'discover-tiles',
  templateUrl: './discover-tiles.component.html',
  styleUrls: ['./discover-tiles.component.scss'],
  providers: [DiscoverTilesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOut', [
      state('void', style({ opacity: 0 })),
      transition('* => void', [animate('250ms cubic-bezier(0.4, 0, 1, 1)'), style({ opacity: 1 })])
    ])
  ]
})
export class DiscoverTilesComponent<T> implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public tiles: CollapsibleTile<T>[];
  @Input() public options: DiscoverTilesOptions;
  /**
   * We need it for the location-page
   * @type {BusinessVertical}
   */
  @Input() public businessVertical: BusinessVertical;

  @Output() private swiperNavigation = new EventEmitter<string>();
  @Output() private tileToggle = new EventEmitter<CollapsibleTile<T>>();

  public title: string;
  public groupRows: number;
  public groups: DiscoverTilesGroup<T>[];
  public activeTile: CollapsibleTile<T>;
  public size: CollapsibleTileSize;
  public swiperOptions: SwiperOptions;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private renderer: Renderer2,
    private discoverTilesService: DiscoverTilesService,
    private analyticsService: AnalyticsService,
    private locationsService: LocationsService
  ) {}

  @HostBinding('class') public hostClass = 'discover-tiles';

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    this.setNavButtonContainerVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tiles) {
      this.tiles = changes.tiles.currentValue as CollapsibleTile[];
      const options: DiscoverTilesOptions = { ...this.options, activeTileKey: this.activeTile?.key };
      this.setInitialActiveTile(options);
    }
    if (changes.options) {
      this.options = changes.options.currentValue as DiscoverTilesOptions;
      this.title = this.options?.title;
      this.groupRows = this.options?.groupRows;
      this.size = this.options?.size ?? 'large';
      this.setInitialActiveTile(this.options);
    }
    this.groups = this.discoverTilesService.generateDiscoverTilesGroups<T>(this.tiles, this.groupRows);
  }

  public trackByKey(index: number, item: CollapsibleTile<T>): string {
    return item.key;
  }

  public onPrevious(): void {
    this.swiperNavigation.emit('Left');
  }

  public onNext(): void {
    this.swiperNavigation.emit('Right');
  }

  public onTileToggle(tile: CollapsibleTile<T>): void {
    if (tile.disabled) {
      this.activeTile = null;
      this.setNavigationHiddenValue(false);
      return;
    }
    if (this.activeTile === tile) {
      this.activeTile = null;
      this.setNavigationHiddenValue(false);
    } else {
      this.activeTile = tile;
      this.setNavigationHiddenValue(true);
      this.triggerAnalyticsEvent('select_promotion', tile);
    }
    this.tileToggle.emit(this.activeTile);
  }

  public onTileEnteredViewport(tile: CollapsibleTile<T>): void {
    this.triggerAnalyticsEvent('view_promotion', tile);
  }

  public trackByGroup(index: number, group: DiscoverTilesGroup<T>): string {
    const keys = group.tiles.flatMap((tile) => tile.key);
    return keys.join('');
  }

  private setNavigationHiddenValue(hidden: boolean): void {
    const nextEl = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.renderer.setAttribute(nextEl, 'hidden', String(hidden));
    this.renderer.setAttribute(prevEl, 'hidden', String(hidden));
  }

  private setSwiperOptions(): void {
    const nextEl = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private setNavButtonContainerVisibility(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
  }

  private setInitialActiveTile(options: DiscoverTilesOptions): void {
    if (!this.tiles?.length) return;
    if (!options?.activeTileKey) {
      this.activeTile = null;
      this.setNavigationHiddenValue(false);
      return;
    }
    const activeTile = this.tiles.find((tile) => tile.key === options.activeTileKey);
    if (activeTile) {
      this.activeTile = activeTile;
      this.setNavigationHiddenValue(true);
    } else {
      this.activeTile = null;
      this.setNavigationHiddenValue(false);
    }
  }

  private triggerAnalyticsEvent(eventName: string, tile: CollapsibleTile<T>): void {
    const promotionName = this.title;
    const businessVertical = this.businessVertical;
    const isLandingPage = this.locationsService.isSeoLocationPage();

    const gaConfig = getCollectionDiscoverCuisinesImpressionGAConfig(
      businessVertical,
      promotionName,
      this.tiles,
      tile,
      isLandingPage
    );
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}
