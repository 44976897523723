<ng-container [ngSwitch]="businessVertical">
  <ng-container *ngSwitchCase="'food'">
    <ng-container *ngIf="loadingData; else normalFoodComponents">
      <section class="page-section">
        <div class="container">
          <skeleton-cuisines></skeleton-cuisines>
        </div>
      </section>
      <section class="page-section">
        <div class="container">
          <skeleton-discover-filters></skeleton-discover-filters>
        </div>
      </section>
    </ng-container>
    <ng-template #normalFoodComponents>
      <section class="page-section">
        <div class="container">
          <discover-food-cuisines></discover-food-cuisines>
        </div>
      </section>
      <section *ngIf="showBrands" class="page-section">
        <div class="container">
          <discover-brands></discover-brands>
        </div>
      </section>
      <section *ngIf="discoverFilters?.length" class="page-section">
        <div class="container">
          <discover-filters
            [discoverFilters]="discoverFilters"
            [title]="'and_I_want_it_to_have' | translate"
          ></discover-filters>
        </div>
      </section>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'groceries'">
    <ng-container *ngIf="loadingData; else normalGroceriesComponents">
      <section class="page-section">
        <div class="container">
          <skeleton-cuisines></skeleton-cuisines>
        </div>
      </section>
    </ng-container>
    <ng-template #normalGroceriesComponents>
      <section class="page-section">
        <div class="container">
          <discover-groceries-cuisines></discover-groceries-cuisines>
        </div>
      </section>
    </ng-template>
  </ng-container>
</ng-container>

<section class="page-section">
  <div class="container">
    <discover-shops></discover-shops>
  </div>
</section>
