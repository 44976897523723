import { appConfig } from '@box-configs/app.config';
import { environment } from '@box-env/environment';
import { GetTextByKeyType } from '@box-types';

export {
  generateTitleContent,
  generateDescriptionContent,
  generateKeywordsContent,
  generateImageContent,
  generateUrlContent,
  addNoIndexNoFollow,
  isSeoLocationPage,
  determinePathBasedOnPage
};

function generateTitleContent(title?: string): string {
  const appName = appConfig.name;
  const givenTitle = title ?? 'Online Delivery';
  return `${givenTitle} | ${appName}`;
}

function generateDescriptionContent(description: string, translateFn: GetTextByKeyType): string {
  return translateFn(description) ?? translateFn('seo_default_description');
}

function generateKeywordsContent(keywords: string, translateFn: GetTextByKeyType): string {
  return translateFn(keywords) ?? translateFn('seo_default_keywords');
}

function generateImageContent(url?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return url ?? `${ROOT_URL}/assets/images/box-share-image.png`;
}

function generateUrlContent(routerUrl?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return routerUrl ? `${ROOT_URL}${routerUrl}` : ROOT_URL;
}

function addNoIndexNoFollow() {
  if (!document) return;
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex, nofollow';
  document.head.appendChild(meta);
}

function isSeoLocationPage(path: string): boolean {
  const pathLength = path.split('/').length;
  if (path.includes('/delivery/') && pathLength === 3) return true;
  if (path.includes('/delivery/') && path.includes('/estiatoria') && pathLength === 4) return true;
  if (path.includes('/delivery/') && path.includes('/psonia') && pathLength === 4) return true;
  return false;
}

function determinePathBasedOnPage(path: string, endUrlParam?: string): string {
  const isSeoLP = isSeoLocationPage(path);
  const locationPath = path.split('/')[2];
  if (endUrlParam) {
    return isSeoLP ? '/delivery/' + locationPath + endUrlParam : '/discover';
  } else {
    return isSeoLP ? '/delivery/' + locationPath : '/discover';
  }
}
