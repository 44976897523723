import { Injectable } from '@angular/core';
import { CoreService } from '@box-core/services';
import { Cuisine, Shop, BusinessVertical } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { filterShopsByMainOrSecondaryCuisines, DiscoverTilesOptions } from '@box/utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DiscoverBrandsService } from './discover-brands.service';

const BREAKPOINT = '(max-width: 1200px)';

@Injectable()
export class DiscoverCuisinesService {
  private readonly foodCuisinesSource = new BehaviorSubject<Cuisine[]>([]);
  private readonly groceriesCuisinesSource = new BehaviorSubject<Cuisine[]>([]);

  public readonly foodCuisines$ = this.foodCuisinesSource.asObservable();
  public readonly groceriesCuisines$ = this.groceriesCuisinesSource.asObservable();

  private readonly checkedFoodCuisineSource = new BehaviorSubject<Cuisine>(undefined);
  private readonly checkedGroceriesCuisineSource = new BehaviorSubject<Cuisine>(undefined);

  public readonly checkedFoodCuisine$ = this.checkedFoodCuisineSource.asObservable();
  public readonly checkedGroceriesCuisine$ = this.checkedGroceriesCuisineSource.asObservable();

  constructor(
    private coreService: CoreService,
    private breakpointObserver: BreakpointObserver,
    private discoverBrandsService: DiscoverBrandsService
  ) {}

  public getFoodCuisines(): Cuisine[] {
    return this.foodCuisinesSource.getValue();
  }

  public getGroceriesCuisines(): Cuisine[] {
    return this.groceriesCuisinesSource.getValue();
  }

  public setCheckedFoodCuisine(cuisine: Cuisine): void {
    if (!cuisine || !cuisine.enabled) this.discoverBrandsService.setCheckedBrand(null);
    this.checkedFoodCuisineSource.next(cuisine);
  }

  public getCheckedFoodCuisine(): Cuisine {
    return this.checkedFoodCuisineSource.getValue();
  }

  public setCheckedGroceriesCuisine(cuisine: Cuisine): void {
    if (Boolean(cuisine) && !cuisine.enabled) return;
    this.checkedGroceriesCuisineSource.next(cuisine);
  }

  public clearCheckedCuisines(): void {
    this.setCheckedGroceriesCuisine(null);
    this.setCheckedFoodCuisine(null);
  }

  public getCheckedGroceriesCuisine(): Cuisine {
    return this.checkedGroceriesCuisineSource.getValue();
  }

  public init(shops: Shop[], cuisineKey: string): void {
    const foodCuisinesThatContainShops = this.getCuisinesThatContainShops(shops, 'food');
    this.foodCuisinesSource.next(foodCuisinesThatContainShops);

    const groceryCuisinesThatContainShops = this.getCuisinesThatContainShops(shops, 'groceries');
    this.groceriesCuisinesSource.next(groceryCuisinesThatContainShops);

    if (!cuisineKey) return;
    const checkedCuisine = [...foodCuisinesThatContainShops, ...groceryCuisinesThatContainShops].find(
      (cuisine) => cuisine.key === cuisineKey
    );
    if (!checkedCuisine) return;
    if (checkedCuisine.businessVertical === 'food') this.setCheckedFoodCuisine(checkedCuisine);
    if (checkedCuisine.businessVertical === 'groceries') this.setCheckedGroceriesCuisine(checkedCuisine);
  }

  private getCuisinesThatContainShops(shops: Shop[], vertical: BusinessVertical): Cuisine[] {
    const cuisines =
      vertical === 'food' ? this.coreService.foodCuisines.getValue() : this.coreService.groceriesCuisines.getValue();
    const verticalShops = shops.filter((s) => s.businessVertical === vertical);

    return cuisines.filter((cuisine) =>
      Boolean(filterShopsByMainOrSecondaryCuisines(verticalShops, [cuisine])?.length)
    );
  }

  public getCuisineNameFromQueryString(queryParams: URLSearchParams): string {
    return queryParams.get('cuisine');
  }

  public appendCuisineQueryString(vertical: BusinessVertical, queryParams: URLSearchParams): void {
    const checkedCuisine = vertical === 'food' ? this.getCheckedFoodCuisine() : this.getCheckedGroceriesCuisine();
    if (!checkedCuisine?.key) return;
    queryParams.set('cuisine', checkedCuisine.key);
  }

  public getDiscoverCuisineTilesOptions(vertical: BusinessVertical): DiscoverTilesOptions {
    const cuisines = vertical === 'food' ? this.getFoodCuisines() : this.getGroceriesCuisines();
    const activeTileKey =
      vertical === 'food' ? this.getCheckedFoodCuisine()?.key : this.getCheckedGroceriesCuisine()?.key;
    const isSmallScreen: boolean = this.breakpointObserver.isMatched(BREAKPOINT);
    const singleRow = (isSmallScreen && cuisines.length <= 6) || (!isSmallScreen && cuisines.length <= 10);
    const title = vertical === 'food' ? 'in_the_mood_for' : 'want_';
    return { title: title, groupRows: singleRow ? 1 : 2, activeTileKey };
  }
}
