<!-- The reason we use h2 elements but with the h3 CSS, is for that in the design we need the h3 styling but we need the h2 tag for SEO purposes -->
<ng-container *ngIf="loading; else contentLoaded">
  <section>
    <div class="cuisine-landing-page-hero--skeleton">
      <div sectionSlice size="66%" class="cuisine-landing-page-hero--skeleton-slice"></div>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h1 class="cuisine-landing-page-title h1--skeleton"></h1>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <skeleton-shops></skeleton-shops>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h2 class="h3--skeleton"></h2>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h2 class="h3--skeleton"></h2>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h2 class="h3--skeleton"></h2>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h2 class="h3--skeleton"></h2>
      <p class="p--skeleton"></p>
      <p class="p--skeleton"></p>
    </div>
  </section>
</ng-container>
<ng-template #contentLoaded>
  <delivery-cuisine-hero [cuisine]="cuisine"></delivery-cuisine-hero>
  <section class="section cuisine-landing-page-section cuisine-landing-page-section-content">
    <div class="container">
      <h1 class="cuisine-landing-page-title">{{ cuisine?.name }} Delivery | {{'delivery_order_online' | translate}}</h1>
    </div>
  </section>
  <section *ngIf="showBrands" class="section cuisine-landing-page-section">
    <div class="container">
      <discover-tiles
        #discoverTiles
        [tiles]="tiles"
        [options]="brandsTilesOptions"
        [businessVertical]="'food'"
        (tileToggle)="onTileToggle($event)"
      ></discover-tiles>
    </div>
  </section>
  <section class="section cuisine-landing-page-section">
    <div class="container">
      <h3 class="cuisine-landing-page-shops-title">{{ shopsTitleDescription }}</h3>
      <discover-sorting-info *ngIf="shops?.length"></discover-sorting-info>
      <shops (shopClick)="onShopClick($event)" [shops]="shops"></shops>
    </div>
  </section>
  <section *ngIf="cuisine?.description" class="section cuisine-landing-page-section">
    <div class="container">
      <h2 class="cuisine-landing-page-section-title">{{ cuisine?.name }} - {{'is_it_your_type'| translate}}</h2>
      <span class="cuisine-landing-page-description" [innerHTML]="cuisine?.description"></span>
    </div>
  </section>
  <section *ngIf="locationsAnchors?.length > 0" class="section cuisine-landing-page-section">
    <div class="container">
      <delivery-anchors
        [title]="cuisine.name + ' ' + ('delivery_areas' | translate)"
        [anchors]="locationsAnchors"
      ></delivery-anchors>
    </div>
  </section>
  <section *ngIf="chainShopsAnchors?.length > 0" class="section cuisine-landing-page-section">
    <div class="container">
      <delivery-anchors
        [title]="('shops_for'|translate) + ' ' + cuisine.name + ' ' + ('delivery_' |translate)"
        [anchors]="chainShopsAnchors"
      ></delivery-anchors>
    </div>
  </section>
  <section *ngIf="newShopsAnchors?.length > 0" class="section cuisine-landing-page-section">
    <div class="container">
      <delivery-anchors
        [title]="cuisine.name + ' ' + ('delivery_new_shops_in_box' | translate)"
        [anchors]="newShopsAnchors"
      ></delivery-anchors>
    </div>
  </section>
</ng-template>
