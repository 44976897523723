import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIError, APIResponse, EditorData, PromoPageData } from '@box-types';
import { catchError, map, Observable, of } from 'rxjs';
import {
  filterItemShops,
  getOutputBlockHtml,
  getPromoPageHeroBackgroundImageUrl,
  normalizeHTMLAnchor
} from '@box/utils';
import { CoreService, ShopsService, TimeslotsService, SentryService } from '@box-core/services';
import { Router } from '@angular/router';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'root' })
export class PromoPagesService {
  private BOX_API: string = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private coreService: CoreService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private sentryService: SentryService,
    private router: Router,
    private globalStateService: GlobalStateService
  ) {}

  public fetchPromoPage$(slug: string): Observable<PromoPageData> {
    return this.http.get(`${this.BOX_API}/landing-pages/${slug}`).pipe(
      map((response: APIResponse<{ landingPage: PromoPageData }>) => {
        const landingPage = response.payload?.landingPage;
        if (!landingPage) return;
        return landingPage;
      }),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Delivery',
          domainDetails: 'Fetch Promo Page',
          severity: 'warning'
        });
        return of(null);
      })
    );
  }

  public decoratePromoPageWithFilteredShops(promoPage: PromoPageData): PromoPageData {
    const shops = this.globalStateService.getShops();
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.globalStateService.getOrderHistory();
    const openShops = shops.filter((s) => s.operatingState === 'OPEN');
    const relatedShops = filterItemShops(promoPage, openShops, { cuisines, orders, chains });
    return { ...promoPage, relatedShops };
  }

  public getPromoPageBlocksHtml(text: EditorData): string {
    if (!text?.blocks?.length) return;
    return text.blocks.map((block) => normalizeHTMLAnchor(getOutputBlockHtml(block))).join('');
  }

  public getPromoPageHeroBackgroundImageUrl(promoPageData: PromoPageData): string {
    const url = getPromoPageHeroBackgroundImageUrl(promoPageData);
    if (url) return `url('${url}')`;
  }

  public handlePromoPageError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Promo Page',
      severity: 'warning'
    });
    return void this.router.navigate([]);
  }
}
