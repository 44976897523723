<ng-container *ngIf="loading; else contentLoaded">
  <section class="section">
    <div class="container">
      <h1 class="h1--skeleton"></h1>
      <div class="locations-page-group">
        <h3 class="h3--skeleton"></h3>
        <div class="locations-page-group-container--skeleton"></div>
      </div>
      <div class="locations-page-group">
        <h3 class="h3--skeleton"></h3>
        <div class="locations-page-group-container--skeleton"></div>
      </div>
      <div class="locations-page-group">
        <h3 class="h3--skeleton"></h3>
        <div class="locations-page-group-container--skeleton"></div>
      </div>
    </div>
  </section>
</ng-container>
<ng-template #contentLoaded>
  <section class="page-section">
    <div class="container">
      <h1 class="locations-page-header">{{'box_in_100_cities'| translate}}</h1>
      <div class="locations-page-container">
        <div *ngFor="let group of groupedLocations | keyvalue" class="locations-page-group">
          <h3 class="locations-page-group-header">{{ group.key }}</h3>
          <div class="locations-page-group-container">
            <a
              *ngFor="let location of group.value"
              class="locations-page-location ods-typography-body-s-regular"
              [routerLink]="['/delivery', location.key]"
              [title]="location.name"
            >
              {{ location.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
