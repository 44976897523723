import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { ShopService, ShopsService, CartService } from '@box-core/services';
import { FetchShopOptions } from '@box-types';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { ShopResolverData } from '@box-delivery/delivery.types';
import {
  normalizeShop,
  getShopCartMaxVolume,
  setPrerenderReady,
  setRedirectPrerenderMetaElements,
  clearExistingRedirectPrerenderMetaElements
} from '@box/utils';
import { ShopMenuNavService } from '@box-delivery/services';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class ShopResolver implements Resolve<ShopResolverData> {
  constructor(
    private router: Router,
    private shopService: ShopService,
    private cartService: CartService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private shopMenuNavService: ShopMenuNavService,
    private globalStateService: GlobalStateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ShopResolverData> {
    const locationKey = route.params.locationKey as string;
    const vanityUrl = route.params.vanityUrl as string;
    const address = this.globalStateService.getAddress();
    const fetchOptions: FetchShopOptions = {
      location: locationKey,
      lat: address?.latitude,
      lng: address?.longitude,
      postalCodes: address?.postalCode
    };

    clearExistingRedirectPrerenderMetaElements();
    setPrerenderReady(false); // it becomes ready when we get the shop items inside the shop page
    return this.shopsService.fetchShopByVanityUrl$(vanityUrl, fetchOptions).pipe(
      map((shop) => {
        const normalizedShop = normalizeShop(shop);
        const decoratedShop = this.shopService.shopPromoDecorator(normalizedShop);
        return { shop: decoratedShop };
      }),
      tap(({ shop }) => {
        // todo refactor shop service shouldn't be global but is used in checkout
        this.shopService.clearServiceStateWhenGoingToDifferentShop(shop);
        this.shopMenuNavService.clearState();
        this.shopService.setShop(shop);
        const maxVolume = getShopCartMaxVolume(shop);
        this.cartService.initializeCart({ shop, maxVolume });
        this.timeslotsService.setTimeslots((shop.timeslots ?? []).filter((t) => t.isAvailable));
      }),
      catchError(() => {
        setRedirectPrerenderMetaElements(window.location.origin + '/delivery/' + fetchOptions.location);
        void this.router.navigate(['/home']);
        return of(null as ShopResolverData);
      })
    );
  }
}
