import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfigurationService,
  CoreService,
  SentryService,
  SEOService,
  ShopService,
  TimeslotsService
} from '@box-core/services';
import { Location, Cuisine, DeliveryAnchor, SeoOptions, Shop, APIError, Brand, CollapsibleTile } from '@box-types';
import {
  normalizeShop,
  sortShopsBasedOnOperatingState,
  sortCuisineShops,
  getShopAverageSortingWeight,
  generateLocationCuisinesDeliveryAnchors,
  setRedirectPrerenderMetaElements,
  brandToCollapsibleTile
} from '@box/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap, take } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { BrandsService, LocationsService } from '../../services';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class LocationCuisinePageService {
  private readonly shopsSource: BehaviorSubject<Shop[]> = new BehaviorSubject<Shop[]>([]);
  private readonly checkedBrandSource = new BehaviorSubject<Brand>(null);
  public readonly checkedBrand$ = this.checkedBrandSource.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private coreService: CoreService,
    private sentryService: SentryService,
    private configService: ConfigurationService,
    private locationsService: LocationsService,
    private timeslotsService: TimeslotsService,
    private shopService: ShopService,
    private languageService: LanguageService,
    private brandsService: BrandsService
  ) {}

  public cuisine$(): Observable<Cuisine> {
    return this.activatedRoute.paramMap.pipe(
      map((params) => params.get('cuisineKey')),
      startWith(undefined),
      pairwise(),
      filter((cuisineKeys) => cuisineKeys[0] !== cuisineKeys[1] && Boolean(cuisineKeys[1])), // TODO faropoulos vasiliadis problematic data
      map((cuisineKeys): string => cuisineKeys[1]),
      switchMap((cuisineKey) => this.getCuisine$(cuisineKey))
    );
  }

  public location$(): Observable<Location> {
    return this.activatedRoute.paramMap.pipe(
      map((params) => params.get('locationKey')),
      startWith(undefined),
      pairwise(),
      filter((locationKeys) => locationKeys[0] !== locationKeys[1] && Boolean(locationKeys[1])),
      map((locationKeys) => locationKeys[1]),
      switchMap((locationKey) => this.locationsService.fetchLocation(locationKey))
    );
  }

  public setCheckedBrand(brand: Brand): void {
    this.checkedBrandSource.next(brand);
  }

  public getCheckedBrand(): Brand {
    return this.checkedBrandSource.getValue();
  }

  public setShops(shops: Shop[]): void {
    this.shopsSource.next(shops);
  }

  public getShops(): Shop[] {
    return this.shopsSource.getValue();
  }

  public setLocationCuisinePageMetaTags(location: Location, cuisine: Cuisine): void {
    const t = this.languageService.getTextByKey.bind(this.languageService);
    const replacementDictioary = { _LOCATION_NAME: location?.name, _CUISINE_NAME: cuisine?.name };
    const options: SeoOptions = {
      title: t('seo_location_cuisine_1', replacementDictioary),
      description: t('seo_location_cuisine_2', replacementDictioary),
      keywords: t('seo_location_cuisine_3', replacementDictioary),
      url: this.router.url
    };
    this.seoService.setTags(options);
  }

  public getTopShops(shops: Shop[]): Shop[] {
    const sortedShops = orderBy(shops, (shop) => getShopAverageSortingWeight(shop), 'desc').slice(0, 10);
    return sortShopsBasedOnOperatingState(sortedShops);
  }

  public handleLocationPageError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Location Cuisine Page',
      severity: 'warning'
    });
    setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
    return void this.router.navigate(['/delivery']);
  }

  public fetchShopsByCuisineAndLocation(location: Location, cuisine: Cuisine): Observable<Shop[]> {
    return this.locationsService.fetchShopsByCuisineAndLocation(location.key, cuisine.key).pipe(
      map((shops) => {
        const normalizedShops = shops.map((shop) => normalizeShop(shop));
        //const normalizedDisplayableShops = normalizedShops.filter((shop: Shop) => displayableShop(shop));
        const decoratedShops = normalizedShops.map((shop) => this.shopService.shopPromoDecorator(shop));
        const promotedChainTag = this.configService.getConfiguration()?.shops?.sortingTag;
        const timeslot = this.timeslotsService.getTimeslot();
        const sortingOptions = { cuisine, promotedChainTag, timeslot };
        const defaultSortedShops = sortCuisineShops(decoratedShops, sortingOptions);
        return sortShopsBasedOnOperatingState(defaultSortedShops);
      })
    );
  }

  public generateCuisinesDeliveryAnchors(location: Location): DeliveryAnchor[] {
    const cuisines = this.coreService.foodCuisines.getValue();
    return generateLocationCuisinesDeliveryAnchors(location, cuisines);
  }

  private getCuisine$(cuisineKey: string): Observable<Cuisine> {
    return this.coreService.cuisines.pipe(
      take(1),
      map((cuisines) => {
        const cuisine = cuisines.find((c) => c.key === cuisineKey);
        if (cuisine) return cuisine;
        throw new Error(`Cuisine with key:${cuisineKey} was not found`);
      })
    );
  }

  public handleEmptyStatePage(location: Location): void {
    setRedirectPrerenderMetaElements(window.location.origin + '/delivery/' + location.key);
    return void this.router.navigate(['/delivery']);
  }

  public generateTiles(cuisine: Cuisine): CollapsibleTile<Brand>[] {
    const cuisineBrands = this.brandsService.getBrandsForCuisine(cuisine);
    const sortedBrands = this.brandsService.decorateFilterAndSortBrands(cuisineBrands, this.getShops());
    return sortedBrands.map((brand) => brandToCollapsibleTile(brand));
  }

  public getFilteredShopsByBrand(): Shop[] {
    const activeBrand = this.getCheckedBrand();
    const shops = this.getShops();
    return this.brandsService.getFilteredShopsByBrand(activeBrand, shops);
  }
}
