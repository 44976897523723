import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddressesService, SEOService } from '@box-core/services';
import { DiscoverBusinessVerticalService, DiscoverSearchService, DiscoverLoaderService } from '@box-discover/services';
import {
  SeoOptions,
  Shop,
  DiscoverSearchResults,
  DiscoverSearchItemsOptions,
  DiscoverSearchOptions,
  DiscoverSearchResultItem,
  BusinessVertical,
  DiscoverSearchSubCategoryType,
  GetTextByKeyType
} from '@box-types';
import { combineLatest, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { DiscoverPageService } from '@box-discover/pages/discover/discover-page.service';
import { deleteJsonLd } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'discover-search-page',
  templateUrl: './discover-search.page.html',
  styleUrls: ['./discover-search.page.scss'],
  providers: [DiscoverPageService]
})
export class DiscoverSearchPage implements OnInit, OnDestroy {
  public searchResults: DiscoverSearchResults;
  public selectedSubCategory: DiscoverSearchSubCategoryType;
  public searchItemsOptions: DiscoverSearchItemsOptions;
  public loading: boolean;
  public businessVertical: BusinessVertical;
  public readonly t: GetTextByKeyType;

  private searchSubscription: Subscription;
  private subCategorySubscription: Subscription;
  private searchResultsSubscription: Subscription;
  private loaderSubscription: Subscription;

  constructor(
    private router: Router,
    private seoService: SEOService,
    private addressesService: AddressesService,
    private discoverLoaderService: DiscoverLoaderService,
    private discoverPageService: DiscoverPageService,
    private discoverSearchService: DiscoverSearchService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private languageService: LanguageService,
    private globalStateService: GlobalStateService
  ) {
    this.t = this.languageService.getTextByKey.bind(this.languageService);
  }

  ngOnInit(): void {
    this.setMetaTags();
    this.searchItemsOptions = this.discoverSearchService.generateSearchItemsOptions();
    this.setSearchSubscription();
    this.setSubCategorySubscription();
    this.setLoaderSubscription();
    this.setSearchResultsSubscription();
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
    if (this.subCategorySubscription) this.subCategorySubscription.unsubscribe();
    if (this.searchResultsSubscription) this.searchResultsSubscription.unsubscribe();
    this.loaderSubscription?.unsubscribe();
    this.discoverSearchService.clearSearchResults();
    deleteJsonLd();
  }

  public onItemSelect(shop: Shop, item: DiscoverSearchResultItem): void {
    const vanityUrl = shop.vanity_url;
    const locationKey = shop.locationKey;
    if (!item) return;
    const params = item?.isOffer ? { offerId: item?.itemId } : { productId: item?.itemId };
    if (!params) return;
    void this.router.navigate(['/delivery', locationKey, vanityUrl], { queryParams: params });
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: 'search_', url: this.router.url };
    this.seoService.setTags(options);
  }

  private setSearchSubscription(): void {
    this.searchSubscription = combineLatest([
      this.discoverBusinessVerticalService.businessVertical,
      this.discoverSearchService.searchTerm
    ]).subscribe(([businessVertical, searchTerm]) => {
      if (!businessVertical || !searchTerm) return;
      this.businessVertical = businessVertical;
      this.setRouterState(searchTerm, businessVertical);
      this.searchForResults(searchTerm, businessVertical);
    });
  }

  private setSubCategorySubscription(): void {
    this.subCategorySubscription = this.discoverSearchService.subCategory.subscribe(
      (subCategory) => (this.selectedSubCategory = subCategory)
    );
  }

  private setSearchResultsSubscription(): void {
    this.searchResultsSubscription = this.discoverSearchService.searchResults.subscribe((searchResults) => {
      this.searchResults = searchResults;
      this.discoverPageService.setFilteredShops(searchResults.shopResults);
    });
  }

  private setLoaderSubscription(): void {
    this.loaderSubscription = combineLatest([
      this.discoverLoaderService.loadingData,
      this.discoverLoaderService.loadingShops
    ])
      .pipe(
        map(([loadingData, loadingShops]) => {
          return loadingData || loadingShops;
        })
      )
      .subscribe((value) => {
        this.loading = value;
      });
  }

  private searchForResults(searchTerm: string, businessVertical: BusinessVertical): void {
    this.discoverLoaderService.setLoadingShops(true);
    const address = this.globalStateService.getAddress();
    const searchOptions: DiscoverSearchOptions = { term: searchTerm, businessVertical, address };
    this.discoverSearchService
      .fetchSearchShops(searchOptions)
      .pipe(
        finalize(() => this.discoverLoaderService.setLoadingShops(false)),
        tap((searchResponse) => this.discoverSearchService.setShowOnlyShops(searchResponse.showOnlyShops)),
        map((searchResponse): DiscoverSearchResults => {
          const shops = this.globalStateService.getShops();
          const searchShops = searchResponse.shops;
          return this.discoverSearchService.generateSearchResults(shops, searchShops);
        })
      )
      .subscribe((searchResults) => {
        this.handleSubCategoryActivation(searchResults);
        this.discoverSearchService.setSearchResults(searchResults);
      });
  }

  private handleSubCategoryActivation(searchResults: DiscoverSearchResults): void {
    const selectedSubCategory = this.discoverSearchService.getSubCategory();
    if (selectedSubCategory === 'shops' && searchResults.shopResults.length > 0) {
      return this.discoverSearchService.setSubCategory('shops');
    }
    if (selectedSubCategory === 'products' && searchResults.productResults.length > 0) {
      return this.discoverSearchService.setSubCategory('products');
    }
    if (selectedSubCategory === 'offers' && searchResults.offerResults.length > 0) {
      return this.discoverSearchService.setSubCategory('offers');
    }
    if (searchResults.shopResults.length > 0) return this.discoverSearchService.setSubCategory('shops');
    if (searchResults.productResults.length > 0) return this.discoverSearchService.setSubCategory('products');
    if (searchResults.offerResults.length > 0) return this.discoverSearchService.setSubCategory('offers');
    return this.discoverSearchService.setSubCategory('shops');
  }

  private setRouterState(searchTerm: string, businessVertical: BusinessVertical): void {
    const queryParams = { vertical: businessVertical, query: searchTerm };
    // todo update when discover location landing pages have search by user input api
    void this.router.navigate(['/discover'], { queryParams });
  }
}
