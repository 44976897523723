import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import locationsRedirections from '@box-data/locations-301-redirection.json';

@Injectable()
export class LocationGuard implements CanActivate {
  private invalidKeys: string[];

  constructor(private router: Router) {
    this.invalidKeys = Object.keys(locationsRedirections);
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const locationKey: string = route.params.locationKey as string;
    if (!locationKey) return void this.router.navigate(['/delivery']);
    if (this.invalidKeys.includes(locationKey)) return void this.redirect301ToCorrectLocation(locationKey);
    return true;
  }

  private redirect301ToCorrectLocation(locationKey: string): void {
    const newLocationKey = locationsRedirections[locationKey] as string;
    if (!newLocationKey) return void this.router.navigate(['/delivery']);
    return void this.router.navigate(['/delivery', newLocationKey]);
  }
}
