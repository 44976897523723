import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DiscoverLoaderService {
  public loadingData: BehaviorSubject<boolean>;
  public loadingShops: BehaviorSubject<boolean>;

  constructor() {
    this.loadingData = new BehaviorSubject<boolean>(null);
    this.loadingShops = new BehaviorSubject<boolean>(null);
  }

  public setLoadingData(loading: boolean): void {
    this.loadingData.next(loading);
  }

  public setLoadingShops(loading: boolean): void {
    this.loadingShops.next(loading);
  }
}
