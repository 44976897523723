import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DiscoverFilter } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { AnalyticsService, DiscoverFiltersService } from '@box-core/services';
import { Router } from '@angular/router';
import { Mousewheel, Navigation } from 'swiper/modules';
import { getCollectionOffersImpressionGAConfig, HomeSection, determinePathBasedOnPage } from '@box/utils';
import { LocationsService } from '@box-delivery/services';

@Component({
  selector: 'home-discover-filters',
  templateUrl: './home-discover-filters.component.html',
  styleUrls: ['./home-discover-filters.component.scss']
})
export class HomeDiscoverFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public homeSection: HomeSection;
  public mainViewDiscoverFilters: DiscoverFilter[];
  public title: string;
  public swiperOptions: SwiperOptions;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private discoverFiltersService: DiscoverFiltersService,
    private analyticsService: AnalyticsService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.setSectionContent();
    this.setSwiperOptions();
  }

  // todo lots of duplicate code related to swiper written in home-shops-section contests-section and promo-banners
  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
  }

  private setSectionContent(): void {
    this.title = this.homeSection.title;
    this.mainViewDiscoverFilters = this.discoverFiltersService.getMainViewDiscoverFilters(
      this.homeSection.discoverFilters,
      this.homeSection.filteredShops
    );
  }

  public onDiscoverFilterClick(discoverFilter: DiscoverFilter): void {
    this.triggerAnalyticsEvent('select_promotion', discoverFilter);
    const queryParams = { vertical: 'food', filters: discoverFilter.slug };
    const urlParam = determinePathBasedOnPage(this.router.url, '/estiatoria');
    void this.router.navigate([urlParam], { queryParams });
  }

  public onDiscoverFilterEnteredViewport(discoverFilter: DiscoverFilter): void {
    this.triggerAnalyticsEvent('view_promotion', discoverFilter);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private triggerAnalyticsEvent(eventName: string, discoverFilter: DiscoverFilter): void {
    const promotionName = this.homeSection.title;
    const creativeSlot = this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home';
    const gaConfig = getCollectionOffersImpressionGAConfig(
      creativeSlot,
      promotionName,
      this.mainViewDiscoverFilters,
      discoverFilter
    );
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}
