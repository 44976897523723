import { Injectable } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import {
  ConfigurationService,
  CoreService,
  OrdersService,
  SentryService,
  TimeslotsService,
  PromoCampaignsService
} from '@box-core/services';
import {
  DiscoverBrandsService,
  DiscoverBusinessVerticalService,
  DiscoverCuisinesService,
  DiscoverLoaderService
} from '@box-discover/services';
import { DiscoverFilterSearchOptions, DiscoverSearchResponse, Shop, BusinessVertical, APIResponse } from '@box-types';
import {
  filterShopsByBrands,
  filterShopsByMainOrSecondaryCuisines,
  sortCuisineShops,
  sortShops,
  sortShopsBasedOnOperatingState,
  filterShopsByDiscoverFilters,
  filterShopsBasedOnOptions,
  defaultSortingIsNotEnabled,
  sortShopsBasedOnOption,
  generateDiscoverItemsRequestOptions,
  normalizeDiscoverFiltersSearchResponse,
  getGroupingsNames,
  urlSearchParamsToParams
} from '@box/utils';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DiscoverFiltersService } from '@box-core/services/discover-filters.service';
import { environment } from '@box-env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DiscoverShopsService } from '@box-discover/services/discover-shops.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { Location } from '@angular/common';
import { LocationsService } from '@box-delivery/services';

@Injectable()
export class DiscoverPageService {
  private BOX_API: string = environment.application.API_URL;

  public readonly filteredSortedShops = new BehaviorSubject<Shop[]>([]);
  public readonly discoverSearchResponse = new BehaviorSubject<DiscoverSearchResponse>(undefined);

  private discoverChangeSubscription: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private coreService: CoreService,
    private ordersService: OrdersService,
    private configService: ConfigurationService,
    private sentryService: SentryService,
    private timeslotsService: TimeslotsService,
    private discoverLoaderService: DiscoverLoaderService,
    private discoverFiltersService: DiscoverFiltersService,
    private discoverCuisinesService: DiscoverCuisinesService,
    private discoverBrandsService: DiscoverBrandsService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private discoverShopsService: DiscoverShopsService,
    private promoCampaignsService: PromoCampaignsService,
    private globalStateService: GlobalStateService,
    private locationsService: LocationsService,
    private activatedRoute: ActivatedRoute
  ) {}

  public init(): void {
    this.setDiscoverChangeSubscription();
  }

  public destroy(): void {
    if (this.discoverChangeSubscription) this.discoverChangeSubscription.unsubscribe();
    this.discoverCuisinesService.clearCheckedCuisines();
  }

  public fetchSearchByDiscoverFilter(options: DiscoverFilterSearchOptions): Observable<DiscoverSearchResponse> {
    return this.http.post(this.BOX_API + '/items/promo-products-offers', options).pipe(
      map((response: APIResponse<DiscoverSearchResponse>) => {
        return normalizeDiscoverFiltersSearchResponse(response.payload);
      }),
      catchError(() => of({ shops: [] }))
    );
  }

  public setFilteredShops(shops: Shop[]): void {
    this.filteredSortedShops.next(shops);
  }

  public setDiscoverSearchResponse(shopsWithItems: DiscoverSearchResponse): void {
    this.discoverSearchResponse.next(shopsWithItems);
  }

  public getDiscoverQueryParams(vertical: BusinessVertical): Params {
    const queryParams: URLSearchParams = new URLSearchParams();
    const isSeoLocationPage = this.locationsService.isSeoLocationPage();
    if (!isSeoLocationPage) this.discoverBusinessVerticalService.appendBusinessVerticalQueryString(queryParams);
    this.discoverCuisinesService.appendCuisineQueryString(vertical, queryParams);
    this.discoverBrandsService.appendBrandQueryString(queryParams);
    this.discoverFiltersService.appendDiscoverFiltersQueryString(vertical, queryParams);
    return urlSearchParamsToParams(queryParams);
  }

  private buildUrlFromState(): void {
    const businessVertical = this.discoverBusinessVerticalService.getBusinessVertical();
    const queryParams = this.getDiscoverQueryParams(businessVertical);
    const locationKey = this.getLocationKeyFromUrlSegments();
    const route = this.getRouteFromLocationKey(locationKey, businessVertical);
    void this.router.navigate([route], {
      queryParams
    });
  }

  private getLocationKeyFromUrlSegments(): string {
    const urlSegments = this.activatedRoute.snapshot.url;
    if (!urlSegments?.length || urlSegments?.length === 1) return;
    if (urlSegments?.length === 2) return urlSegments[0]?.path;
  }

  private getRouteFromLocationKey(locationKey: string, businessVertical: BusinessVertical): string {
    if (locationKey) {
      return '/delivery/' + locationKey + (businessVertical === 'food' ? '/estiatoria' : '/psonia');
    } else {
      return '/discover';
    }
  }

  public filterAndSortDiscoverShops(shops: Shop[], businessVertical: BusinessVertical): Shop[] {
    if (businessVertical === 'food') return this.applyFoodFiltersAndSort(shops);
    if (businessVertical === 'groceries') return this.applyMarketFiltersAndSort(shops);
  }

  private applyFoodFiltersAndSort(shops: Shop[]): Shop[] {
    const currentShops: Shop[] = shops.filter((shop) => shop.businessVertical === 'food');
    const activeFoodCuisine = this.discoverCuisinesService.getCheckedFoodCuisine();
    const activeFoodCuisines = activeFoodCuisine ? [activeFoodCuisine] : [];
    const cuisineFilteredShops = filterShopsByMainOrSecondaryCuisines(currentShops, activeFoodCuisines);
    const activeBrand = this.discoverBrandsService.getCheckedBrand();
    const activeBrands = activeBrand ? [activeBrand] : [];
    const brandFilteredShops = filterShopsByBrands(cuisineFilteredShops, activeBrands);
    const activeDiscoverFilters = this.discoverFiltersService.getCheckedDiscoverFilters();
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.globalStateService.getOrderHistory();
    const discoverFilteredShops = filterShopsByDiscoverFilters(brandFilteredShops, activeDiscoverFilters, {
      cuisines,
      orders,
      chains
    });
    const checkedFilteringOptions = this.discoverShopsService.getCheckedFilteringOptions();
    const tagFilteredShops = filterShopsBasedOnOptions(discoverFilteredShops, checkedFilteringOptions);
    /** When the Reccomended sorting option is checked, then we are going to use our Default Sorting.
     * If any other Sorting Option is selected, we skip the Default sorting to avoid unnecessary calculations.*/
    const sortingOptions = this.discoverShopsService.getSortingOptions();
    if (defaultSortingIsNotEnabled(sortingOptions)) return this.getSortedShopsBasedOnSortingOptions(tagFilteredShops);
    const timeslot = this.timeslotsService.getTimeslot();
    if (activeFoodCuisines.length === 0) return sortShops(tagFilteredShops, timeslot);
    const promotedChainTag = this.configService.getConfiguration()?.shops?.sortingTag;
    const categorySortingOptions = { cuisine: activeFoodCuisine, promotedChainTag, timeslot };
    return sortCuisineShops(tagFilteredShops, categorySortingOptions);
  }

  private applyMarketFiltersAndSort(shops: Shop[]): Shop[] {
    const currentShops: Shop[] = shops.filter((shop) => shop.businessVertical === 'groceries');
    const activeGroceriesCuisine = this.discoverCuisinesService.getCheckedGroceriesCuisine();
    const activeGroceriesCuisines = activeGroceriesCuisine ? [activeGroceriesCuisine] : [];
    const cuisineFilteredShops = filterShopsByMainOrSecondaryCuisines(currentShops, activeGroceriesCuisines);
    /** When the Reccomended sorting option is checked, then we are going to use our Default Sorting.
     * If any other Sorting Option is selected, we skip the Default sorting to avoid unnecessary calculations.*/
    const sortingOptions = this.discoverShopsService.getSortingOptions();
    if (defaultSortingIsNotEnabled(sortingOptions)) {
      return this.getSortedShopsBasedOnSortingOptions(cuisineFilteredShops);
    }
    const timeslot = this.timeslotsService.getTimeslot();
    if (activeGroceriesCuisines.length === 0) return sortShops(cuisineFilteredShops, timeslot);
    const categorySortingOptions = { cuisine: activeGroceriesCuisine, timeslot };
    return sortCuisineShops(cuisineFilteredShops, categorySortingOptions);
  }

  public getSortedShopsBasedOnSortingOptions(shops: Shop[]): Shop[] {
    const shopsCopy = [...shops];
    const sortingOption = this.discoverShopsService.getSelectedSortingOption();
    const sortedShops = sortShopsBasedOnOption(shopsCopy, sortingOption);
    return sortShopsBasedOnOperatingState(sortedShops);
  }

  private checkDiscoverFiltersAndFetch(filteredShops: Shop[]): Observable<DiscoverSearchResponse> {
    if (!filteredShops?.length) return of({ shops: [] });

    const vertical = this.discoverBusinessVerticalService.getBusinessVertical();
    if (vertical === 'groceries') return of({ shops: [] });

    const discoverFiltersFetchOptions = this.generateDiscoverItemsRequestOptions(filteredShops);
    const noOfferTypes = !discoverFiltersFetchOptions?.offerTypes?.length;
    const noPromoCampaigns = !discoverFiltersFetchOptions?.promoCampaigns?.length;
    const noBrand = !discoverFiltersFetchOptions?.brand?.length;
    const noTagIds = !discoverFiltersFetchOptions?.tags?.length;
    const noGroupings = !discoverFiltersFetchOptions?.groupings?.length;
    if (noOfferTypes && noPromoCampaigns && noBrand && noTagIds && noGroupings) return of({ shops: [] });
    const eligibleGroupings = this.globalStateService.getEligibleGroupings();
    const eligibleGroupingsNames = getGroupingsNames(eligibleGroupings);
    discoverFiltersFetchOptions.groupings = discoverFiltersFetchOptions.groupings.filter((grouping) =>
      eligibleGroupingsNames.includes(grouping)
    );
    return this.fetchSearchByDiscoverFilter(discoverFiltersFetchOptions);
  }

  private generateDiscoverItemsRequestOptions(filteredShops: Shop[]): DiscoverFilterSearchOptions {
    const checkedDiscoverFilters = this.discoverFiltersService.getCheckedDiscoverFilters();
    const dishRelatedCheckedDiscoverFilters = checkedDiscoverFilters.filter((filter) => filter.dishRelated);
    const checkedBrandFilter = this.discoverBrandsService.getCheckedBrand();
    const checkedFilteringOptions = this.discoverShopsService.getCheckedFilteringOptions();
    const dietaryFilteringOptions = checkedFilteringOptions.filter((option) => option.type === 'dietary');

    return generateDiscoverItemsRequestOptions(
      dishRelatedCheckedDiscoverFilters,
      checkedBrandFilter,
      dietaryFilteringOptions,
      filteredShops
    );
  }

  private redecorateShopsWithDiscoverFilterSynergies(shops: Shop[]): Shop[] {
    const couponSynergies = this.discoverFiltersService
      .getCheckedDiscoverFilters()
      .flatMap((filter) => filter.eligibleCouponSynergies);
    return this.promoCampaignsService.redecorateShopsWithSpecificPromoCouponSynergies(couponSynergies, shops);
  }

  private setDiscoverChangeSubscription(): void {
    this.discoverChangeSubscription = combineLatest([
      this.globalStateService.shops$,
      this.discoverBusinessVerticalService.businessVertical,
      this.discoverCuisinesService.checkedFoodCuisine$,
      this.discoverCuisinesService.checkedGroceriesCuisine$,
      this.discoverBrandsService.checkedBrand$,
      this.discoverFiltersService.getCheckedDiscoverFilters$(),
      this.discoverShopsService.getCheckedFilteringOptions$(),
      this.discoverShopsService.sortingOptions$
    ])
      .pipe(
        map(([shops, businessVertical]) => {
          const filteredShops = this.filterAndSortDiscoverShops(shops, businessVertical);
          const redecoratedShops = this.redecorateShopsWithDiscoverFilterSynergies(filteredShops);
          return { filteredShops: redecoratedShops, businessVertical };
        })
      )
      .subscribe(({ filteredShops, businessVertical }) => {
        this.discoverLoaderService.setLoadingShops(true);
        this.buildUrlFromState();
        this.checkDiscoverFiltersAndFetch(filteredShops)
          .pipe(
            finalize(() => this.discoverLoaderService.setLoadingShops(false)),
            catchError((error: HttpErrorResponse) => {
              this.sentryService.captureException(error, {
                domain: 'Discover'
              });
              return throwError(() => error);
            })
          )
          .subscribe((response) => {
            this.setFilteredShops(filteredShops);
            this.setDiscoverSearchResponse(response);
          });
      });
  }
}
