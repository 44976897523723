import { Injectable } from '@angular/core';
import { DiscoverTilesGroup } from '@box/utils';
import { CollapsibleTile } from '@box-types';

@Injectable()
export class DiscoverTilesService {
  public generateDiscoverTilesGroups<T>(tiles: CollapsibleTile<T>[], groupRows?: number): DiscoverTilesGroup<T>[] {
    if (!tiles?.length) return [];
    if (!groupRows) return tiles.map((tile) => ({ tiles: [tile] }));
    const groups = [] as DiscoverTilesGroup<T>[];
    const numberOfGroups = Math.ceil(tiles.length / groupRows);
    for (let i = 0; i < numberOfGroups; i++) {
      const startIndex = i * groupRows;
      const endIndex = startIndex + groupRows;
      const group = { tiles: tiles.slice(startIndex, endIndex) };
      groups.push(group);
    }
    return groups;
  }
}
