import { Injectable } from '@angular/core';
import {
  WhatsNewService,
  PromoBannersService,
  OnBoardingService,
  GDPRService,
  UserService,
  NotificationsService,
  GlobalPopupsService,
  ConfigurationService
} from '@box-core/services';
import { HomePageService } from '@box-core/pages/home/home-page.service';
import { Observable, switchMap, of, first, Subscription, mapTo } from 'rxjs';
import dayjs from 'dayjs';
import { RateAppNotificationWrapperComponent } from '@box-rating-widget/components';
import { isPrerenderBrowser, saveRewardsPointsNotificationTimestamp, storageSet } from '@box/utils';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { BenefitsBannerWrapperComponent } from '@box-shared/components';
import { UrgentConditionNotificationService } from '@box-core/services/urgent-condition-notification.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { isFirstLoginThisMonth } from '@box/utils';
import { now } from 'lodash-es';

const DEFAULT_POPUPS_LIMIT = 3;

@Injectable()
export class HomePagePopupsService {
  private afterOpenedSubscription: Subscription;
  private notificationsSubscription: Subscription;
  private remotePopupsLimit: number;
  private popupsCount = 0;

  constructor(
    private whatsNewService: WhatsNewService,
    private promoBannersService: PromoBannersService,
    private onBoardingService: OnBoardingService,
    private gdprService: GDPRService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private homePageService: HomePageService,
    private globalPopupsService: GlobalPopupsService,
    private platform: Platform,
    private dialogRef: MatDialog,
    private configurationService: ConfigurationService,
    private urgentConditionNotificationService: UrgentConditionNotificationService,
    private globalStateService: GlobalStateService
  ) {}

  public initializePopups(): void {
    if (!this.platform.isBrowser) return;
    if (isPrerenderBrowser(window)) return;
    if (
      this.globalPopupsService.getHomePopupsServiceState() === 'completed' ||
      this.globalPopupsService.getHomePopupsServiceState() === 'pending'
    ) {
      return;
    }

    this.remotePopupsLimit = this.configurationService.get('homePopupsLimit', DEFAULT_POPUPS_LIMIT);

    // when the global popups have been completed continue with home popups
    const globalPopupsCompleted$ = this.globalPopupsService.globalPopupsServiceState$.pipe(
      first((state) => state === 'completed')
    );

    this.globalPopupsService.setHomePopupsServiceState('pending');
    globalPopupsCompleted$
      .pipe(
        switchMap(() => this.limitlessGroup$()),
        switchMap(() => this.limitedGroup$())
      )
      .subscribe(() => this.globalPopupsService.setHomePopupsServiceState('completed'));
  }

  private limitlessGroup$(): Observable<null> {
    return of(0).pipe(
      switchMap(() => this.whatsNewService.initWhatsNewSequence$()),
      switchMap(() => this.promoBannersService.openPromoBannerDetailsDialogCheck$())
    );
  }

  private limitedGroup$(): Observable<null> {
    return of(0).pipe(
      tap(() => {
        this.setAfterOpenedSubscription();
        this.setNotificationsSubscription();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.onBoardingService.initiateOnBoarding$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.homePageService.checkGBPromoDialog$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.gdprService.checkGDPRConsent$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        const notificationAlert = this.urgentConditionNotificationService.getHomePageNotificationAlert();
        if (!notificationAlert) return this.homePageService.showCollectionCampaignsDialog$();
        return this.urgentConditionNotificationService
          .showUrgentConditionsNotification('Home')
          .afterDismissed.pipe(mapTo(null));
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.initiateAppRateSequence$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.initiatesRewardsPointsNotificationSequence$();
      }),
      tap(() => {
        this.afterOpenedSubscription?.unsubscribe();
        this.notificationsSubscription?.unsubscribe();
      })
    );
  }

  private setAfterOpenedSubscription(): void {
    this.afterOpenedSubscription = this.dialogRef.afterOpened.asObservable().subscribe(() => ++this.popupsCount);
  }

  private setNotificationsSubscription(): void {
    this.notificationsSubscription = this.notificationsService.afterOpened
      .asObservable()
      .subscribe(() => ++this.popupsCount);
  }

  private isLimitReached(): boolean {
    return this.popupsCount >= this.remotePopupsLimit;
  }

  private initiateAppRateSequence$(): Observable<null> {
    if (this.userService.isGuest) return of(null);
    const user = this.globalStateService.getUser();
    if (!user.hasOrdered) return of(null);
    const hasRated = Boolean(user?.appRating?.web);
    if (hasRated) return of(null);
    const lastOrder = this.globalStateService.getOrderHistory()[0];
    if (!lastOrder?.createdAt) return of(null);
    const currentDate = dayjs();
    const lastOrderDate = dayjs(lastOrder.createdAt);
    if (currentDate.diff(lastOrderDate, 'hours') <= 2) return of(null);
    const notificationRef = this.notificationsService.addNotification(RateAppNotificationWrapperComponent);
    return notificationRef.afterDismissed.pipe(mapTo(null));
  }

  private initiatesRewardsPointsNotificationSequence$(): Observable<null> {
    const showNotification = this.homePageService.shouldShowRewardsPointsNotification();
    if (!showNotification) return of(null);

    const isFirstLoginThisMonthVar = isFirstLoginThisMonth(this.globalStateService.getLastLoginDate());

    const notificationData = this.homePageService.getRewardsPointsNotificationData(isFirstLoginThisMonthVar);
    if (!notificationData) return of(null);

    const notificationRef = this.notificationsService.addNotification(BenefitsBannerWrapperComponent, {
      data: {
        currencyText: notificationData,
        infoText: isFirstLoginThisMonthVar ? 'last_month_u_won' : 'last_week_u_won'
      }
    });

    return notificationRef.afterDismissed.pipe(
      tap(() => saveRewardsPointsNotificationTimestamp()),
      mapTo(null)
    );
  }
}
