import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, PromoPagesService, SEOService, TimeslotsService, ShopsService } from '@box-core/services';
import { APIError, FAQquestion, PromoCoupon, PromoPageData, SeoOptions, Shop } from '@box-types';
import {
  isShopFilterableItem,
  normalizeWebAction,
  setPrerenderReady,
  getFAQPageLd,
  addOrUpdateJsonLd,
  faqPageLdId,
  webActionIsDynamicLink
} from '@box/utils';
import { Subscription, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'promo-page',
  templateUrl: './promo.page.html',
  styleUrls: ['./promo.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoPage implements OnInit, OnDestroy {
  public loading: boolean;
  public promoPageData: PromoPageData;
  public heroImage: string;
  public heroBackgroundColor: string;
  public title: string;
  public description: string;
  public seoTitle: string;
  public seoText: string;
  public buttonTitle: string;
  public relatedShops: Shop[];
  public faqTitle: string;
  public faq: FAQquestion[];
  public coupon: PromoCoupon;
  public webAction: string;

  private addressSubscription: Subscription;

  constructor(
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private promoPagesService: PromoPagesService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private timeslotsService: TimeslotsService,
    private globalStateService: GlobalStateService,
    private shopsService: ShopsService
  ) {}

  ngOnInit(): void {
    setPrerenderReady(false);
    this.initPage();
    this.setHeaderSubscription();
    this.setMetaTags();
    this.setFAQBreadcrumbs();
  }

  ngOnDestroy(): void {
    this.addressSubscription?.unsubscribe();
  }

  private initPage(): void {
    this.setPromoPageData();
    setPrerenderReady(true);
  }

  private setPromoPageData(): void {
    this.promoPageData = this.activatedRoute.snapshot.data.promoPage as PromoPageData;
    this.title = this.promoPageData.title;
    this.description = this.promoPagesService.getPromoPageBlocksHtml(this.promoPageData?.description);
    this.seoTitle = this.promoPageData.seoTitle;
    this.seoText = this.promoPagesService.getPromoPageBlocksHtml(this.promoPageData?.seoText);
    this.buttonTitle = this.promoPageData.buttonTitle;
    this.relatedShops = this.promoPageData?.relatedShops;
    this.faqTitle = this.promoPageData.faqTitle;
    this.faq = this.promoPageData?.faq;
    this.heroImage = this.promoPagesService.getPromoPageHeroBackgroundImageUrl(this.promoPageData);
    this.heroBackgroundColor = '#121212';
    this.coupon = this.promoPageData?.coupon;
    this.webAction = this.promoPageData?.webAction;
  }

  private setHeaderSubscription(): void {
    if (!isShopFilterableItem(this.promoPageData)) {
      setPrerenderReady(true);
      return;
    }

    this.addressSubscription = combineLatest([this.globalStateService.address$, this.timeslotsService.timeslot$])
      .pipe(
        tap(() => {
          this.loading = true;
          this.changeDetectorRef.detectChanges();
        }),
        switchMap(() => this.shopsService.getShops$())
      )
      .subscribe({
        next: () => {
          const decoratedPromoPage = this.promoPagesService.decoratePromoPageWithFilteredShops(this.promoPageData);
          this.relatedShops = decoratedPromoPage?.relatedShops;
          this.loading = false;
          this.changeDetectorRef.detectChanges();
          setPrerenderReady(true);
        },
        error: (error: APIError) => this.promoPagesService.handlePromoPageError(error)
      });
  }

  private setFAQBreadcrumbs(): void {
    if (!this.faq?.length) return;
    const faqPageLd = getFAQPageLd(this.faq);
    if (!faqPageLd) return;
    addOrUpdateJsonLd(faqPageLdId, faqPageLd);
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: this.title, url: this.router.url };
    this.seoService.setTags(options);
  }

  public onCtaClick(): void {
    const webAction = this.webAction;
    if (!webAction) return void this.router.navigateByUrl('/discover');
    const isDynamicLink = webActionIsDynamicLink(webAction);
    if (isDynamicLink) {
      window.location.replace(webAction);
      return;
    }
    const navigationUrl = normalizeWebAction(webAction);
    return void this.router.navigateByUrl(navigationUrl);
  }

  public onShopClick(event: { shop: Shop; index: number }): void {
    this.analyticsService.updatePurchaseEvent(event.shop._id, {
      index: event.index,
      itemListName: this.promoPageData.slug,
      creativeSlot: 'main_vp_promo_page',
      promotionName: this.promoPageData.title
    });
  }
}
