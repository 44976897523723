import {
  Component,
  HostBinding,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { AnalyticsService, translate } from '@box-core/services';
import { GAPromotionConfig, BoxNavigationOptions, BoxNavigationTabOptions } from '@box-types';
import { Router, Event, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'box-navigation',
  templateUrl: './box-navigation.component.html',
  styleUrls: ['./box-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxNavigationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public options: BoxNavigationOptions;
  @HostBinding('class') public hostClass = 'box-navigation';
  public url: string;
  public translate = translate;

  private routeSubscription: Subscription;
  constructor(
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  public ngOnInit(): void {
    this.setRouteSubscription();
  }

  public ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.options = changes.options.currentValue;
      this.changeDetectorRef.detectChanges();
    }
  }

  private setRouteSubscription(): void {
    if (this.routeSubscription) return;
    this.url = this.location.path();
    this.changeDetectorRef.detectChanges();
    this.routeSubscription = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
        this.changeDetectorRef.detectChanges();
      });
  }

  public onBoxNavItemClick(tab: BoxNavigationTabOptions): void {
    const gaConfig = {
      creative_name: translate(tab.title),
      creative_slot: tab.creativeSlot,
      promotion_id: '',
      promotion_name: 'tabs'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    tab.action();
  }
}
