import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DiscoverBusinessVerticalService, DiscoverSearchService } from '@box-discover/services';
import { AnalyticsService } from '@box-core/services';
import { GASearchConfig } from '@box-types';
import { getDiscoverPageCreativeSlot } from '@box/utils/src/discover/discover-page-analytics.utils';
import { LocationsService } from '@box-delivery/services';

@Component({
  selector: 'discover-search-input',
  templateUrl: './discover-search-input.component.html',
  styleUrls: ['./discover-search-input.component.scss']
})
export class DiscoverSearchInputComponent implements OnInit, OnDestroy {
  @HostBinding('class') public hostClass = 'discover-search-input';
  @Input() public placeholder = 'search_shop';

  public searchTerm: string;
  private searchSubscription: Subscription;

  constructor(
    private discoverSearchService: DiscoverSearchService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private analyticsService: AnalyticsService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.setSearchSubscription();
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  public onSearch(searchTerm: string): void {
    const trimmedTerm = searchTerm.trim();
    if (trimmedTerm?.length === 0) return this.clearInput();
    if (trimmedTerm?.length < 3) return;
    this.discoverSearchService.setSearchTerm(trimmedTerm);
    this.triggerAnalyticsEvent(trimmedTerm);
  }

  public clearInput(): void {
    this.discoverSearchService.clearSearchTerm();
  }

  private setSearchSubscription(): void {
    this.searchSubscription = this.discoverSearchService.searchTerm.subscribe(
      (searchTerm) => (this.searchTerm = searchTerm)
    );
  }

  private triggerAnalyticsEvent(searchTerm: string): void {
    const isLandingPage = this.locationsService.isSeoLocationPage();
    const businessVertical = this.discoverBusinessVerticalService.getBusinessVertical();
    const source = getDiscoverPageCreativeSlot(businessVertical, isLandingPage);
    const gaConfig = {
      search_term: searchTerm,
      source: source
    } as GASearchConfig;
    this.analyticsService.addGAEcommerceEvent('search', gaConfig);
  }
}
