import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TimeslotsService, AnalyticsService } from '@box-core/services';
import { Location, Cuisine, DeliveryAnchor, Shop, APIError, CollapsibleTile, Brand } from '@box-types';
import { combineLatest, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LocationCuisinePageService } from './location-cuisine-page.service';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';
import {
  deleteJsonLd,
  setPrerenderReady,
  clearExistingRedirectPrerenderMetaElements,
  generateShopsTitleDescription,
  DiscoverTilesOptions
} from '@box/utils';
import { translate as t } from '@box-core/services/language.service';

@Component({
  selector: 'location-cuisine',
  templateUrl: './location-cuisine.page.html',
  styleUrls: ['./location-cuisine.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationCuisinePageService]
})
export class LocationCuisinePage implements OnInit, OnDestroy {
  public location: Location;
  public cuisine: Cuisine;
  public shops: Shop[] = [];
  public topShops: Shop[] = [];
  public loading: boolean;
  public cuisinesAnchors: DeliveryAnchor[];
  public shopsTitleDescription: string;
  public showBrands: boolean;
  public tiles: CollapsibleTile<Brand>[];
  public brandsTilesOptions: DiscoverTilesOptions;

  private pageSubscription: Subscription;

  constructor(
    private timeslotsService: TimeslotsService,
    private changeDetectorRef: ChangeDetectorRef,
    private locationCuisinePageService: LocationCuisinePageService,
    private breadCrumbService: BreadCrumbService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public pageWrapper = 'page-wrapper';

  ngOnInit(): void {
    setPrerenderReady(false);
    this.initPage();
  }

  ngOnDestroy(): void {
    this.pageSubscription?.unsubscribe();
    clearExistingRedirectPrerenderMetaElements();
    deleteJsonLd();
  }

  private initPage(): void {
    this.loading = true;
    this.pageSubscription = combineLatest({
      cuisine: this.locationCuisinePageService.cuisine$().pipe(tap((cuisine) => (this.cuisine = cuisine))),
      location: this.locationCuisinePageService.location$().pipe(
        tap((location) => {
          this.location = location;
          this.cuisinesAnchors = this.locationCuisinePageService.generateCuisinesDeliveryAnchors(location);
        })
      ),
      timeslot: this.timeslotsService.timeslot$
    })
      .pipe(
        tap(() => {
          this.loading = true;
          clearExistingRedirectPrerenderMetaElements();
          this.changeDetectorRef.detectChanges();
        }),
        switchMap(({ cuisine, location }) =>
          this.locationCuisinePageService
            .fetchShopsByCuisineAndLocation(location, cuisine)
            .pipe(map((shops) => ({ location, cuisine, shops })))
        )
      )
      .subscribe({
        next: ({ location, cuisine, shops }) => {
          if (!shops.length) this.locationCuisinePageService.handleEmptyStatePage(location);
          this.breadCrumbService.addBreadcrumbs({ locationWithCuisine: `${cuisine.name} ${location.name}` });
          this.breadCrumbService.addBreadcrumbs({ location: location.name });
          this.locationCuisinePageService.setLocationCuisinePageMetaTags(location, cuisine);
          this.locationCuisinePageService.setShops(shops);
          this.shops = this.locationCuisinePageService.getShops();
          this.shopsTitleDescription = generateShopsTitleDescription(shops.length, t);
          this.topShops = this.locationCuisinePageService.getTopShops(shops);
          this.setCuisineBrandsInfo(cuisine);
          this.loading = false;
          this.changeDetectorRef.detectChanges();
          setPrerenderReady(true);
        },
        error: (error: APIError) => this.locationCuisinePageService.handleLocationPageError(error)
      });
  }

  private setCuisineBrandsInfo(cuisine: Cuisine): void {
    this.tiles = this.locationCuisinePageService.generateTiles(cuisine);
    this.brandsTilesOptions = { title: 'see_the_stores_by_variety', size: 'medium' };
    this.showBrands = this.tiles?.length > 0;
  }

  public onTileToggle(tile: CollapsibleTile<Brand>): void {
    const activeBrand = tile?.item;
    this.locationCuisinePageService.setCheckedBrand(activeBrand);
    this.shops = this.locationCuisinePageService.getFilteredShopsByBrand();
    this.shopsTitleDescription = generateShopsTitleDescription(this.shops?.length, t);
    this.changeDetectorRef.detectChanges();
  }

  public onShopClick(event: { shop: Shop; index: number }): void {
    this.analyticsService.updatePurchaseEvent(event.shop._id, {
      index: event.index,
      itemListName: this.location.key + '-' + this.cuisine.key,
      creativeSlot: 'location_cuisine_page',
      promotionName: this.location.name + '-' + this.cuisine.name
    });
  }
}
