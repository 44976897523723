import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route, UrlSegment, UrlSegmentGroup, UrlMatchResult } from '@angular/router';

import * as Resolvers from './resolvers';
import * as Guards from './guards';
import * as Pages from './pages';
import { HomePage } from '@box-core/pages';
import { DiscoverShellComponent } from '@box-discover/components';
import { BusinessVertical } from '@box-types';

export const deliveryRoutes: Routes = [
  {
    // /delivery
    component: Pages.LocationsPage,
    data: { name: 'Locations Page', breadcrumbName: '', breadcrumbKey: 'location' },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const condition = group?.segments?.length === 1 && group?.segments[0]?.path === 'delivery';
      if (!condition) return null;

      return { consumed: segments };
    }
  },
  {
    // /delivery/locationKey
    component: HomePage,
    canActivate: [Guards.LocationGuard],
    data: { name: 'Location Page', breadcrumbName: '', breadcrumbKey: 'location' },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition = groupSegments?.length === 2 && groupSegments[0]?.path === 'delivery';
      if (!condition) return null;
      const secondSegment = groupSegments[1]?.path;
      if (!secondSegment || secondSegment === 'kouzines' || secondSegment === 'psonia') {
        return { consumed: [groupSegments[0]] };
      }
      const locationKey = new UrlSegment(secondSegment, {});
      return { consumed: segments, posParams: { locationKey } };
    }
  },
  {
    // /delivery/locationKey/psonia /delivery/locationKey/estiatoria
    component: DiscoverShellComponent,
    canActivate: [Guards.LocationGuard],
    data: { name: 'Location Discover Page', breadcrumbName: '', breadcrumbKey: 'location' },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 3 &&
        groupSegments[0]?.path === 'delivery' &&
        (groupSegments[2]?.path === 'psonia' || groupSegments[2]?.path === 'estiatoria');
      if (!condition) return null;
      const secondSegment = groupSegments[1]?.path;
      if (!secondSegment || secondSegment === 'kouzines' || secondSegment === 'psonia') {
        return { consumed: [groupSegments[0]] }; // redirect to delivery
      }
      const thirdSegment = groupSegments[2]?.path;
      const segmentToBusinessVertical: BusinessVertical = thirdSegment === 'estiatoria' ? 'food' : 'groceries';
      const locationKey = new UrlSegment(secondSegment, {});
      const businessVertical = new UrlSegment(segmentToBusinessVertical, {});
      return { consumed: segments, posParams: { locationKey, businessVertical } };
    }
  },
  {
    // /delivery/kouzines/:cuisineKey
    component: Pages.CuisinePage,
    resolve: { cuisine: Resolvers.CuisineResolver },
    data: { name: 'Food Cuisine Page', breadcrumbName: '', breadcrumbKey: 'cuisine' },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 3 && groupSegments[0]?.path === 'delivery' && groupSegments[1]?.path === 'kouzines';
      if (!condition) return null;

      const cuisineKey = new UrlSegment(groupSegments[2]?.path, {});
      return { consumed: segments, posParams: { cuisineKey } };
    }
  },
  {
    // /delivery/psonia/:cuisineKey
    component: Pages.CuisinePage,
    resolve: { cuisine: Resolvers.CuisineResolver },
    data: { name: 'Groceries Category Page', breadcrumbName: '', breadcrumbKey: 'cuisine' },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 3 && groupSegments[0]?.path === 'delivery' && groupSegments[1]?.path === 'psonia';
      if (!condition) return null;

      const cuisineKey = new UrlSegment(groupSegments[2]?.path, {});
      return { consumed: segments, posParams: { cuisineKey } };
    }
  },
  {
    // /delivery/:locationKey/:vanityUrl
    component: Pages.ShopPage,
    children: [{ path: '', component: Pages.ShopMenuPage, data: { breadcrumbName: '', breadcrumbKey: 'shopName' } }],
    data: { name: 'Shop Menu Page', breadcrumbName: '', breadcrumbKey: 'location' },
    resolve: { shopResolverData: Resolvers.ShopResolver },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition = groupSegments?.length === 3 && groupSegments[0]?.path === 'delivery';
      if (!condition) return null;

      const locationKey = new UrlSegment(groupSegments[1]?.path, {});
      const vanityUrl = new UrlSegment(groupSegments[2]?.path, {});
      return {
        consumed: segments,
        posParams: { locationKey, vanityUrl }
      };
    }
  },
  {
    // /delivery/:locationKey/kouzines/:cuisineKey
    component: Pages.LocationCuisinePage,
    data: {
      name: 'Location Food Cuisine Page',
      shouldReuse: false,
      breadcrumbName: '',
      breadcrumbKey: 'locationWithCuisine'
    },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 4 && groupSegments[0]?.path === 'delivery' && groupSegments[2]?.path === 'kouzines';
      if (!condition) return null;

      const locationKey = new UrlSegment(groupSegments[1]?.path, {});
      const cuisineKey = new UrlSegment(groupSegments[3]?.path, {});
      return {
        consumed: segments,
        posParams: { locationKey, cuisineKey }
      };
    }
  },
  {
    // /delivery/:locationKey/psonia/:cuisineKey
    component: Pages.LocationCuisinePage,
    data: {
      name: 'Location Groceries Cuisine Page',
      shouldReuse: false,
      breadcrumbName: '',
      breadcrumbKey: 'locationWithCuisine'
    },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 4 && groupSegments[0]?.path === 'delivery' && groupSegments[2]?.path === 'psonia';
      if (!condition) return null;

      const locationKey = new UrlSegment(groupSegments[1]?.path, {});
      const cuisineKey = new UrlSegment(groupSegments[3]?.path, {});
      return {
        consumed: segments,
        posParams: { locationKey, cuisineKey }
      };
    }
  },
  {
    // /delivery-chains/menu/:chain-slug
    component: Pages.ShopPage,
    children: [{ path: '', component: Pages.ShopMenuPage, data: { breadcrumbName: '', breadcrumbKey: 'shopName' } }],
    data: { name: 'Shop Menu Page', breadcrumbName: 'skipBreadcrumb', breadcrumbKey: 'location' },
    resolve: { shopResolverData: Resolvers.ChainMainShopResolver },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition =
        groupSegments?.length === 3 &&
        groupSegments[0]?.path === 'delivery-chains' &&
        groupSegments[1]?.path === 'menu';
      if (!condition) return null;

      const chainSlug = new UrlSegment(groupSegments[2]?.path, {});
      return {
        consumed: segments,
        posParams: { chainSlug }
      };
    }
  },
  {
    // /delivery-promos/:promotionKey
    component: Pages.PromoPage,
    data: { name: 'Promo Page', breadcrumbName: '', breadcrumbKey: 'promoPage' },
    resolve: { promoPage: Resolvers.PromoPageResolver },
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {
      const groupSegments = group?.segments;
      const condition = groupSegments?.length === 2 && groupSegments[0]?.path === 'delivery-promos';
      if (!condition) return null;

      const promotionKey = new UrlSegment(groupSegments[1]?.path, {});
      return {
        consumed: segments,
        posParams: { promotionKey }
      };
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(deliveryRoutes)],
  exports: [RouterModule]
})
export class DeliveryRoutingModule {}
