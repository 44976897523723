import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'skeleton-shops',
  templateUrl: './skeleton-shops.component.html',
  styleUrls: ['./skeleton-shops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonShopsComponent implements OnChanges {
  @Input() public showTitle = false;
  @HostBinding('class') public hostClass = 'skeleton-shops';

  ngOnChanges(changes: SimpleChanges): void {
    this.showTitle = changes.showTitle.currentValue as boolean;
  }
}
