import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DiscoverSearchItems, DiscoverSearchItemsOptions, DiscoverSearchResultItem, Shop } from '@box-types';

const defaultOptions: DiscoverSearchItemsOptions = { minimumSearchItems: 3, maximumSearchItems: 10 };

@Component({
  selector: 'discover-search-result-items',
  templateUrl: './discover-search-result-items.component.html',
  styleUrls: ['./discover-search-result-items.component.scss']
})
export class DiscoverSearchResultItemsComponent implements OnInit {
  @HostBinding('class') public hostClass = 'discover-search-result-items';
  @Input() public result: DiscoverSearchItems<DiscoverSearchResultItem>;
  @Input() public options: DiscoverSearchItemsOptions = defaultOptions;
  @Input() public type: 'product' | 'offer' = 'product';
  @Output() private itemSelect = new EventEmitter<DiscoverSearchResultItem>();
  @Output() private shopSelect = new EventEmitter<Shop>();

  public shop: Shop;
  public items: DiscoverSearchResultItem[];
  public showExpansionButton: boolean;
  public showShopButton: boolean;
  public firstGroup: DiscoverSearchResultItem[];
  public secondGroup: DiscoverSearchResultItem[];
  public expanded: boolean;
  public expandButtonText: string;

  ngOnInit(): void {
    this.shop = this.result.shop;
    this.items = this.result.items;
    this.showExpansionButton = this.items.length > this.options.minimumSearchItems;
    this.showShopButton = this.items.length > this.options.maximumSearchItems;
    this.firstGroup = this.items.slice(0, this.options.minimumSearchItems);
    this.secondGroup = this.items.slice(this.options.minimumSearchItems, this.options.maximumSearchItems);
    this.expandButtonText = this.generateExpandButtonText();
  }

  public onShopCardClick(shop: Shop): void {
    this.shopSelect.emit(shop);
  }

  public onItemClick(item: DiscoverSearchResultItem): void {
    this.itemSelect.emit(item);
  }

  public onExpansionToggle(): void {
    this.expanded = !this.expanded;
  }

  private generateExpandButtonText(): string {
    if (this.type === 'product') return 'see_more_products';
    return 'see_more_offers';
  }
}
