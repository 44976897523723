import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap, take } from 'rxjs/operators';
import {
  Shop,
  FetchShopOptions,
  Address,
  CanDeliverToCoordsResponse,
  APIResponse,
  ChainShopListItem,
  NotificationAlert,
  FetchShopsOptions
} from '@box-types';
import { environment } from '@box-env/environment';
import {
  hasShopAtLeastOneDayOpen,
  normalizeShop,
  ObservableCacheDecorator,
  isAddressReadyForView,
  filterShopsByChainKeys,
  getClosestItemToAddress,
  sortShops
} from '@box/utils';
import pickBy from 'lodash-es/pickBy';
import { ShopService } from '@box-core/services/shop.service';
import { UrgentConditionNotificationService } from '@box-core/services/urgent-condition-notification.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { TimeslotsService } from '@box-core/services/timeslots.service';

const SHOPS_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 minutes
const SHOP_BY_VANITY_EXPIRATION = 1 * 5 * 1000; // 5 seconds
const defaultAddress: Address = { longitude: 23.7275388, latitude: 37.9838096, postalCode: '10560' };
const invalidationSignalShopsSource = new Subject<string>();
const invalidationSignalShops$: Observable<string> = invalidationSignalShopsSource.asObservable();

@Injectable({ providedIn: 'root' })
export class ShopsService {
  private BOX_API: string = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private shopService: ShopService,
    private urgentConditionNotificationService: UrgentConditionNotificationService,
    private globalStateService: GlobalStateService,
    private timeslotsService: TimeslotsService
  ) {}

  /*
   * We are using ObservableCacheDecorator to prevent two requests
   * when we navigate in the shop. This is happening in cases where
   * we are calling this function with router.navigate (shop resolver triggered) because of
   * locationKey need. Example order-status.service.ts
   */
  @ObservableCacheDecorator<Shop[], string>({
    expirationTimeInMs: SHOP_BY_VANITY_EXPIRATION,
    functionKeyArgumentIndexes: [0]
  })
  public fetchShopByVanityUrl$(vanityUrl: string, options?: FetchShopOptions): Observable<Shop> {
    const headers = new HttpHeaders({ 'Accept-Version': '3' });
    const pickedOptions = pickBy(options) as FetchShopOptions;
    const params: HttpParams = new HttpParams({ fromObject: { vanity_url: vanityUrl, ...pickedOptions } });
    return this.http.get(`${this.BOX_API}/shops/findShopByVanityUrl`, { params, headers }).pipe(
      tap((response: APIResponse<{ shop: Shop; notificationAlert: NotificationAlert }>) => {
        this.urgentConditionNotificationService.setShopPageNotificationAlert(response.payload.notificationAlert);
      }),
      map((response: APIResponse<{ shop: Shop; notificationAlert: NotificationAlert }>) => {
        return response.payload.shop;
      })
    );
  }

  public canDeliverToAddress(shopId: string, address: Address): Observable<CanDeliverToCoordsResponse> {
    const headers = new HttpHeaders({ 'Accept-Version': '3' });
    const url = `/food/canDeliverToCoords/${shopId}/${address.latitude}/${address.longitude}`;
    return this.http.get(this.BOX_API + url, { headers }).pipe(
      map(
        (
          response: APIResponse<{
            shops: CanDeliverToCoordsResponse;
          }>
        ) => response.payload.shops
      )
    );
  }

  public triggerShopsCacheInvalidation(): void {
    // providing a null value clears all the cache entries for every key
    invalidationSignalShopsSource.next(null);
  }

  @ObservableCacheDecorator<Shop[], string>({
    expirationTimeInMs: SHOPS_SESSION_EXPIRATION,
    functionKeyArgumentIndexes: [0, 1, 2],
    invalidateSignal$: invalidationSignalShops$
  })
  public fetchShops(latitude: number, longitude: number, postalCode: string): Observable<Shop[]> {
    const headers = new HttpHeaders({ 'Accept-Version': '3' });
    const params: HttpParams = this.generateFetchShopsParams(latitude, longitude, postalCode);
    if (!params) return of([]);
    return this.http.get(`${this.BOX_API}/shops`, { params, headers }).pipe(
      tap((response: APIResponse<{ shops: Shop[]; notificationAlert: NotificationAlert }>) => {
        this.urgentConditionNotificationService.setHomePageNotificationAlert(response.payload.notificationAlert);
      }),
      map((response: APIResponse<{ shops: Shop[]; notificationAlert: NotificationAlert }>) => {
        const shops = response.payload.shops;
        const normalizedShops = shops.map((shop) => normalizeShop(shop));
        return normalizedShops.filter((shop: Shop) => shop.logo && hasShopAtLeastOneDayOpen(shop));
      })
    );
  }

  public getShops$(): Observable<Shop[]> {
    const fetchShopOptions = this.getFetchShopsOptions();
    return this.fetchShops(fetchShopOptions.latitude, fetchShopOptions.longitude, fetchShopOptions.postalCode).pipe(
      take(1),
      map((shops) => {
        const decoratedShops = shops.map((shop) => this.shopService.shopPromoDecorator(shop));
        const timeslot = this.timeslotsService.getTimeslot();
        return sortShops(decoratedShops, timeslot);
      }),
      tap((shops) => this.globalStateService.setShops(shops)),
      catchError(() => of([]))
    );
  }

  private generateFetchShopsParams(latitude: number, longitude: number, postalCode: string): HttpParams {
    if (!latitude || !longitude || !postalCode) return;
    const paramsObject: Record<string, string> = {};
    paramsObject.latitude = String(latitude);
    paramsObject.longitude = String(longitude);
    paramsObject.postalCodes = String(postalCode);
    return new HttpParams({ fromObject: paramsObject });
  }

  public getClosestShopByChainKey(chainKey: string): Observable<Shop> {
    const address = this.globalStateService.getAddress();
    if (!chainKey?.length || !isAddressReadyForView(address)) return of(null);

    return this.getShops$().pipe(
      map((shops) => {
        if (!shops?.length) return;
        const shopsWithChain = filterShopsByChainKeys(shops, [chainKey]);
        if (!shopsWithChain?.length) return;
        return getClosestItemToAddress(address, shopsWithChain);
      }),
      catchError(() => of(null))
    );
  }

  @ObservableCacheDecorator({ functionKeyArgumentIndexes: [0] })
  public getShopsByChainKey(chainKey: string): Observable<ChainShopListItem[]> {
    return this.http
      .get(`${this.BOX_API}/shops/chain/${chainKey}`)
      .pipe(map((response: APIResponse<{ shops: ChainShopListItem[] }>) => response.payload.shops));
  }

  private getFetchShopsOptions(): FetchShopsOptions {
    const address = this.globalStateService.getAddress();
    if (!address) {
      return {
        latitude: defaultAddress.latitude,
        longitude: defaultAddress.longitude,
        postalCode: defaultAddress.postalCode
      };
    }
    return {
      latitude: address.latitude,
      longitude: address.longitude,
      postalCode: address.postalCode
    };
  }
}
