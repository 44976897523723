import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DiscoverFilteringOption } from '@box-types';
import { map, Subscription } from 'rxjs';
import { DiscoverShopsService } from '@box-discover/services';

@Component({
  selector: 'discover-active-filters',
  templateUrl: './discover-active-filters.component.html',
  styleUrls: ['./discover-active-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverActiveFiltersComponent implements OnInit, OnDestroy {
  public activeFilteringOptions: DiscoverFilteringOption[];
  private discoverSubscription: Subscription;

  constructor(private discoverShopsService: DiscoverShopsService, private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'discover-active-filters';

  ngOnInit(): void {
    this.setDiscoverSubscription();
  }

  ngOnDestroy(): void {
    this.discoverSubscription?.unsubscribe();
  }

  public onRemoveFilter(filteringOption: DiscoverFilteringOption): void {
    this.discoverShopsService.changeFilteringOption(filteringOption, false);
  }

  private setDiscoverSubscription(): void {
    this.discoverSubscription = this.discoverShopsService.filteringOptions$
      .pipe(map((filteringOptions) => filteringOptions?.filter((option) => option.checked)))
      .subscribe((activeFilteringOptions) => {
        this.activeFilteringOptions = activeFilteringOptions;
        this.changeDetectorRef.detectChanges();
      });
  }
}
