import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DiscoverFilter } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { DiscoverFiltersService } from '@box-core/services/discover-filters.service';
import { Mousewheel, Navigation } from 'swiper/modules';
import { SwiperDirective } from '@box-shared/directives';
import { AnalyticsService } from '@box-core/services';
import { getCollectionDiscoverFiltersImpressionGAConfig } from '@box/utils';
import { LocationsService } from '@box-delivery/services';

@Component({
  selector: 'discover-filters',
  templateUrl: './discover-filters.component.html',
  styleUrls: ['./discover-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverFiltersComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(SwiperDirective) private swiperDirective: SwiperDirective;
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;
  @Input() public title = 'filter_per_recommendation';
  @Input() public discoverFilters: DiscoverFilter[];
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1;

  public swiperOptions: SwiperOptions;

  constructor(
    private discoverFiltersService: DiscoverFiltersService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private locationsService: LocationsService
  ) {}

  @HostBinding('class') public hostClass = 'discover-filters';

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) this.renderer.addClass(buttonContainer, 'hidden');
    this.initializeActiveIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.discoverFilters) this.discoverFilters = changes.discoverFilters.currentValue as DiscoverFilter[];
  }

  public onDiscoverFilterToggle(discoverFilter: DiscoverFilter): void {
    this.discoverFiltersService.toggleDiscoverFilter(discoverFilter);
    if (!discoverFilter.active) return;
    this.triggerAnalyticsEvent('select_promotion', discoverFilter);
  }

  public onDiscoverFilterEnteredViewport(discoverFilter: DiscoverFilter): void {
    this.triggerAnalyticsEvent('view_promotion', discoverFilter);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private initializeActiveIndex(): void {
    const activeIndex = this.discoverFilters.findIndex((f) => f.active);
    this.swiperDirective.getSwiper().activeIndex = activeIndex;
    this.changeDetectorRef.detectChanges();
  }

  private triggerAnalyticsEvent(eventName: string, discoverFilter: DiscoverFilter): void {
    const promotionName = this.title;
    const creativeSlot = this.locationsService.isSeoLocationPage() ? 'discover_estiatoria' : 'discover_food';
    const gaConfig = getCollectionDiscoverFiltersImpressionGAConfig(
      promotionName,
      this.discoverFilters,
      discoverFilter,
      creativeSlot
    );
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}
