import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DiscoverSearchService, OrdersService, ShopsService, TimeslotsService, UserService } from '@box-core/services';
import { DiscoverBrandsService, DiscoverLoaderService } from '@box-discover/services';
import { DiscoverBusinessVerticalService } from '@box-discover/services/discover-business-vertical.service';
import { DiscoverCuisinesService } from '@box-discover/services/discover-cuisines.service';
import { BusinessVertical, Shop, BoxNavigationOptions } from '@box-types';
import { combineLatest, forkJoin, Subscription, Observable } from 'rxjs';
import { DiscoverFiltersService } from '@box-core/services/discover-filters.service';
import { getDiscoverFilterSlugsFromQueryString } from '@box/utils';
import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { finalize, tap } from 'rxjs/operators';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { LocationsService } from '@box-delivery/services';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'discover-shell',
  templateUrl: './discover-shell.component.html',
  styleUrls: ['./discover-shell.component.scss']
})
export class DiscoverShellComponent implements OnInit, OnDestroy {
  @HostBinding('class') public pageWrapper = 'page-wrapper';
  private discoverSubscription: Subscription;
  private searchSubscription: Subscription;
  private loaderSubscription: Subscription;
  private breakPointObserverSubscription: Subscription;

  public searchPlaceholder: string;
  public loadingData: boolean;
  public isMobileScreen: boolean;
  public showSearchPage: boolean;
  public isSeoLocationPage: boolean;
  public url: string;
  public boxNavigationOptions: BoxNavigationOptions;

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private ordersService: OrdersService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private discoverSearchService: DiscoverSearchService,
    private discoverLoaderService: DiscoverLoaderService,
    private discoverFiltersService: DiscoverFiltersService,
    private discoverCuisinesService: DiscoverCuisinesService,
    private discoverBrandsService: DiscoverBrandsService,
    private breakpointObserver: BreakpointObserver,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private globalStateService: GlobalStateService,
    private activatedRoute: ActivatedRoute,
    private locationsService: LocationsService,
    private breadCrumbService: BreadCrumbService
  ) {}

  ngOnInit(): void {
    this.isSeoLocationPage = this.locationsService.isSeoLocationPage();
    this.setDiscoverSubscription();
    this.setLoaderSubscription();
    this.setBreakPointObserverSubscription();
    this.setBoxNavigationOptions();
  }

  ngOnDestroy(): void {
    this.discoverSubscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
    this.loaderSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
    this.discoverSearchService.clearSearchTerm();
    this.discoverSearchService.clearSearchResults();
  }

  private getSearchPlaceholderText(businessVertical: BusinessVertical): string {
    if (businessVertical === 'groceries') return 'search_shop_or_product';
    return 'search_shop_dish';
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      this.isMobileScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    });
  }

  private setLoaderSubscription(): void {
    this.loaderSubscription = this.discoverLoaderService.loadingData.subscribe((value) => {
      this.loadingData = value;
    });
  }

  private setDiscoverSubscription(): void {
    this.discoverSubscription = combineLatest({
      address: this.globalStateService.address$,
      timeslot: this.timeslotsService.timeslot$,
      user: this.globalStateService.user$,
      coupons: this.globalStateService.availableCoupons$
    }).subscribe(() => {
      if (this.searchSubscription) this.searchSubscription.unsubscribe();
      this.fetchDiscoverData();
    });
  }

  private fetchDiscoverData(): void {
    this.discoverLoaderService.setLoadingData(true);
    forkJoin({
      shops: this.getShops$(),
      orders: this.ordersService.getOrderHistory$(),
      discoverFilters: this.discoverFiltersService.getDiscoverFilters$()
    })
      .pipe(
        finalize(() => {
          this.discoverLoaderService.setLoadingData(false);
        })
      )
      .subscribe(({ shops }) => {
        this.buildStateFromUrl(shops);
        this.setSearchSubscription();
      });
  }

  private setSearchSubscription(): void {
    if (this.isSeoLocationPage) {
      this.showSearchPage = false;
      return;
    }

    this.searchSubscription = combineLatest([
      this.discoverSearchService.searchTerm,
      this.discoverBusinessVerticalService.businessVertical
    ]).subscribe(([searchTerm, businessVertical]) => {
      this.searchPlaceholder = this.getSearchPlaceholderText(businessVertical);
      this.showSearchPage = Boolean(searchTerm?.length);
    });
  }

  private buildStateFromUrl(shops: Shop[]): void {
    this.globalStateService.setShops(shops);
    const queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);

    const searchTerm = queryParams.get('query');
    if (searchTerm) this.initializeSearch(searchTerm);

    const businessVertical = this.getVerticalFromUrl(queryParams);
    this.discoverBusinessVerticalService.setBusinessVertical(businessVertical);

    const activeCuisineKey = this.discoverCuisinesService.getCuisineNameFromQueryString(queryParams);
    this.discoverCuisinesService.init(shops, activeCuisineKey);

    const activeBrandName = this.discoverBrandsService.getBrandNameFromQueryString(queryParams);
    this.discoverBrandsService.init(activeBrandName, activeCuisineKey);

    const activeFilterSlugs = getDiscoverFilterSlugsFromQueryString(queryParams);
    this.discoverFiltersService.init(shops, activeFilterSlugs);
  }

  private initializeSearch(searchTerm: string): void {
    this.discoverSearchService.setSearchTerm(searchTerm);
  }

  private setBoxNavigationOptions(): void {
    const locationKey = this.activatedRoute.snapshot.params.locationKey;
    this.boxNavigationOptions = this.locationsService.getBoxNavigationOptions(locationKey);
  }

  private getVerticalFromUrl(urlSearchParams: URLSearchParams): BusinessVertical {
    if (this.isSeoLocationPage) {
      const urlSegments = this.activatedRoute.snapshot.url;
      if (urlSegments?.length !== 2) return;
      const verticalSegment = urlSegments[1]?.path;
      return verticalSegment === 'estiatoria' ? 'food' : 'groceries';
    } else {
      return this.discoverBusinessVerticalService.getVerticalFromQueryString(urlSearchParams);
    }
  }

  private getShops$(): Observable<Shop[]> {
    if (this.isSeoLocationPage) {
      const locationKey = this.activatedRoute.snapshot.params.locationKey;
      return this.locationsService.getShopsByLocationKey$(locationKey).pipe(
        tap(() => {
          this.breadCrumbService.addBreadcrumbs({ location: this.globalStateService.getLocation()?.name });
        })
      );
    } else {
      return this.shopsService.getShops$();
    }
  }
}
