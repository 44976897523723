import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CoreService } from '@box-core/services';
import { Cuisine } from '@box-types';
import { setRedirectPrerenderMetaElements } from '@box/utils';

@Injectable()
export class CuisineResolver implements Resolve<Cuisine> {
  constructor(private router: Router, private coreService: CoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Cuisine {
    const cuisineKey: string = route.params.cuisineKey as string;
    if (!cuisineKey) {
      setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
      return void this.router.navigate(['/delivery']);
    }

    const cuisines = this.coreService.cuisines.getValue();
    const cuisine = cuisines.find((c) => c.key === cuisineKey);
    if (!cuisine) {
      setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
      return void this.router.navigate(['/delivery']);
    }

    const isFoodCuisine = cuisine.businessVertical === 'food';
    const isGroceriesCuisine = cuisine.businessVertical === 'groceries';
    const isFoodURL = state.url.startsWith('/delivery/kouzines/');
    const isGroceriesURL = state.url.startsWith('/delivery/psonia/');
    if ((isFoodURL && isFoodCuisine) || (isGroceriesURL && isGroceriesCuisine)) return cuisine;

    if (isFoodURL) {
      setRedirectPrerenderMetaElements(window.location.origin + '/delivery/psonia/' + cuisineKey);
      return void this.router.navigate(['/delivery', 'psonia', cuisineKey]);
    }

    if (isGroceriesURL) {
      setRedirectPrerenderMetaElements(window.location.origin + '/delivery/kouzines/' + cuisineKey);
      return void this.router.navigate(['/delivery', 'kouzines', cuisineKey]);
    }
  }
}
