<ng-container *ngFor="let faq of faqQuestions; let last = last">
  <mat-expansion-panel class="faqs-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p class="faqs-question ods-typography-subtitle-s">{{ faq.question }}</p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="faqs-answer ods-typography-body-m-regular">{{ faq.answer }}</p>
  </mat-expansion-panel>
  <div *ngIf="!last" class="box-divider faqs-divider"></div>
</ng-container>
