import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [
    // Components
    Components.CartComponent,
    Components.CartProductComponent,
    Components.CartProductInstanceComponent,
    Components.CartButtonComponent,
    Components.CartDialogComponent,
    Components.CartOfferComponent,
    Components.CartOfferInstanceComponent,
    Components.MarketCartItemComponent,
    Components.MarketCartVolumeComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    // Components
    Components.CartComponent,
    Components.CartProductComponent,
    Components.CartProductInstanceComponent,
    Components.CartButtonComponent,
    Components.CartDialogComponent,
    Components.CartOfferComponent,
    Components.CartOfferInstanceComponent,
    Components.MarketCartItemComponent,
    Components.MarketCartVolumeComponent
  ]
})
export class CartWidgetModule {}
