import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PromoPageData } from '@box-types';
import { Observable, of } from 'rxjs';
import { normalizePromoPage, setPrerenderReady, addNoIndexNoFollow } from '@box/utils';
import { PromoPagesService } from '@box-core/services';
import { catchError, map } from 'rxjs/operators';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';

@Injectable({ providedIn: 'root' })
export class PromoPageResolver implements Resolve<PromoPageData> {
  constructor(
    private router: Router,
    private promoPagesService: PromoPagesService,
    private breadCrumbService: BreadCrumbService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PromoPageData> {
    const promotionKey = route.params.promotionKey as string;
    if (!promotionKey) {
      return void this.router.navigateByUrl('/404', { skipLocationChange: true });
    }

    setPrerenderReady(false);

    return this.promoPagesService.fetchPromoPage$(promotionKey).pipe(
      map((promoPage) => {
        this.breadCrumbService.addBreadcrumbs({ promoPage: promoPage.title });
        const normalizedPromoPage = normalizePromoPage(promoPage);
        if (!normalizedPromoPage.isGoogleIndexed) addNoIndexNoFollow();
        return promoPage;
      }),
      catchError(() => {
        void this.router.navigateByUrl('/404', { skipLocationChange: true });
        return of(null);
      })
    );
  }
}
