import { DiscoverSortingOption } from '../types';

export const discoverSortingOptions: DiscoverSortingOption[] = [
  {
    slug: 'recommended',
    label: 'recommended_',
    checked: true
  },
  {
    slug: 'ratingOverall',
    label: 'reviews_',
    checked: false
  },
  {
    slug: 'deliveryEstimationTime',
    label: 'delivery_time',
    checked: false
  },
  {
    slug: 'minimumAllowedDeliveryOrderTotalPrice',
    label: 'minimum_order_title',
    checked: false
  }
];
