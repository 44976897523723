import { Shop, ShopOperatingStateType, DiscoverSearchItems, GetTextByKeyType, Timeslot } from '@box-types';
import { shopRevertSwitchBackOnIsOnTheFuture } from './shop.utils';
import { getNextWorkingHours, getShopCardFutureOpenText, isShopInWorkingHours } from './timetable.utils';

function getShopClosedText(
  shop: Shop,
  translateFn: GetTextByKeyType,
  timeslot?: Timeslot,
  lastUserTimeslot?: Timeslot
): string {
  if (shop.chainView) return;
  switch (shop.operatingState) {
    case 'CLOSED':
      return 'closed_';
    case 'OUTSIDE_TIMETABLE':
      return getShopCardFutureOpenText(shop, translateFn, timeslot, lastUserTimeslot);
    case 'OFF_HOURS':
      return 'outside_working_hours';
    case 'DAAS_AREA_UNAVAILABLE':
      return 'delivery_suspended_temporarily';
    case 'OUT_OF_ORDER':
      return 'not_accepting_orders_currently';
  }
}

/**
 * Returns the shop operating state
 * @param {Shop} shop
 * @param {Timeslot} timeslot
 * @returns {ShopOperatingStateType}
 */
function getShopOperatingState(shop: Shop, timeslot: Timeslot): ShopOperatingStateType {
  // Handle unavailable shop
  if (!shop.available) return getUnavailableShopOperatingState(shop, timeslot);

  // Handle available shop but not connected
  if (!shop.merchantConnected) return isShopInWorkingHours(shop, timeslot) ? 'OUT_OF_ORDER' : 'CLOSED';

  // Handle available and connected shop
  if (!isShopInWorkingHours(shop, timeslot)) return getClosedShopOperatingState(shop, timeslot);

  if (shop?.daas && !shop?.isDaasAreaAvailable) return 'DAAS_AREA_UNAVAILABLE';

  return 'OPEN';
}

/**
 * Returns the shop operating state for unavailable shops.
 * We do not filter out shops after fetching them when revertSwitchBackOn
 * does not exist or it is in the past so we have to check it and return CLOSED state.
 * @param {Shop} shop
 * @param {Timeslot} timeslot
 * @returns {ShopOperatingStateType}
 */
function getUnavailableShopOperatingState(shop: Shop, timeslot: Timeslot): ShopOperatingStateType {
  if (!shopRevertSwitchBackOnIsOnTheFuture(shop)) return 'CLOSED';
  const nextShopAvailableTimetable = getNextWorkingHours(shop, timeslot)?.nextFutureWorkingHours;
  if (nextShopAvailableTimetable && !isSMWithTimeslots(shop)) return 'OUTSIDE_TIMETABLE';
  return 'CLOSED';
}

function getClosedShopOperatingState(shop: Shop, timeslot: Timeslot): ShopOperatingStateType {
  const nextShopAvailableTimetable = getNextWorkingHours(shop, timeslot)?.nextFutureWorkingHours;
  return nextShopAvailableTimetable ? 'OUTSIDE_TIMETABLE' : 'OFF_HOURS';
}

/*
  We are skipping the check for the timetable if the shop is a Super Market with Timeslots
  that covers the case of instant SMs that have ASAP delivery but no scheduled order. The SMs
  that have Timeslots, are handled like a 24h shop
*/
function isSMWithTimeslots(shop: Shop): boolean {
  const isSMWithTimeslots = shop.isSuperMarket && shop.hasTimeSlots;
  return isSMWithTimeslots;
}

/**
 * This function sorts the shops provided based on shop operatingState.
 * @param {Shop[]} shops
 * @returns {Shop[]}
 */
function sortShopsBasedOnOperatingState(shops: Shop[]): Shop[] {
  const openShops = shops.filter((s) => s.operatingState === 'OPEN');
  const daasAreaUnavailableShops = shops.filter((s) => s.operatingState === 'DAAS_AREA_UNAVAILABLE');
  const outShops = shops.filter((s) => s.operatingState === 'OUTSIDE_TIMETABLE');
  const outOfOrdersShops = shops.filter((s) => s.operatingState === 'OUT_OF_ORDER');
  const offHoursShops = shops.filter((s) => s.operatingState === 'OFF_HOURS');
  const closedShops = shops.filter((s) => s.operatingState === 'CLOSED');
  return [
    ...openShops,
    ...daasAreaUnavailableShops,
    ...outShops,
    ...outOfOrdersShops,
    ...offHoursShops,
    ...closedShops
  ];
}

/**
 * This function sorts the Discover Search Items provided based on search results shop operatingState.
 * @param {DiscoverSearchItems<>[]} searchResults
 * @returns {DiscoverSearchItems[]}
 */
function sortSearchResultsByOperatingState<T>(searchResults: DiscoverSearchItems<T>[]): DiscoverSearchItems<T>[] {
  const openShopResults = searchResults.filter((r) => r.shop.operatingState === 'OPEN');
  const daasAreaUnavailableShopResults = searchResults.filter((r) => r.shop.operatingState === 'DAAS_AREA_UNAVAILABLE');
  const outShopResults = searchResults.filter((r) => r.shop.operatingState === 'OUTSIDE_TIMETABLE');
  const outOfOrdersShopResults = searchResults.filter((r) => r.shop.operatingState === 'OUT_OF_ORDER');
  const offHoursShopResults = searchResults.filter((r) => r.shop.operatingState === 'OFF_HOURS');
  const closedShopResults = searchResults.filter((r) => r.shop.operatingState === 'CLOSED');
  return [
    ...openShopResults,
    ...daasAreaUnavailableShopResults,
    ...outShopResults,
    ...outOfOrdersShopResults,
    ...offHoursShopResults,
    ...closedShopResults
  ];
}

export {
  getShopClosedText,
  getShopOperatingState,
  sortShopsBasedOnOperatingState,
  sortSearchResultsByOperatingState,
  isSMWithTimeslots,
  getClosedShopOperatingState,
  getUnavailableShopOperatingState
};
