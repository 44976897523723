<section class="discover-search-page-section discover-search-page-navigation">
  <div class="container">
    <ng-container *ngIf="loading; else normalNavigation">
      <div class="discover-search-page-navigation--skeleton"></div>
    </ng-container>
    <ng-template #normalNavigation>
      <discover-search-navigation></discover-search-navigation>
    </ng-template>
  </div>
</section>
<section class="discover-search-page-section">
  <div class="container">
    <ng-container [ngSwitch]="selectedSubCategory">
      <ng-container *ngSwitchCase="'shops'">
        <discover-shops></discover-shops>
      </ng-container>
      <div *ngSwitchCase="'products'" class="discover-search-page-items">
        <ng-container *ngIf="loading; else normalProducts">
          <header class="discover-search-page-items-header">
            <div class="discover-search-page-items-title discover-search-page-items-title--skeleton"></div>
          </header>
          <div class="discover-search-page-items-sorting-info discover-search-page-items-sorting-info--skeleton"></div>
          <div class="discover-search-page-items-container">
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
          </div>
        </ng-container>
        <ng-template #normalProducts>
          <header class="discover-search-page-items-header">
            <h3 class="discover-search-page-items-title">
              {{ 'found_x_items' | translate:{_ITEMS_COUNT:searchResults.productsCount.toString()} }}
            </h3>
          </header>
          <discover-sorting-info
            *ngIf="searchResults?.productResults?.length"
            sortingKey="search"
            class="discover-search-page-items-sorting-info"
          ></discover-sorting-info>
          <div *ngFor="let productResult of searchResults.productResults" class="discover-search-page-items-container">
            <discover-search-result-items
              type="product"
              [result]="productResult"
              [options]="searchItemsOptions"
              (itemSelect)="onItemSelect(productResult.shop, $event)"
            ></discover-search-result-items>
          </div>
        </ng-template>
      </div>

      <div *ngSwitchCase="'offers'" class="discover-search-page-items">
        <ng-container *ngIf="loading; else normalOffers">
          <header class="discover-search-page-items-header">
            <div class="discover-search-page-items-title discover-search-page-items-title--skeleton"></div>
          </header>
          <div class="discover-search-page-items-sorting-info discover-search-page-items-sorting-info--skeleton"></div>
          <div class="discover-search-page-items-container">
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
            <skeleton-shop-card-with-items></skeleton-shop-card-with-items>
          </div>
        </ng-container>
        <ng-template #normalOffers></ng-template>
        <header class="discover-search-page-items-header">
          <h3 class="discover-search-page-items-title">
            {{ 'found_x_offers' | translate:{_OFFERS_COUNT:searchResults.offersCount.toString()} }}
          </h3>
        </header>
        <discover-sorting-info
          *ngIf="searchResults?.offerResults?.length"
          sortingKey="search"
          class="discover-search-page-items-sorting-info"
        ></discover-sorting-info>
        <div *ngFor="let offerResult of searchResults.offerResults" class="discover-search-page-items-container">
          <discover-search-result-items
            type="offer"
            [result]="offerResult"
            [options]="searchItemsOptions"
            (itemSelect)="onItemSelect(offerResult.shop, $event)"
          ></discover-search-result-items>
        </div>
      </div>
    </ng-container>
  </div>
</section>
