<div class="discover-shops-wrapper">
  <ng-container *ngIf="loading; else normalHeader">
    <div class="discover-shops-header discover-shops-header--skeleton"></div>
  </ng-container>
  <ng-template #normalHeader>
    <header class="discover-shops-header">
      <h3 class="discover-shops-title">
        {{
          'found_x_shops' | translate: { _SHOPS_COUNT: (discoverShopsWithItems?.length || shops?.length)?.toString() }
        }}
      </h3>
      <button
        box-button
        size="small"
        theme="insignificant"
        class="discover-shops-filter-button"
        (click)="onOpenFilters()"
      >
        <div class="discover-shops-filter-button-content">
          <svg class="discover-shops-filter-button-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 14">
            <path
              fill="#F87A1D"
              fill-rule="nonzero"
              d="M1.115 2.864h2.462c0 .651.552 1.181 1.23 1.181h1.231c.68 0 1.231-.53 1.231-1.181h8.616c.34 0 .615-.264.615-.591a.603.603 0 0 0-.615-.591H7.269C7.27 1.03 6.717.5 6.04.5H4.807c-.68 0-1.231.53-1.231 1.182H1.115a.603.603 0 0 0-.615.59c0 .328.276.592.615.592Zm14.77 3.545h-3.693c0-.652-.552-1.182-1.23-1.182H9.73c-.679 0-1.231.53-1.231 1.182H1.115A.603.603 0 0 0 .5 7c0 .327.276.59.615.59H8.5c0 .653.552 1.183 1.23 1.183h1.232c.678 0 1.23-.53 1.23-1.182h3.693c.34 0 .615-.264.615-.591a.603.603 0 0 0-.615-.59Zm0 4.727H7.269c0-.651-.552-1.181-1.23-1.181H4.807c-.68 0-1.231.53-1.231 1.181H1.115a.603.603 0 0 0-.615.591c0 .327.276.591.615.591h2.462c0 .652.552 1.182 1.23 1.182h1.231c.68 0 1.231-.53 1.231-1.182h8.616c.34 0 .615-.264.615-.59a.603.603 0 0 0-.615-.592Z"
            />
          </svg>
          <span>{{ 'filters_'| translate }}</span>
        </div>
      </button>
    </header>
  </ng-template>

  <ng-container *ngIf="loading; else normalInfo">
    <div class="discover-shops-sorting-info discover-shops-sorting-info--skeleton"></div>
  </ng-container>
  <ng-template #normalInfo>
    <discover-sorting-info
      *ngIf="haveDishesToShow || shops?.length"
      class="discover-shops-sorting-info"
    ></discover-sorting-info>
  </ng-template>

  <ng-container *ngIf="loading; else normalActiveFilters">
    <div class="discover-active-filters discover-active-filters--skeleton"></div>
  </ng-container>
  <ng-template #normalActiveFilters>
    <discover-active-filters></discover-active-filters>
  </ng-template>

  <ng-container *ngIf="loading; else normalShops">
    <div class="discover-shops-container">
      <div class="discover-shop-tile-container" *ngFor="let shop of [1, 2, 3, 4, 5, 6, 7]">
        <skeleton-shop-tile-compact class="discover-shop-card"></skeleton-shop-tile-compact>
      </div>
    </div>
  </ng-container>
  <ng-template #normalShops>
    <ng-container *ngIf="haveDishesToShow; else shopsOnly">
      <div *ngFor="let searchShop of discoverShopsWithItems" class="discover-shops-items-container">
        <discover-shop-result-items
          [searchShop]="searchShop"
          [disableAnimation]="true"
          (itemSelect)="onItemSelect(searchShop, $event)"
        ></discover-shop-result-items>
      </div>
    </ng-container>
    <ng-template #shopsOnly>
      <div class="discover-shops-container">
        <div class="discover-shop-tile-container" *ngFor="let shop of lazyShops; trackBy: trackById">
          <shop-tile-compact
            class="discover-shop-card hoverable"
            visibilityObserver
            [triggerOnce]="true"
            [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
            (enteredViewport)="onShopEnteredViewport(shop)"
            [shop]="shop"
            (click)="onShopCardClick(shop)"
          ></shop-tile-compact>
        </div>
      </div>
      <progressive-rendering-trigger
        *ngIf="shops"
        [items]="shops"
        (triggerEvent)="onTriggerEnterView($event)"
      ></progressive-rendering-trigger>
    </ng-template>
  </ng-template>
</div>
