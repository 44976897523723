import { Component, HostBinding, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DiscoverBrandsService } from '@box-discover/services';
import { Brand, CollapsibleTile } from '@box-types';
import { brandToCollapsibleTile, DiscoverTilesOptions } from '@box/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'discover-brands',
  templateUrl: './discover-brands.component.html',
  styleUrls: ['./discover-brands.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverBrandsComponent implements OnInit, OnDestroy {
  public tiles: CollapsibleTile<Brand>[];
  public discoverTilesOptions: DiscoverTilesOptions;

  constructor(private discoverBrandsService: DiscoverBrandsService, private changeDetectorRef: ChangeDetectorRef) {}

  private discoverBrandsSubscription: Subscription;

  @HostBinding('class') public hostClass = 'discover-brands';

  ngOnInit(): void {
    this.setDiscoverBrandsSubscription();
    const activeTileKey = this.discoverBrandsService.getCheckedBrand()?.name;
    this.discoverTilesOptions = { title: 'with_brew', activeTileKey, size: 'medium' };
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.discoverBrandsSubscription?.unsubscribe();
  }

  private setDiscoverBrandsSubscription(): void {
    this.discoverBrandsSubscription = this.discoverBrandsService.filteredBrands$.subscribe((brands) => {
      this.tiles = brands.map((brand) => brandToCollapsibleTile(brand));
      this.changeDetectorRef.detectChanges();
    });
  }

  public onTileToggle(tile: CollapsibleTile<Brand>): void {
    const activeBrand = tile?.item;
    this.discoverBrandsService.setCheckedBrand(activeBrand);
    this.changeDetectorRef.detectChanges();
  }
}
