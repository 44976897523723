import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import {
  Location,
  Shop,
  APIResponse,
  NotificationAlert,
  SeoOptions,
  APIError,
  GetShopsByLocationResponse,
  BoxNavigationOptions,
  BoxNavigationTabOptions
} from '@box-types';
import {
  normalizeLocation,
  filterValidLocations,
  setRedirectPrerenderMetaElements,
  normalizeShop,
  sortShops,
  ObservableCacheDecorator,
  hasShopAtLeastOneDayOpen
} from '@box/utils';
import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  translate as t,
  SEOService,
  SentryService,
  ConfigurationService,
  TimeslotsService,
  ShopService
} from '@box-core/services';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { UrgentConditionNotificationService } from '@box-core/services/urgent-condition-notification.service';
import { DiscoverBusinessVerticalService } from '@box-discover/services';

const SHOPS_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 minutes

@Injectable({ providedIn: 'root' })
export class LocationsService {
  private BOX_API: string = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private router: Router,
    private seoService: SEOService,
    private sentryService: SentryService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigurationService,
    private timeslotsService: TimeslotsService,
    private shopService: ShopService,
    private globalStateService: GlobalStateService,
    private urgentConditionNotificationService: UrgentConditionNotificationService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService
  ) {}

  @ObservableCacheDecorator<Location, string>({
    functionKeyArgumentIndexes: [0]
  })
  public fetchLocation(locationKey: string): Observable<Location> {
    const params: HttpParams = new HttpParams().set('key', locationKey);
    return this.http
      .get(`${this.BOX_API}/location/getOne`, { params })
      .pipe(map((response: APIResponse<{ location: Location }>) => response.payload.location));
  }

  public fetchLocations(): Observable<Location[]> {
    return this.http.get(`${this.BOX_API}/location/getAll`).pipe(
      map((response: APIResponse<{ locations: Location[] }>) => {
        const locations = response.payload.locations;
        const filteredLocations = filterValidLocations(locations) as Location[];
        return filteredLocations.map((location) => normalizeLocation(location));
      })
    );
  }

  public fetchLocationsByPostalCode(postalCode: string): Observable<Location[]> {
    const params = new HttpParams().set('postalCode', postalCode);
    return this.http.get(`${this.BOX_API}/location/findByPostalCode`, { params }).pipe(
      map((response: APIResponse<{ locations: Location[] }>) => {
        const locations = response.payload.locations;
        const filteredLocations = filterValidLocations(locations) as Location[];
        return filteredLocations.map((location) => normalizeLocation(location));
      })
    );
  }

  @ObservableCacheDecorator<Shop[], string>({
    expirationTimeInMs: SHOPS_SESSION_EXPIRATION,
    functionKeyArgumentIndexes: [0]
  })
  public fetchShopsByLocationKey(locationKey: string): Observable<GetShopsByLocationResponse> {
    const params: HttpParams = new HttpParams().set('key', locationKey);
    return this.http.get(`${this.BOX_API}/shops/getShopsByLocation`, { params }).pipe(
      map((response: APIResponse<GetShopsByLocationResponse>) => {
        const shops = response?.payload?.shops;
        const location = response?.payload?.location;
        const notificationAlert = response?.payload?.notificationAlert;
        const normalizedShops = shops.map((shop) => normalizeShop(shop));
        const filteredShops = normalizedShops.filter((shop: Shop) => shop.logo && hasShopAtLeastOneDayOpen(shop));
        return { shops: filteredShops, location, notificationAlert };
      })
    );
  }

  public fetchShopsByCuisineAndLocation(locationKey: string, cuisineKey: string): Observable<Shop[]> {
    const params: HttpParams = new HttpParams().set('location', locationKey).set('cuisine', cuisineKey);
    return this.http
      .get(`${this.BOX_API}/shops/findShopsByCuisineAndLocation`, { params })
      .pipe(
        map((response: APIResponse<{ shops: Shop[]; notificationAlert: NotificationAlert }>) => response.payload.shops)
      );
  }

  public setLocationPageMetaTags(location: Location): void {
    const replacementDictionary = { _NAME: location?.name };
    const options: SeoOptions = {
      title: t('seo_text_4', replacementDictionary),
      description: t('seo_text_3', replacementDictionary),
      keywords: t('seo_text_2', replacementDictionary),
      url: this.router.url
    };
    this.seoService.setTags(options);
  }

  public handleLocationPageError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Location Page',
      severity: 'warning'
    });

    setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
    return void this.router.navigate(['/delivery']);
  }

  public handleEmptyStatePage(): void {
    setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
    return void this.router.navigate(['/delivery']);
  }

  public getShopsByLocationKey$(locationKey: string): Observable<Shop[]> {
    if (!locationKey?.length) return of([]);

    return forkJoin({
      shopsResponse: this.fetchShopsByLocationKey(locationKey),
      location: this.fetchLocation(locationKey)
    }).pipe(
      tap(({ shopsResponse, location }) => {
        this.urgentConditionNotificationService.setHomePageNotificationAlert(shopsResponse.notificationAlert);
        this.globalStateService.setLocation(location);
      }),
      map(({ shopsResponse }) => {
        const decoratedShops = shopsResponse.shops.map((shop) => this.shopService.shopPromoDecorator(shop));
        const timeslot = this.timeslotsService.getTimeslot();
        return sortShops(decoratedShops, timeslot);
      }),
      tap((shops: Shop[]) => {
        this.globalStateService.setShops(shops);
      }),
      catchError(() => of([]))
    );
  }

  public isSeoLocationPage(): boolean {
    return this.router.url.includes('/delivery/');
  }

  // todo dimitris test
  public getBoxNavigationOptions(locationKey?: string): BoxNavigationOptions {
    const homeTab: BoxNavigationTabOptions = {
      action: () => {
        const link = locationKey ? '/delivery/' + locationKey : '/home';
        this.router.navigate([link]);
      },
      title: 'recommendations_',
      svgPath: '/assets/images/navigation/home_navigation.svg',
      creativeSlot: this.isSeoLocationPage ? 'discover_landing' : 'discover_home',
      urlMatcher: (url) => {
        if (locationKey) return url === '/delivery/' + locationKey;
        return url.includes('/home');
      }
    };
    const footTab: BoxNavigationTabOptions = {
      action: () => {
        this.discoverBusinessVerticalService.setBusinessVertical('food');
        const link = locationKey ? '/delivery/' + locationKey + '/estiatoria' : '/discover';
        this.router.navigate([link]);
      },
      title: 'restaurants_',
      svgPath: '/assets/images/navigation/restaurants_navigation.svg',
      creativeSlot: this.isSeoLocationPage ? 'discover_estiatoria' : 'discover_food',
      urlMatcher: (url) => {
        if (homeTab.urlMatcher(url)) return false;
        return this.discoverBusinessVerticalService.getBusinessVertical() === 'food';
      }
    };
    const groceriesTab: BoxNavigationTabOptions = {
      action: () => {
        this.discoverBusinessVerticalService.setBusinessVertical('groceries');
        const link = locationKey ? '/delivery/' + locationKey + '/psonia' : '/discover';
        this.router.navigate([link]);
      },
      title: 'shopping_',
      svgPath: '/assets/images/navigation/groceries_navigation.svg',
      creativeSlot: this.isSeoLocationPage ? 'discover_psonia' : 'discover_grocery',
      urlMatcher: (url) => {
        if (homeTab.urlMatcher(url)) return false;
        return this.discoverBusinessVerticalService.getBusinessVertical() === 'groceries';
      }
    };

    return {
      navOptions: { separators: false },
      tabs: [homeTab, footTab, groceriesTab]
    };
  }
}
