import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'skeleton-discover-filters',
  templateUrl: './skeleton-discover-filters.component.html',
  styleUrls: ['./skeleton-discover-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonDiscoverFiltersComponent {
  @HostBinding('class') public hostClass = 'skeleton-discover-filters';
}
