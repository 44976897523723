import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  HostBinding
} from '@angular/core';
import { Subscription, combineLatest, tap } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

import {
  ShopService,
  DialogService,
  DeliveryMethodService,
  CartService,
  AnalyticsService,
  CampaignEligibilityService,
  CurrencyService
} from '@box-core/services';
import {
  Product,
  CartGroupChange,
  ProductInstance,
  BOXEmptyStateOptions,
  Shop,
  DeliveryFee,
  Offer,
  OfferInstance,
  DeliveryMethod,
  GetTextByKeyType,
  Cart
} from '@box-types';
import { ProductMYODialogComponent, OfferWizardDialogComponent } from '@box-delivery/components';
import orderBy from 'lodash-es/orderBy';
import {
  getShopDeliveryFee,
  cartHasInactiveOffers,
  cartOverridesOrderMinimumPrice,
  cartSupportsVolume,
  decorateOfferWithInactiveText,
  getCartInactiveOffers,
  getCartInactiveOffersMessage,
  itemsPriceIsOverMinimum,
  getFreeDeliveryFeeThreshold,
  getCorrespondingDeliveryFeeTier,
  getNextDeliveryFeeTier,
  getShopMinimumPrice,
  generateImageSrc,
  CurrencyFormatOptions,
  currencyFormat,
  getCartProductGAConfig,
  getCartOfferGAConfig,
  getMultiplierSum,
  getPointsSum
} from '@box/utils';
import { CartButtonComponent } from '@box-cart-widget/components';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';
import { LanguageService } from '@box-core/services/language.service';
import { currencyCode } from '@box-core/services/currency.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CartProductService, CartOfferService } from '@box-core/services/cart-item.service';

const OFFER_REMOVAL_MESSAGES = ['this_action_will_remove_the_offer_from_the_cart'];
const PRODUCT_REMOVAL_MESSAGES = ['this_action_will_remove_the_product_from_the_cart'];

const FOOD_EMPTY_STATE_OPTIONS = {
  iconURL: '/assets/images/empty-state/cart-empty-state-food.svg',
  title: 'your_cart_is_empty',
  description: 'start_adding_products_and_earn_points'
};

const GROCERIES_EMPTY_STATE_OPTIONS = {
  iconURL: '/assets/images/empty-state/cart-empty-state-groceries.svg',
  title: 'your_cart_is_empty',
  description: 'start_adding_products_and_earn_points'
};

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('cartHeader') private cartHeader: ElementRef<HTMLElement>;
  @ViewChild('cartFooter') private cartFooter: ElementRef<HTMLElement>;
  @ViewChild('cartButtonComponent') private cartButtonComponent: CartButtonComponent;

  // todo refactor
  @Input() public disabled = false;
  @Output() private cartSubmit = new EventEmitter<void>();

  public minimumPrice = 0;
  public supportsVolume: boolean;
  public cartVolume: number;
  public maxCartVolume: number;
  public businessVertical: string;
  public disabledCartButton: boolean;
  public remainingPriceText: string;
  public offers: Offer[] = [];
  public products: Product[] = [];
  public showPriceBreakDown: boolean;
  public serviceFee: number;
  public cartTotalQuantity: number;
  public multiplierSum: number;
  public pointsSum: number;

  public formattedItemsPriceWithoutEnvFee: string;
  public formattedServiceFee: string;
  public formattedEnvFee: string;
  public formattedDiscount: string;
  public totalPrice: number;
  public formattedTotal: string;

  public shopLogo: string;
  public minimumPriceCase: 'override' | 'minimum';
  public isEmpty: boolean;
  public cartEmptyStateOptions: BOXEmptyStateOptions;
  public isSuperMarket: boolean;
  public freeDelFeeText: string;
  public freeDelFeeClass: string;
  public showProductsCommentPlaceholder: boolean;

  private cart: Cart;
  private shop: Shop;
  private priceIsOverMinimum: boolean;
  private hasOverriddenMinimumPrice: boolean;
  private cartSubscription: Subscription;

  public readonly t: GetTextByKeyType; // for the template to use

  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private dialogService: DialogService,
    private campaignEligibilityService: CampaignEligibilityService,
    private renderer: Renderer2,
    private shopService: ShopService,
    private cartService: CartService,
    private cartProductService: CartProductService,
    private cartOfferService: CartOfferService,
    private analyticsService: AnalyticsService,
    private languageService: LanguageService,
    private currencyService: CurrencyService
  ) {
    this.t = this.languageService.getTextByKey.bind(this.languageService);
  }

  @HostBinding('class') public hostClass = 'cart';

  ngOnInit(): void {
    this.setCartSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disabled = changes.disabled.currentValue as boolean;
    this.disabledCartButton = !this.userCanSubmit();
  }

  ngOnDestroy(): void {
    this.cartSubscription?.unsubscribe();
  }

  public onBodyScroll(event: BodyScrollEvent): void {
    event.scrolled
      ? this.renderer.addClass(this.cartHeader.nativeElement, 'has-shadow')
      : this.renderer.removeClass(this.cartHeader.nativeElement, 'has-shadow');
    event.scrolledToBottom
      ? this.renderer.removeClass(this.cartFooter.nativeElement, 'has-shadow')
      : this.renderer.addClass(this.cartFooter.nativeElement, 'has-shadow');
  }

  public onProductAdd(event: CartGroupChange<Product, ProductInstance>): void {
    const { item, instance } = event;
    this.cartProductService.addItem(item, { ...instance, quantity: 1 });
  }

  public onProductRemove(event: CartGroupChange<Product, ProductInstance>): void {
    const { item, instance } = event;
    const productRemoveCallBack = () => {
      const cartResponse = this.cartProductService.removeItem(item, instance);
      if (cartResponse === 'ITEM_REMOVED') {
        if (instance.quantity === 1) this.triggerAnalyticsProductEvent(item, instance);
      }
    };
    if (instance.quantity > 1) return productRemoveCallBack();
    this.cartService.showItemRemovalInfoDialog$(PRODUCT_REMOVAL_MESSAGES).subscribe((response) => {
      if (response) productRemoveCallBack();
    });
  }

  public onProductEdit(event: CartGroupChange<Product, ProductInstance>): void {
    const { item, instance } = event;
    const product = this.products.find((p) => p._id === item._id);
    this.openProductMYO(product, instance)
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.cartProductService.handleItemMyoResponse(response);
        this.shopService.addToCartProductAnalyticsEvent(response.product, response.productInstance, 'shop');
      });
  }

  public onOfferAdd(event: CartGroupChange<Offer, OfferInstance>): void {
    const { item, instance } = event;
    const addOfferCallBack = () => {
      this.cartOfferService.addItem(item, { ...instance, quantity: 1 });
    };
    if (instance.isDFY) return void this.openDFYConfimDialog(addOfferCallBack);
    return addOfferCallBack();
  }

  public onOfferRemove(event: CartGroupChange<Offer, OfferInstance>): void {
    const { item, instance } = event;
    const removeOfferCallBack = () => {
      const cartResponse = this.cartOfferService.removeItem(item, instance);
      if (cartResponse === 'ITEM_REMOVED') {
        if (instance.quantity === 1) this.triggerAnalyticsOfferEvent(item, instance);
      }
    };
    if (instance.quantity > 1) return removeOfferCallBack();
    this.cartService.showItemRemovalInfoDialog$(OFFER_REMOVAL_MESSAGES).subscribe((response) => {
      if (response) removeOfferCallBack();
    });
  }

  public onOfferEdit(event: CartGroupChange<Offer, OfferInstance>): void {
    const { item, instance } = event;
    const offer = this.offers.find((o) => o._id === item._id);
    this.openOfferWizard(offer, instance)
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.cartOfferService.handleItemMyoResponse(response);
        this.shopService.addToCartOfferAnalyticsEvent(response.product, response.productInstance, 'shop');
      });
  }

  public onCartSubmit(): void {
    const inactiveOffers = getCartInactiveOffers(this.cart);
    if (!inactiveOffers.length) return this.cartSubmit.emit();
    this.handleInactiveOffersDialog(inactiveOffers);
  }

  private setDeliveryFeeDetails(itemsFinalPrice: number): void {
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const shop = this.shopService.getShop();
    this.serviceFee = getShopDeliveryFee(shop, deliveryMethod, itemsFinalPrice) ?? 0;
    this.minimumPrice = getShopMinimumPrice(shop, deliveryMethod) ?? 0;
    this.freeDelFeeText = this.getDeliveryFeeMessage(shop.deliveryFees, itemsFinalPrice, deliveryMethod);
    this.freeDelFeeClass = this.getDeliveryFeeClass(shop.deliveryFees, itemsFinalPrice, deliveryMethod);
  }

  private getEmptyStateOptions(shop: Shop): BOXEmptyStateOptions {
    if (shop.businessVertical === 'food') return FOOD_EMPTY_STATE_OPTIONS;
    if (shop.businessVertical === 'groceries') return GROCERIES_EMPTY_STATE_OPTIONS;
  }

  private getMinimumPriceMessage(): 'override' | 'minimum' {
    if (!this.cartTotalQuantity) return;
    if (this.hasOverriddenMinimumPrice && !this.priceIsOverMinimum) return 'override';
    if (!this.hasOverriddenMinimumPrice && !this.priceIsOverMinimum) return 'minimum';
  }

  private userCanSubmit(): boolean {
    const passedMinimumPrice = this.hasOverriddenMinimumPrice || this.priceIsOverMinimum;
    const cartHasProduct = this.cartTotalQuantity > 0;
    return !this.disabled && passedMinimumPrice && cartHasProduct;
  }

  private setCartSubscription(): void {
    this.cartSubscription = combineLatest({
      shop: this.shopService.shop.pipe(tap((shop) => this.setShopData(shop))),
      cart: this.cartService.cart$.pipe(tap(() => this.setBenefitsData())),
      deliveryMethod: this.deliveryMethodService.deliveryMethod
    }).subscribe(({ shop, cart, deliveryMethod }) => {
      this.setCartData(cart);
      const cartPriceWithPureDiscountsAndEnvFees = cart.itemsFinalPrice;
      if (!this.isSuperMarket) this.hasOverriddenMinimumPrice = cartOverridesOrderMinimumPrice(cart);
      this.priceIsOverMinimum = itemsPriceIsOverMinimum(shop, deliveryMethod, cartPriceWithPureDiscountsAndEnvFees);
      if (!this.priceIsOverMinimum) this.calculateRemainingPrice();
      this.minimumPriceCase = this.getMinimumPriceMessage();
      this.disabledCartButton = !this.userCanSubmit();
      this.showProductsCommentPlaceholder = this.shop.isProductCommentsAllowed;
    });
  }

  private setShopData(shop: Shop): void {
    this.shop = shop;
    this.isSuperMarket = shop.isSuperMarket;
    this.businessVertical = shop.businessVertical;
    this.shopLogo = generateImageSrc(shop.logo);
    this.cartEmptyStateOptions = this.getEmptyStateOptions(shop);
  }

  private setBenefitsData(): void {
    const campaigns = this.campaignEligibilityService.getConsumedPromoCampaigns();
    this.multiplierSum = getMultiplierSum(campaigns);
    this.pointsSum = getPointsSum(campaigns);
  }

  private setCartData(cart: Cart): void {
    this.cart = cart;
    const products = cart.products;
    const offers = cart.offers;
    const itemsQuantity = cart.itemsQuantity;
    const isSuperMarket = this.shop.isSuperMarket;
    const itemsStartingPrice = cart.itemsStartingPrice;
    const itemsFinalPrice = cart.itemsFinalPrice;
    const itemsDiscount = this.isSuperMarket ? cart.itemsDiscount : 0;
    const envFee = cart.envFee;
    /* We are using the isSuperMarket here due to a tech flaw we have with the Discount Calculation. We
    assume that the non SM Shops have no pure Discount. That is a subject to change. */
    this.offers = offers.map((offer) => decorateOfferWithInactiveText(offer, cart, this.t, currencyCode));
    this.products = orderBy(products, 'category.categoryIndex', 'asc');
    /* We do not use the isCartEmpty function from cart utilities here due to the fact
    that you can have inactive offers that do not meet the min order items price
    requirement, but you wanna show them. */
    this.isEmpty = !cartHasInactiveOffers(cart) && itemsQuantity === 0;
    this.setDeliveryFeeDetails(itemsFinalPrice);
    const previousQuantity = this.cartTotalQuantity;
    this.cartTotalQuantity = itemsQuantity;
    if (itemsQuantity > previousQuantity) this.cartButtonComponent?.twist();
    this.supportsVolume = cartSupportsVolume(cart);
    this.maxCartVolume = cart.maxVolume;
    this.cartVolume = cart.volume;
    const currencyFormatOptions = {
      minimumFractionDigits: 2,
      symbolSpace: false,
      currencyCode: currencyCode
    } as CurrencyFormatOptions;
    /** We are using the itemsFinalPrice instead of the beginPrice on food shops,
     * due to a tech design flaw calculating the starting price. This needs to be
     * taken in consideration when we refactor the cart */
    const itemsPrice = isSuperMarket ? itemsStartingPrice : itemsFinalPrice;
    const itemsPriceWithoutEnvFee = itemsPrice - envFee;
    this.formattedItemsPriceWithoutEnvFee = currencyFormat(itemsPriceWithoutEnvFee, currencyFormatOptions);
    this.formattedEnvFee = envFee ? currencyFormat(envFee, currencyFormatOptions) : null;
    this.formattedServiceFee = this.serviceFee ? currencyFormat(this.serviceFee, currencyFormatOptions) : null;
    this.formattedDiscount = itemsDiscount ? currencyFormat(itemsDiscount, currencyFormatOptions) : null;
    this.totalPrice = itemsFinalPrice + this.serviceFee;
    this.formattedTotal = currencyFormat(this.totalPrice, currencyFormatOptions);
    this.showPriceBreakDown = Boolean(itemsDiscount || this.serviceFee || envFee);
  }

  private calculateRemainingPrice(): void {
    // total price includes delivery fee
    const itemsPriceWithoutDeliveryFee = this.totalPrice - this.serviceFee;
    const remainingPrice: number = Math.max(this.minimumPrice - itemsPriceWithoutDeliveryFee, 0);
    const currencyCode = this.currencyService.getCurrencyCode();
    const priceToText = currencyFormat(remainingPrice, { currencyCode, minimumFractionDigits: 2, symbolSpace: false });
    if (remainingPrice >= 100 && remainingPrice < 200) {
      this.remainingPriceText = this.languageService
        .getTextByKey('fall_short_one_for_min_delivery_price')
        .replace('_PRICE_TEXT', priceToText);
    } else {
      this.remainingPriceText = this.languageService
        .getTextByKey('fall_short_many_for_min_delivery_price')
        .replace('_PRICE_TEXT', priceToText);
    }
  }

  private openProductMYO(product: Product, instance: ProductInstance): MatDialogRef<ProductMYODialogComponent> {
    return this.dialogService.openDialog(ProductMYODialogComponent, {
      panelClass: 'box-dialog',
      data: {
        editMode: true,
        product,
        productInstance: cloneDeep(instance),
        productInstanceToEdit: instance,
        shop: this.shop
      }
    });
  }

  private openOfferWizard(offer: Offer, instance: OfferInstance): MatDialogRef<OfferWizardDialogComponent> {
    return this.dialogService.openDialog(OfferWizardDialogComponent, {
      panelClass: 'box-dialog',
      data: {
        editMode: true,
        offer,
        offerInstance: cloneDeep(instance),
        offerInstanceToEdit: instance,
        shop: this.shop
      }
    });
  }

  private openDFYConfimDialog(callBack: () => void): void {
    this.dialogService
      .openConfirmDialog({
        messages: ['you_can_only_add_one_dfy_offer_per_order'],
        confirmText: 'confirm_',
        cancelText: 'cancel_'
      })
      .afterClosed()
      .subscribe((data: { accepted: boolean }) => {
        if (data?.accepted) callBack();
      });
  }

  private handleInactiveOffersDialog(inactiveOffers: Offer[]): void {
    const inactiveOffersMessage = getCartInactiveOffersMessage(inactiveOffers, this.t);
    const config = {
      title: 'you_didnt_cover_the_min_order',
      messages: [inactiveOffersMessage],
      confirmText: 'confirm_',
      cancelText: 'cancel_'
    };
    const dialogRef = this.dialogService.openConfirmDialog(config);

    dialogRef.afterClosed().subscribe((response) => {
      if (!response?.accepted) return;
      this.cartService.removeInactiveOffers();
      this.cartSubmit.emit();
    });
  }

  private getDeliveryFeeClass(fees: DeliveryFee[], itemsPrice: number, method: DeliveryMethod): string {
    // the case where minimum allowed delivery is not fulfilled is handled elsewhere.
    if (method === 'takeAway' || !itemsPrice || !fees?.length) return '';
    const freeDeliveryFeeThreshold = getFreeDeliveryFeeThreshold(fees);
    if (!freeDeliveryFeeThreshold) return 'del-fee-limit-pending';
    if (itemsPrice < freeDeliveryFeeThreshold) return 'del-fee-limit-pending';
    return 'del-fee-limit-free-delivery';
  }

  private getDeliveryFeeMessage(fees: DeliveryFee[], itemsPrice: number, method: DeliveryMethod): string {
    if (method === 'takeAway' || !itemsPrice || !fees?.length) return;
    const deliveryFeeTier = getCorrespondingDeliveryFeeTier(fees, itemsPrice);
    if (!deliveryFeeTier) return;

    if (deliveryFeeTier.fee === 0) return 'you_have_free_delivery';
    const nextDeliveryFeeTier = getNextDeliveryFeeTier(fees, itemsPrice);
    if (!nextDeliveryFeeTier) return;

    const remainingPrice = nextDeliveryFeeTier.minCartPrice - itemsPrice;
    const remainingPriceText: string = currencyFormat(remainingPrice, {
      symbolSpace: false,
      currencyCode: currencyCode
    });

    const nextFeeTierFeeText: string = currencyFormat(nextDeliveryFeeTier.fee, {
      symbolSpace: false,
      currencyCode: currencyCode
    });
    if (remainingPrice >= 100 && remainingPrice < 200) {
      return nextDeliveryFeeTier.fee === 0
        ? this.getTranslatedDeliveryFeeMessage(
            'fall_short_one_for_free_delivery',
            remainingPriceText,
            nextFeeTierFeeText
          )
        : this.getTranslatedDeliveryFeeMessage(
            'this_action_will_remove_the_product_from_the_cart',
            remainingPriceText,
            nextFeeTierFeeText
          );
    } else {
      return nextDeliveryFeeTier.fee === 0
        ? this.getTranslatedDeliveryFeeMessage(
            'fall_short_many_for_free_delivery',
            remainingPriceText,
            nextFeeTierFeeText
          )
        : this.getTranslatedDeliveryFeeMessage('fall_short_many_for_delivery', remainingPriceText, nextFeeTierFeeText);
    }
  }

  private getTranslatedDeliveryFeeMessage(key: string, priceText: string, nextFeeTierText: string): string {
    return this.languageService
      .getTextByKey(key)
      .replace('_PRICE_TEXT', priceText)
      .replace('_NEXT_FEE_TIER_TEXT', nextFeeTierText ?? '');
  }

  private triggerAnalyticsProductEvent(product: Product, productInstance: ProductInstance): void {
    const shop = this.shopService.getShop();
    const gaConfig = getCartProductGAConfig(product, productInstance, shop, 'shop');
    this.analyticsService.addGAEcommerceEvent('remove_from_cart', gaConfig);
  }

  private triggerAnalyticsOfferEvent(offer: Offer, offerInstance: OfferInstance): void {
    const shop = this.shopService.getShop();
    const gaConfig = getCartOfferGAConfig(offer, offerInstance, shop, 'shop');
    this.analyticsService.addGAEcommerceEvent('remove_from_cart', gaConfig);
  }
}
