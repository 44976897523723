import { Shop, PromoBanner, GAEcommerceItemConfig, GAPromotionConfig } from '@box-types';

export { createCollectionShopsImpressionItems, getCollectionShopsImpressionGAConfig };

function createCollectionShopsImpressionItems(
  itemListName: string,
  shops: Shop[],
  selectedShop: Shop
): GAEcommerceItemConfig[] {
  return shops.reduce((itemsArray: GAEcommerceItemConfig[], shop, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: shop._id,
      item_name: shop.name,
      affiliation: shop.promo.firstGroupLongOptions?.text ?? '',
      affiliation2: shop.promo.secondGroupLongOptions?.text ?? '',
      index: index + 1,
      item_category: shop.mainCuisine?.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: ''
    };
    if (item.item_id === selectedShop._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionShopsImpressionGAConfig(
  promoBanner: PromoBanner,
  shops?: Shop[],
  selectedShop?: Shop,
  itemListName?: string
): GAPromotionConfig {
  if (!shops?.length) return;
  const promotionItems = createCollectionShopsImpressionItems(itemListName, shops, selectedShop);
  return {
    creative_name: promoBanner.slug,
    creative_slot: itemListName,
    promotion_id: '',
    promotion_name: promoBanner.title,
    items: promotionItems
  };
}
