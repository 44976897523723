import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  DiscoverSearchItems,
  DiscoverSearchResultItem,
  Shop,
  BusinessVertical,
  GAPromotionConfig,
  GetTextByKeyType,
  DiscoverSearchResponse
} from '@box-types';
import { MatMenuTrigger } from '@angular/material/menu';
import { AnalyticsService, DialogService } from '@box-core/services';
import { combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DiscoverPageService } from '@box-discover/pages/discover/discover-page.service';
import { map, tap } from 'rxjs/operators';
import { DiscoverShopsFiltersDialogComponent } from '@box-discover/components';
import { DiscoverShopsFiltersDialogResponse } from '../discover-shops-filters-dialog/discover-shops-filters-dialog.types';
import { DiscoverBusinessVerticalService, DiscoverShopsService, DiscoverLoaderService } from '@box-discover/services';
import { getCollectionDiscoverShopsImpressionGAConfig } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { LocationsService } from '@box-delivery/services';

@Component({
  selector: 'discover-shops',
  templateUrl: './discover-shops.component.html',
  styleUrls: ['./discover-shops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverShopsComponent implements OnInit, OnDestroy {
  @HostBinding('class') public hostClass = 'discover-shops';
  @ViewChild(MatMenuTrigger) private trigger: MatMenuTrigger;
  public discoverShopsWithItems: DiscoverSearchItems<DiscoverSearchResultItem>[] = [];
  public shops: Shop[];
  public lazyShops: Shop[];
  public haveDishesToShow: boolean;
  public businessVertical: BusinessVertical;
  public loading = false;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.9;
  public readonly t: GetTextByKeyType;

  private apiResponseSubscription: Subscription;
  private loaderSubscription: Subscription;
  private businessVerticalSubscription: Subscription;
  private discoverServiceShopsSubscription: Subscription;

  constructor(
    private router: Router,
    private discoverLoaderService: DiscoverLoaderService,
    private discoverPageService: DiscoverPageService,
    private discoverShopsService: DiscoverShopsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private analyticsService: AnalyticsService,
    private languageService: LanguageService,
    private globalStateService: GlobalStateService,
    private locationsService: LocationsService
  ) {
    this.t = this.languageService.getTextByKey.bind(this.languageService);
  }

  ngOnInit(): void {
    this.setDiscoverServiceShopsSubscription();
    this.setLoaderSubscription();
    this.setBusinessVerticalSubscription();
    this.setApiResponseSubscription();
  }

  ngOnDestroy(): void {
    this.apiResponseSubscription?.unsubscribe();
    this.loaderSubscription?.unsubscribe();
    this.businessVerticalSubscription?.unsubscribe();
    this.discoverServiceShopsSubscription?.unsubscribe();
  }

  public onOptionChange(): void {
    this.trigger.closeMenu();
  }

  public onOpenFilters(): void {
    const sortingOptions = this.discoverShopsService.getSortingOptions();
    const filteringOptions = this.discoverShopsService.getFilteringOptions();
    const dialogConfig = {
      data: { sortingOptions, filteringOptions },
      panelClass: 'box-dialog-fit-content'
    };
    this.dialogService
      .openDialog(DiscoverShopsFiltersDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((response: DiscoverShopsFiltersDialogResponse) => {
        if (!response) return;
        this.discoverShopsService.selectSortingOption(response.selectedSortingOption);
        this.discoverShopsService.selectFilteringOptions(response.selectedFilteringOptions);
      });
  }

  public onItemSelect(searchItem: DiscoverSearchItems<DiscoverSearchResultItem>, itemId: string): void {
    const vanityUrl = searchItem.shop.vanity_url;
    const locationKey = searchItem.shop.locationKey;
    const item = searchItem.items.find((item) => item.itemId === itemId);
    if (!item) return;
    const params = item?.isOffer ? { offerId: itemId } : { productId: itemId };
    if (!params) return;
    void this.router.navigate(['/delivery', locationKey, vanityUrl], { queryParams: params });
  }

  public onShopEnteredViewport(shop: Shop): void {
    this.triggerAnalyticsViewPromoEvent(shop);
  }

  public onShopCardClick(shop: Shop): void {
    this.triggerAnalyticsSelectPromoEvent(shop);
  }

  public trackById(index: number, item: Shop): string {
    return item._id;
  }

  private setDiscoverServiceShopsSubscription(): void {
    this.discoverServiceShopsSubscription = this.globalStateService.shops$.subscribe((shops) => {
      this.discoverShopsService.initializeFilteringOptions(shops);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setLoaderSubscription(): void {
    this.loaderSubscription = combineLatest([
      this.discoverLoaderService.loadingData,
      this.discoverLoaderService.loadingShops
    ])
      .pipe(map(([loadingData, loadingShops]) => loadingData || loadingShops))
      .subscribe((value: boolean) => {
        this.loading = value;
        this.changeDetectorRef.detectChanges();
      });
  }

  private setApiResponseSubscription(): void {
    this.apiResponseSubscription = combineLatest([
      this.discoverPageService.filteredSortedShops.pipe(
        tap((shops: Shop[]) => {
          this.shops = shops;
          this.lazyShops = [];
        })
      ),
      this.discoverPageService.discoverSearchResponse
    ])
      .pipe(
        map(([filteredAndSortedShops, discoverSearchResponse]: [Shop[], DiscoverSearchResponse]) => {
          if (!discoverSearchResponse?.shops?.length) {
            this.haveDishesToShow = false;
            this.discoverShopsWithItems = [];
            this.changeDetectorRef.detectChanges();
            return;
          }

          return this.discoverShopsService.generateDiscoverSearchItems(
            filteredAndSortedShops,
            discoverSearchResponse?.shops
          );
        })
      )
      .subscribe((shopsItems) => {
        if (!shopsItems) return;
        this.discoverShopsWithItems = shopsItems;
        this.haveDishesToShow = !!shopsItems?.length && shopsItems.some((item) => !!item?.items?.length);
        this.changeDetectorRef.detectChanges();
      });
  }

  public onTriggerEnterView(shops: Shop[]): void {
    this.lazyShops = shops;
    this.changeDetectorRef.detectChanges();
  }

  private setBusinessVerticalSubscription(): void {
    this.businessVerticalSubscription = this.discoverBusinessVerticalService.businessVertical.subscribe((value) => {
      this.businessVertical = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  private triggerAnalyticsViewPromoEvent(shop: Shop): void {
    const businessVerticalType = this.discoverBusinessVerticalService.getBusinessVertical();
    const isLandingPage = this.locationsService.isSeoLocationPage();
    const gaConfig = getCollectionDiscoverShopsImpressionGAConfig(
      businessVerticalType,
      this.shops,
      shop,
      isLandingPage
    );
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsSelectPromoEvent(shop: Shop): void {
    const businessVerticalType = this.discoverBusinessVerticalService.getBusinessVertical();
    const isLandingPage = this.locationsService.isSeoLocationPage();
    const gaConfig = getCollectionDiscoverShopsImpressionGAConfig(
      businessVerticalType,
      this.shops,
      shop,
      isLandingPage
    );
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    this.updatePurchaseEvent(shop, gaConfig);
  }

  private updatePurchaseEvent(shop: Shop, gaConfig: GAPromotionConfig) {
    if (!gaConfig?.items?.length) return;
    const shopIndex = gaConfig['items'][0].index;
    const purchaseEvent = {
      creativeName: gaConfig['creative_name'],
      index: shopIndex,
      itemListName: gaConfig['creative_slot'],
      creativeSlot: gaConfig['creative_slot'],
      promotionName: gaConfig['promotion_name'],
      locationName: this.locationsService.isSeoLocationPage() ? this.globalStateService.getLocation()?.name : ''
    };
    this.analyticsService.updatePurchaseEvent(shop._id, purchaseEvent);
  }
}
