import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { DiscoverCuisinesService } from '@box-discover/services/discover-cuisines.service';
import { Cuisine, CollapsibleTile } from '@box-types';
import { filterShopsByMainOrSecondaryCuisines, DiscoverTilesOptions, cuisineToCollapsibleTile } from '@box/utils';
import { DiscoverTilesComponent } from '@box-discover-widget/components';
import { DiscoverPageService } from '@box-discover/pages/discover/discover-page.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'discover-food-cuisines',
  templateUrl: './discover-food-cuisines.component.html',
  styleUrls: ['./discover-food-cuisines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverFoodCuisinesComponent implements OnInit, OnDestroy {
  @ViewChild('discoverTiles') discoverTiles: DiscoverTilesComponent<Cuisine>;

  public tiles: CollapsibleTile<Cuisine>[];
  public discoverTilesOptions: DiscoverTilesOptions;

  private filteredShopsSubscription: Subscription;

  constructor(
    private discoverCuisinesService: DiscoverCuisinesService,
    private discoverPageService: DiscoverPageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'discover-food-cuisines';

  ngOnInit(): void {
    // this is the correct execution order, race condition bug?
    this.setFilteredShopsSubscription();
    this.setDiscoverTilesOptions();
  }

  ngOnDestroy(): void {
    this.filteredShopsSubscription?.unsubscribe();
  }

  private setFilteredShopsSubscription(): void {
    const cuisines = this.discoverCuisinesService.getFoodCuisines();
    this.filteredShopsSubscription = this.discoverPageService.filteredSortedShops.subscribe((filteredShops) => {
      /* todo refactor you should not listen for filtered shops but instead listen fot the filters directly
      you are waiting for the promo-products-offers api when you don't need to */
      this.tiles = cuisines.map((c) => {
        const compatibleShops = filterShopsByMainOrSecondaryCuisines(filteredShops, [c]);
        return cuisineToCollapsibleTile(c, compatibleShops.length);
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  private setDiscoverTilesOptions(): void {
    this.discoverTilesOptions = this.discoverCuisinesService.getDiscoverCuisineTilesOptions('food');
    this.changeDetectorRef.detectChanges();
  }

  public onTileToggle(tile: CollapsibleTile<Cuisine>): void {
    const activeCuisine = tile?.item;
    this.discoverCuisinesService.setCheckedFoodCuisine(activeCuisine);
  }
}
