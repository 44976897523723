import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';
import { CartWidgetModule } from '@box-cart-widget/cart-widget.module';

import * as Components from './components';

@NgModule({
  declarations: [
    Components.MarketItemComponent,
    Components.MarketCardComponent,
    Components.MarketCardDialogComponent,
    Components.MarketCardConnectDialogComponent,
    Components.MarketCardCreateDialogComponent,
    Components.MarketCardValidationDialogComponent
  ],
  imports: [CommonModule, SharedModule, CartWidgetModule],
  exports: [
    Components.MarketItemComponent,
    Components.MarketCardComponent,
    Components.MarketCardDialogComponent,
    Components.MarketCardConnectDialogComponent,
    Components.MarketCardCreateDialogComponent,
    Components.MarketCardValidationDialogComponent
  ]
})
export class MarketWidgetModule {}
