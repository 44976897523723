<div boxNav [options]="navOptions" class="discover-search-navigation-wrapper">
  <div
    boxNavItem
    [class.box-nav-item-active]="selectedSubCategory === 'shops'"
    class="discover-search-navigation-item"
    (click)="onSubCategoryChange('shops')"
  >
    <span class="ods-typography-body-s-bold discover-search-navigation-item-text"
      >{{ 'shops_' | translate }} ({{ shopsCount }})</span
    >
  </div>
  <div
    *ngIf="!showOnlyShops"
    boxNavItem
    [class.box-nav-item-active]="selectedSubCategory === 'products'"
    class="discover-search-navigation-item"
    (click)="onSubCategoryChange('products')"
  >
    <span class="discover-search-navigation-item-text ods-typography-body-s-bold"
      >{{ 'products_' | translate }} ({{ productsCount }})</span
    >
  </div>
  <div
    *ngIf="!showOnlyShops"
    boxNavItem
    [class.box-nav-item-active]="selectedSubCategory === 'offers'"
    class="discover-search-navigation-item"
    (click)="onSubCategoryChange('offers')"
  >
    <span class="discover-search-navigation-item-text ods-typography-body-s-bold"
      >{{ 'offers_' | translate }} ({{ offersCount }})</span
    >
  </div>
</div>
