import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { DiscoverFilteringOption, DiscoverSortingOption } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { finalize, map, Subscription } from 'rxjs';
import { DiscoverShopsFiltersDialogService } from './discover-shops-filters-dialog.service';
import {
  DiscoverShopsFiltersDialogData,
  DiscoverShopsFiltersDialogResponse
} from './discover-shops-filters-dialog.types';
import { discoverSortingOptions } from '@box-configs/discover-sorting.options';
import orderBy from 'lodash-es/orderBy';

@Component({
  selector: 'discover-shops-filters-dialog',
  templateUrl: './discover-shops-filters-dialog.component.html',
  styleUrls: ['./discover-shops-filters-dialog.component.scss'],
  providers: [DiscoverShopsFiltersDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverShopsFiltersDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public sortingOptions: DiscoverSortingOption[];
  public dietaryFilteringOptions: DiscoverFilteringOption[];
  public paymentFilteringOptions: DiscoverFilteringOption[];
  public deliveryOption: DiscoverFilteringOption;

  private sortingSubscription: Subscription;
  private filteringSubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DiscoverShopsFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DiscoverShopsFiltersDialogData,
    private discoverShopsFiltersDialogService: DiscoverShopsFiltersDialogService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    const sortingOptions = this.data.sortingOptions ?? discoverSortingOptions;
    this.discoverShopsFiltersDialogService.setSortingOptions(sortingOptions);
    this.setSortingSubscription();
    const filteringOptions = this.data.filteringOptions ?? [];
    this.discoverShopsFiltersDialogService.setFilteringOptions(filteringOptions);
    this.setFilteringSubscription();
  }

  ngOnDestroy(): void {
    this.sortingSubscription?.unsubscribe();
    this.filteringSubscription?.unsubscribe();
  }

  public closeDialog(response?: DiscoverShopsFiltersDialogResponse): void {
    this.dialogRef.close(response);
  }

  public onSubmit(): void {
    const response = this.discoverShopsFiltersDialogService.getDialogResponse();
    this.closeDialog(response);
  }

  public onChangeSorting(eventItem: MatRadioChange): void {
    const sortingOption = eventItem.value as DiscoverSortingOption;
    this.discoverShopsFiltersDialogService.selectSortingOption(sortingOption);
  }

  public onChangeFiltering(eventItem: MatCheckboxChange, filteringOption: DiscoverFilteringOption): void {
    this.discoverShopsFiltersDialogService.changeFilteringOption(filteringOption, eventItem.checked);
  }

  private setSortingSubscription(): void {
    this.sortingSubscription = this.discoverShopsFiltersDialogService.sortingOptions$
      .pipe(finalize(() => this.changeDetectorRef.detectChanges()))
      .subscribe((sortingOptions) => (this.sortingOptions = sortingOptions));
  }

  private setFilteringSubscription(): void {
    this.filteringSubscription = this.discoverShopsFiltersDialogService.filteringOptions$
      .pipe(
        finalize(() => this.changeDetectorRef.detectChanges()),
        map((options) => options.filter((o) => !o.quickFilter).sort((a, b) => b.priority - a.priority))
      )
      .subscribe((filteringOptions) => {
        this.paymentFilteringOptions = orderBy(
          filteringOptions.filter((option) => option.type === 'payment'),
          'disabled',
          false
        );
        this.dietaryFilteringOptions = orderBy(
          filteringOptions.filter((option) => option.type === 'dietary'),
          'disabled',
          false
        );
        this.deliveryOption = filteringOptions.find((option) => option.type === 'delivery');
      });
  }
}
