import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'shop-description',
  templateUrl: './shop-description.component.html',
  styleUrls: ['./shop-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopDescriptionComponent implements OnChanges, AfterViewInit {
  @ViewChild('text') private textRef: ElementRef<HTMLParagraphElement>;
  @Input() public description: string;

  public collapsed: boolean;
  public buttonIcon: string;
  public showButton: boolean;

  private readonly EXPANDED_MAT_ICON = 'keyboard_arrow_up';
  private readonly COLLAPSED_MAT_ICON = 'keyboard_arrow_down';
  private readonly LINE_THRESHOLD = 2;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'shop-description';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.description) {
      this.description = changes.description.currentValue as string;
      this.checkButtonVisibility();
    }
  }

  ngAfterViewInit(): void {
    this.checkButtonVisibility();
  }

  private checkButtonVisibility(): void {
    this.expand();
    this.changeDetectorRef.detectChanges();
    if (!this.textRef) return;
    this.showButton = this.countLines() > this.LINE_THRESHOLD;
    if (this.showButton) this.collapse();
    this.changeDetectorRef.detectChanges();
  }

  private countLines(): number {
    const element = this.textRef.nativeElement;
    const divHeight = element.offsetHeight;
    const lineHeight = 16;
    return divHeight / lineHeight;
  }

  public expand(): void {
    this.collapsed = false;
    this.buttonIcon = this.EXPANDED_MAT_ICON;
  }

  public collapse(): void {
    this.collapsed = true;
    this.buttonIcon = this.COLLAPSED_MAT_ICON;
  }

  public toggle(): void {
    if (this.collapsed) return this.expand();
    this.collapse();
  }
}
