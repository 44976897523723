import { Component, Renderer2, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { CartDialogComponentData, CartDialogComponentResponse } from './cart-dialog.types';

@Component({
  selector: 'cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss']
})
export class CartDialogComponent extends BoxDialogWrapperComponent {
  public isOpenForDelivery: boolean;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<CartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CartDialogComponentData
  ) {
    super(renderer);
    this.isOpenForDelivery = this.data.isOpenForDelivery;
  }

  private closeDialog(data?: CartDialogComponentResponse): void {
    this.dialogRef.close(data);
  }

  public onCartSubmit(): void {
    this.closeDialog({ submit: true });
  }
}
