<div class="cart-wrapper">
  <header #cartHeader class="cart-section cart-header">
    <h3 class="cart-title">{{ 'my_products' | translate }}</h3>
    <market-cart-volume
      *ngIf="supportsVolume"
      class="market-cart-volume"
      [volume]="cartVolume"
      [maxVolume]="maxCartVolume"
    ></market-cart-volume>
  </header>
  <main #cartBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="cart-main">
    <box-empty-state *ngIf="isEmpty; else notEmpty" [options]="cartEmptyStateOptions"></box-empty-state>
    <ng-template #notEmpty>
      <div class="cart-groups cart-section" [ngSwitch]="businessVertical">
        <ng-container *ngFor="let offer of offers; let last = last">
          <cart-offer
            [offer]="offer"
            (add)="onOfferAdd($event)"
            (remove)="onOfferRemove($event)"
            (edit)="onOfferEdit($event)"
          ></cart-offer>
          <div *ngIf="!last || products?.length" class="box-divider"></div>
        </ng-container>
        <ng-container *ngFor="let product of products; let last = last">
          <cart-product
            [product]="product"
            [showCommentPlaceholder]="showProductsCommentPlaceholder"
            (add)="onProductAdd($event)"
            (remove)="onProductRemove($event)"
            (edit)="onProductEdit($event)"
          ></cart-product>
          <div *ngIf="!last" class="box-divider"></div>
        </ng-container>
      </div>
    </ng-template>
  </main>
  <footer #cartFooter class="cart-footer">
    <div class="cart-section" *ngIf="freeDelFeeText && !minimumPriceCase">
      <div class="del-fee-limit ods-typography-body-s-bold" [class]="freeDelFeeClass">
        {{ freeDelFeeText | translate }}
      </div>
    </div>
    <ng-container [ngSwitch]="minimumPriceCase">
      <div *ngSwitchCase="'override'" class="cart-minimum-price-override">
        <figure class="image contain-image cart-minimum-price-override-image">
          <img [src]="shopLogo" [alt]="'exclusive_offer' | translate" />
        </figure>
        <div class="cart-minimum-price-override-text">
          {{ 'the_minimum_order_value_is' | translate }} {{ minimumPrice | currencyPipe }}.
          <span [innerHTML]="'dont_worry_you_have_chosen_the_order' | translate | safeHtml"></span>
        </div>
      </div>
      <div *ngSwitchCase="'minimum'" class="cart-section">
        <div class="order-limit ods-typography-body-s-bold">{{ remainingPriceText | translate }}</div>
      </div>
    </ng-container>
    <div *ngIf="showPriceBreakDown" class="cart-total-break-down cart-section" [class.disabled]="disabledCartButton">
      <div class="cart-total-row">
        <span class="cart-total-row-title">{{ 'total_' | translate }}</span>
        <span class="cart-total-row-number">{{ formattedItemsPriceWithoutEnvFee }}</span>
      </div>
      <div *ngIf="formattedEnvFee" class="cart-total-row">
        <span class="cart-total-row-title">{{ 'environmental_fee' | translate }}</span>
        <span class="cart-total-row-number">{{ formattedEnvFee }}</span>
      </div>
      <div *ngIf="formattedServiceFee" class="cart-total-row">
        <span class="cart-total-row-title">{{ 'delivery_fee' | translate }}</span>
        <span class="cart-total-row-number">{{ formattedServiceFee }}</span>
      </div>
      <div *ngIf="formattedDiscount" class="cart-total-row">
        <span class="cart-total-row-title">{{ 'win_from_the_discount' | translate }}</span>
        <span class="cart-total-row-number">{{ formattedDiscount }}</span>
      </div>
      <div class="box-divider"></div>
      <div class="cart-total-row">
        <span class="cart-total-row-title">{{ 'final_order_price' | translate }}</span>
        <span class="cart-total-row-number">{{ formattedTotal }}</span>
      </div>
    </div>
    <cart-button
      #cartButtonComponent
      [disabled]="disabledCartButton"
      [pointsSum]="pointsSum"
      [multiplierSum]="multiplierSum"
      [quantity]="cartTotalQuantity"
      [price]="totalPrice / 100"
      (click)="onCartSubmit()"
      ><span class="cart-next-step">{{ 'next_step' | translate }}</span></cart-button
    >
  </footer>
</div>
