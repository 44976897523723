<div class="shop-menu">
  <div class="shop-menu-nav-container">
    <shop-menu-nav class="shop-menu-nav" (itemChange)="onMenuNavItemChange($event)"></shop-menu-nav>
  </div>
  <div class="shop-menu-content">
    <shop-page-info></shop-page-info>
    <shop-page-delivery-method></shop-page-delivery-method>
    <shop-description *ngIf="description?.length" [description]="description"></shop-description>
    <div class="shop-menu-content-sticky-section">
      <shop-menu-nav-mobile
        *ngIf="showNavMobile"
        class="shop-menu-nav-mobile"
        (itemChange)="onMenuNavItemChange($event)"
      ></shop-menu-nav-mobile>
      <shop-menu-search
        class="shop-menu-search"
        [placeholder]="searchPlaceholder | translate"
        (stateChange)="onSearchResultsStateChange($event)"
      ></shop-menu-search>
    </div>
    <div class="shop-menu-items-container">
      <div *ngIf="searchResultsIsOpen" class="shop-menu-items-container-overlay"></div>
      <ng-container *ngIf="!shop.categoryView; else categoryView">
        <shop-menu-items [shop]="shop"></shop-menu-items>
      </ng-container>
      <ng-template #categoryView>
        <categories-menu-items
          *ngIf="fetchShopItemsCompleted"
          (categorySelected)="onSuperMarketCategorySelected($event)"
          [shop]="shop"
        ></categories-menu-items>
      </ng-template>
    </div>
  </div>
</div>
