import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  ChangeDetectorRef,
  Optional,
  Input,
  HostBinding
} from '@angular/core';
import { DialogService, ConfigurationService, DiscoverSearchService } from '@box-core/services';
import { DiscoverShopsService } from '@box-discover/services/discover-shops.service';
import { DiscoverCuisinesService } from '@box-discover/services';
import { getSortingKeyFromOptions, getShopSortingInfoText } from '@box/utils';
import { ShopSortingInfoKey } from '@box-types';

@Component({
  selector: 'discover-sorting-info',
  templateUrl: './discover-sorting-info.component.html',
  styleUrls: ['./discover-sorting-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverSortingInfoComponent implements AfterViewInit {
  @Input() public sortingKey: ShopSortingInfoKey;
  private sortingInfoText: string;

  constructor(
    private dialogService: DialogService,
    private configService: ConfigurationService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() private discoverShopsService: DiscoverShopsService,
    @Optional() private discoverCuisinesService: DiscoverCuisinesService,
    @Optional() private discoverSearchService: DiscoverSearchService
  ) {}

  @HostBinding('class') public hostClass = 'discover-sorting-info';

  ngAfterViewInit(): void {
    this.initializeSortingInfo();
    this.changeDetectorRef.detach();
  }

  private initializeSortingInfo(): void {
    const sortingInfo = this.configService.getConfiguration()?.shops?.sortingInfo;
    if (this.sortingKey) {
      this.sortingInfoText = getShopSortingInfoText(sortingInfo, this.sortingKey);
      return;
    }
    const searchTerm = this.discoverSearchService?.searchTerm?.getValue();
    const checkedFoodCuisine =
      this.discoverCuisinesService?.getCheckedFoodCuisine() ||
      this.discoverCuisinesService?.getCheckedGroceriesCuisine() ||
      null;
    const sortingOptions = this.discoverShopsService?.getSortingOptions();
    const sortingKey = getSortingKeyFromOptions(searchTerm, checkedFoodCuisine, sortingOptions);
    this.sortingInfoText = getShopSortingInfoText(sortingInfo, sortingKey);
  }

  public onShowInfo(): void {
    this.initializeSortingInfo();
    if (!this.sortingInfoText?.length) return;
    this.dialogService.openInfoDialog({
      title: 'results_sorting',
      messages: [this.sortingInfoText],
      userAction: false
    });
  }
}
