import { Component, HostBinding, OnInit } from '@angular/core';
import { DiscoverCuisinesService } from '@box-discover/services/discover-cuisines.service';
import { Cuisine, CollapsibleTile } from '@box-types';
import { DiscoverTilesOptions, cuisineToCollapsibleTile, filterShopsByMainOrSecondaryCuisines } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'discover-groceries-cuisines',
  templateUrl: './discover-groceries-cuisines.component.html',
  styleUrls: ['./discover-groceries-cuisines.component.scss']
})
export class DiscoverGroceriesCuisinesComponent implements OnInit {
  public tiles: CollapsibleTile<Cuisine>[];
  public discoverTilesOptions: DiscoverTilesOptions;

  constructor(
    private discoverCuisinesService: DiscoverCuisinesService,
    private globalStateService: GlobalStateService
  ) {}

  @HostBinding('class') public hostClass = 'discover-groceries-cuisines';

  ngOnInit(): void {
    const cuisines = this.discoverCuisinesService.getGroceriesCuisines();
    const shops = this.globalStateService.getShops();
    this.tiles = cuisines.map((c) =>
      cuisineToCollapsibleTile(c, filterShopsByMainOrSecondaryCuisines(shops, [c]).length)
    );
    this.discoverTilesOptions = this.discoverCuisinesService.getDiscoverCuisineTilesOptions('groceries');
  }

  public onTileToggle(tile: CollapsibleTile<Cuisine>): void {
    const activeCuisine = tile?.item;
    this.discoverCuisinesService.setCheckedGroceriesCuisine(activeCuisine);
  }
}
