import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Timeslot, Address, Location } from '@box-types';
import { timeslotToText, addressToHeaderText, getAddressIcon } from '@box/utils';
import { AnalyticsService, translate } from '@box-core/services';
import { Router } from '@angular/router';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'box-header-delivery-details',
  templateUrl: './box-header-delivery-details.component.html',
  styleUrls: ['./box-header-delivery-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxHeaderDeliveryDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public address: Address;
  @Input() public timeslot: Timeslot;

  @Output() private addressChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private timeslotChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addressText: string;
  public timeslotText: string;
  public addressIconPath: string;

  private locationSubscription: Subscription;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private globalStateService: GlobalStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setLocationSubscription();
  }

  ngOnDestroy() {
    this.locationSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address) {
      this.address = changes.address.currentValue as Address;
      this.setAddressText();
      this.setAddressIconPath();
    }
    if (changes.timeslot) {
      this.timeslot = changes.timeslot.currentValue as Timeslot;
      this.timeslotText = timeslotToText(this.timeslot);
    }
  }

  private setLocationSubscription(): void {
    this.locationSubscription = this.globalStateService.location$.subscribe((location: Location) => {
      if (!location?.name || this.address) return;
      this.address = { city: location.name } as Address;
      this.setAddressText();
      this.changeDetectorRef.detectChanges();
    });
  }

  private setAddressText(): void {
    this.addressText = addressToHeaderText(this.address, translate);
  }

  private setAddressIconPath(): void {
    this.addressIconPath = getAddressIcon(this.address);
  }

  public onAddressClick(): void {
    const source = this.router.url;
    const gaCustomConfig = { source };
    this.analyticsService.addGACustomEvent('address_change', gaCustomConfig);
    this.addressChange.emit();
  }

  public onTimeslotClick(): void {
    this.timeslotChange.emit();
  }
}
