import { Injectable } from '@angular/core';
import { CoreService, ConfigurationService, PromoCampaignsService } from '@box-core/services';
import { Brand, Cuisine, Shop } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { decorateBrandWithBenefits, filterSortAndSliceBrands, getBrandsForCuisine } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class DiscoverBrandsService {
  private readonly checkedBrandSource = new BehaviorSubject<Brand>(undefined);
  public readonly checkedBrand$ = this.checkedBrandSource.asObservable();

  private readonly filteredBrandsSource = new BehaviorSubject<Brand[]>([]);
  public readonly filteredBrands$ = this.filteredBrandsSource.asObservable();

  constructor(
    private coreService: CoreService,
    private configService: ConfigurationService,
    private promoCampaignsService: PromoCampaignsService,
    private globalStateService: GlobalStateService
  ) {}

  public getCheckedBrand(): Brand {
    return this.checkedBrandSource.getValue();
  }

  public setCheckedBrand(brand: Brand): void {
    this.checkedBrandSource.next(brand);
  }

  public getFilteredBrands(): Brand[] {
    return this.filteredBrandsSource.getValue();
  }

  public setFilteredBrands(brands: Brand[]): void {
    this.filteredBrandsSource.next(brands);
  }

  public getBrandsForCuisine(cuisine?: Cuisine): Brand[] {
    const brands = this.coreService.brands.getValue();
    return getBrandsForCuisine(cuisine, brands);
  }

  public init(activeBrandName: string, activeCuisineKey?: string): void {
    const cuisines = this.coreService.cuisines.getValue();
    const activeCuisine = cuisines.find((cuisine) => cuisine.key === activeCuisineKey);
    if (!activeCuisine) {
      this.setCheckedBrand(null);
      return;
    }
    const brandsForCuisine = this.getBrandsForCuisine(activeCuisine);
    const shops = this.globalStateService.getShops();
    const sortedBrands = this.decorateFilterAndSortBrands(brandsForCuisine, shops);
    this.setFilteredBrands(sortedBrands);
    const checkedBrand = brandsForCuisine.find((brand) => brand.name === activeBrandName);
    if (checkedBrand) this.setCheckedBrand(checkedBrand);
  }

  public decorateFilterAndSortBrands(brands: Brand[], shops: Shop[]): Brand[] {
    const maxBrands = this.configService.getConfiguration().maxBrandsNo;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const decoratedBrands = brands.map((brand) => decorateBrandWithBenefits(brand, promoCampaigns));
    return filterSortAndSliceBrands(decoratedBrands, shops, maxBrands);
  }

  public getBrandNameFromQueryString(queryParams: URLSearchParams): string {
    return queryParams.get('brand');
  }

  public appendBrandQueryString(queryParams: URLSearchParams): void {
    const checkedBrand = this.getCheckedBrand();
    if (!checkedBrand) return;
    queryParams.set('brand', checkedBrand.name);
  }
}
