<div boxNav *ngIf="options.tabs?.length && url?.length" [options]="options.navOptions" class="navigation-wrapper">
  <div
    *ngFor="let tab of options.tabs"
    boxNavItem
    [class.box-nav-item-active]="tab.urlMatcher(url)"
    class="navigation-item"
    (click)="onBoxNavItemClick(tab)"
  >
    <svg-icon class="box-nav-item-icon" [svgPath]="tab.svgPath"> </svg-icon>
    <span class="box-nav-item-text navigation-item-text">{{ tab.title | translate }}</span>
  </div>
</div>
