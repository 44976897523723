import {
  DiscoverFilter,
  Shop,
  CollapsibleTile,
  BusinessVertical,
  GAEcommerceItemConfig,
  GAPromotionConfig
} from '@box-types';

export {
  getDiscoverPagePromotionName,
  getDiscoverPageCreativeSlot,
  getDiscoverPageItemListName,
  createDiscoverFiltersImpressionItems,
  getCollectionDiscoverFiltersImpressionGAConfig,
  getCollectionDiscoverCuisinesImpressionGAConfig,
  createDiscoverCuisinesImpressionItems,
  createDiscoverShopsImpressionItems,
  getCollectionDiscoverShopsImpressionGAConfig
};

function getDiscoverPagePromotionName(vertical: BusinessVertical): string {
  return vertical === 'groceries' ? 'shops_grocery' : 'shops_food';
}

function getDiscoverPageCreativeSlot(vertical: BusinessVertical, isLandingPage: boolean): string {
  return vertical === 'groceries'
    ? isLandingPage
      ? 'discover_psonia'
      : 'discover_grocery'
    : isLandingPage
      ? 'discover_estiatoria'
      : 'discover_food';
}

function getDiscoverPageItemListName(vertical: BusinessVertical): string {
  return vertical === 'groceries' ? '' : 'kouzines';
}

function createDiscoverFiltersImpressionItems(
  itemListName: string,
  filters: DiscoverFilter[],
  selectedFilter: DiscoverFilter
): GAEcommerceItemConfig[] {
  return filters.reduce((itemsArray: GAEcommerceItemConfig[], filter, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: filter.slug,
      item_name: '',
      index: index + 1,
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedFilter.slug) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionDiscoverFiltersImpressionGAConfig(
  promotionName: string,
  filters: DiscoverFilter[],
  selectedFilter: DiscoverFilter,
  creativeSlot: string
): GAPromotionConfig {
  if (!filters?.length) return;
  const promotionItems = createDiscoverFiltersImpressionItems('offers_for_you', filters, selectedFilter);
  return {
    creative_name: selectedFilter.slug,
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createDiscoverCuisinesImpressionItems<T>(
  itemListName: string,
  cuisines: CollapsibleTile<T>[],
  selectedCuisine: CollapsibleTile<T>
): GAEcommerceItemConfig[] {
  return cuisines.reduce((itemsArray: GAEcommerceItemConfig[], cuisine, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: cuisine.key,
      item_name: '',
      index: index + 1,
      item_category2: cuisine.title,
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedCuisine.key) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionDiscoverCuisinesImpressionGAConfig<T>(
  vertical: BusinessVertical,
  promotionName: string,
  cuisines: CollapsibleTile<T>[],
  selectedCuisine: CollapsibleTile<T>,
  isLandingPage: boolean
): GAPromotionConfig {
  if (!cuisines?.length) return;
  const creativeSlot = getDiscoverPageCreativeSlot(vertical, isLandingPage);
  const itemListName = getDiscoverPageItemListName(vertical);
  const promotionItems = createDiscoverCuisinesImpressionItems(itemListName, cuisines, selectedCuisine);
  return {
    creative_name: selectedCuisine.title,
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createDiscoverShopsImpressionItems(
  itemListName: string,
  shops: Shop[],
  selectedShop: Shop
): GAEcommerceItemConfig[] {
  return shops.reduce((itemsArray: GAEcommerceItemConfig[], shop, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: shop._id,
      item_name: shop.name,
      affiliation: shop.promo.firstGroupLongOptions?.text ?? '',
      affiliation2: shop.promo.secondGroupLongOptions?.text ?? '',
      index: index + 1,
      item_category: shop.mainCuisine?.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: ''
    };
    if (item.item_id === selectedShop._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionDiscoverShopsImpressionGAConfig(
  vertical: BusinessVertical,
  shops: Shop[],
  selectedShop: Shop,
  isLandingPage: boolean
): GAPromotionConfig {
  if (!shops?.length) return;
  const creativeSlot = getDiscoverPageCreativeSlot(vertical, isLandingPage);
  const promotionName = getDiscoverPagePromotionName(vertical);
  const promotionItems = createDiscoverShopsImpressionItems(creativeSlot, shops, selectedShop);
  return {
    creative_name: '',
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}
