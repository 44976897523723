import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [Components.DiscoverTilesComponent, Components.DiscoverSortingInfoComponent],
  imports: [CommonModule, SharedModule],
  exports: [Components.DiscoverTilesComponent, Components.DiscoverSortingInfoComponent]
})
export class DiscoverWidgetModule {}
