<div class="box-dialog-wrapper">
  <header class="box-dialog-header">
    <box-dialog-header [title]="'filters_' | translate" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section *ngIf="sortingOptions?.length" class="discover-shops-filters-dialog-section">
      <h4>{{ 'sorting_' | translate }}</h4>
      <mat-radio-group
        color="primary"
        class="discover-shops-filters-dialog-radio-group discover-shops-filters-dialog-section-container"
        (change)="onChangeSorting($event)"
      >
        <mat-radio-button
          class="discover-shops-filters-dialog-radio-button"
          *ngFor="let option of sortingOptions"
          [checked]="option.checked"
          [value]="option"
        >
          <div class="discover-shops-filters-dialog-radio-text">{{ option.label | translate }}</div>
        </mat-radio-button>
      </mat-radio-group>
    </section>

    <section *ngIf="dietaryFilteringOptions?.length" class="discover-shops-filters-dialog-section">
      <h4>{{ 'diet_' | translate }}</h4>
      <div class="discover-shops-filters-dialog-section-container">
        <mat-checkbox
          *ngFor="let filter of dietaryFilteringOptions"
          color="primary"
          [checked]="filter.checked"
          [disabled]="filter.disabled"
          (change)="onChangeFiltering($event, filter)"
        >
          <div class="discover-shops-filters-dialog-checkbox-content">
            <span class="discover-shops-filters-dialog-checkbox-content-text">{{ filter.label | translate }}</span>
            <img
              *ngIf="filter.icon"
              class="discover-shops-filters-dialog-checkbox-content-image"
              [src]="filter.icon"
              [alt]="filter.label"
            />
          </div>
        </mat-checkbox>
      </div>
    </section>

    <section *ngIf="paymentFilteringOptions?.length" class="discover-shops-filters-dialog-section">
      <h4>{{ 'payment_' | translate }}</h4>
      <div class="discover-shops-filters-dialog-section-container">
        <mat-checkbox
          *ngFor="let filter of paymentFilteringOptions"
          color="primary"
          [checked]="filter.checked"
          [disabled]="filter.disabled"
          (change)="onChangeFiltering($event, filter)"
        >
          <div class="discover-shops-filters-dialog-checkbox-content">
            <span class="discover-shops-filters-dialog-checkbox-content-text">{{ filter.label | translate }}</span>
            <img
              *ngIf="filter.icon"
              class="discover-shops-filters-dialog-checkbox-content-image"
              [src]="filter.icon"
              [alt]="filter.label"
            />
          </div>
        </mat-checkbox>
      </div>
    </section>

    <section *ngIf="deliveryOption" class="discover-shops-filters-dialog-section">
      <h4>{{ 'delivery_' | translate }}</h4>
      <div class="discover-shops-filters-dialog-section-container">
        <mat-checkbox
          color="primary"
          [checked]="deliveryOption.checked"
          [disabled]="deliveryOption.disabled"
          (change)="onChangeFiltering($event, deliveryOption)"
        >
          <div class="discover-shops-filters-dialog-checkbox-content">
            <span class="discover-shops-filters-dialog-checkbox-content-text">{{
              deliveryOption.label | translate
            }}</span>
            <img
              *ngIf="deliveryOption.icon"
              class="discover-shops-filters-dialog-checkbox-content-image"
              [src]="deliveryOption.icon"
              [alt]="deliveryOption.label"
            />
          </div>
        </mat-checkbox>
      </div>
    </section>
  </main>
  <footer class="box-dialog-footer discover-shops-filters-dialog-footer">
    <button box-button theme="primary" class="discover-shops-filters-dialog-footer-button" (click)="onSubmit()">
      {{ 'apply_' | translate }}
    </button>
  </footer>
</div>
