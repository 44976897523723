import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogConfig } from '@angular/material/dialog';
import { combineLatest, forkJoin, iif, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
  deleteJsonLd,
  shopDefaultDecorator,
  getShopDeliveryFee,
  shouldFetchDaasAvailability,
  decorateShopWithDaasOperatingState,
  getTimeslotsStatus,
  cartHasInactiveOffers,
  getBeginCheckoutGAConfig,
  setPrerenderReady,
  getMultiplierSum,
  getPointsSum,
  normalizeShop
} from '@box/utils';
import {
  AnalyticsService,
  CartService,
  DeliveryMethodService,
  DialogService,
  LoaderService,
  OrdersService,
  SentryService,
  ShopService,
  ShopsService,
  UserService,
  AuthenticationService,
  CampaignEligibilityService,
  translate
} from '@box-core/services';
import {
  Address,
  Shop,
  Timeslot,
  Order,
  Cuisine,
  DaasAvailability,
  DaasAvailabilityFetchOptions,
  APIError
} from '@box-types';
import { CartDialogComponent, CartButtonComponent } from '@box-cart-widget/components';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { Platform } from '@angular/cdk/platform';
import { ShopResolverData } from '@box-delivery/delivery.types';
import { DaasAvailabilityService } from '@box-core/services/daas-availability.service';
import { TimeslotsDialogsService } from '@box-core/services/timeslots-dialogs.service';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';
import { CartShow } from '@box-core/animations';
import { ShopPageService, ShopMenuPageService } from '@box-delivery/services';
import { UrgentConditionNotificationService } from '@box-core/services/urgent-condition-notification.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'shop-page',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss'],
  animations: [CartShow]
})
export class ShopPage implements OnInit, OnDestroy {
  @ViewChild('cartButtonComponent') private cartButtonComponent: CartButtonComponent;
  @HostBinding('class') public pageWrapper = 'page-wrapper';

  public shop: Shop;
  public shopHeroImage: string;
  public shopHeroBackgroundColor: string;
  public cartTotalPrice: number;
  public cartTotalQuantity: number;
  public cartItemsPrice: number;
  public cuisine: Cuisine;
  public shopResolverData: ShopResolverData;
  public canContinue: boolean;
  public ctaShow = false;
  public cartDialogOpen: boolean;
  public multiplierSum: number;
  public pointsSum: number;
  private address: Address;
  private timeslot: Timeslot;
  private daasAvailability: DaasAvailability;
  private totalPriceSubscription: Subscription;
  private cartItemsSubscription: Subscription;
  private addressSubscription: Subscription;
  private timeslotSubscription: Subscription;
  private shopItemsSubscription: Subscription;

  constructor(
    private platform: Platform,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private shopService: ShopService,
    private shopsService: ShopsService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private loaderService: LoaderService,
    private timeslotsService: TimeslotsService,
    private timeslotDialogsService: TimeslotsDialogsService,
    private shopPageService: ShopPageService,
    private ordersService: OrdersService,
    private daasAvailabilityService: DaasAvailabilityService,
    private deliveryMethodService: DeliveryMethodService,
    private breadCrumbService: BreadCrumbService,
    private sentryService: SentryService,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private shopMenuPageService: ShopMenuPageService,
    private campaignEligibilityService: CampaignEligibilityService,
    private urgentConditionNotificationService: UrgentConditionNotificationService,
    private globalStateService: GlobalStateService
  ) {}

  ngOnInit(): void {
    this.shop = this.shopService.getShop();
    this.shopHeroImage = this.shopPageService.getShopHeroBackgroundImageStyle(this.shop);
    this.shopHeroBackgroundColor = this.shopPageService.getShopHeroBackgroundColor(this.shop);
    this.shopPageService.setMetaTags(this.shop);
    this.setShopResolverData();
    if (!this.platform.isBrowser) return;
    this.triggerShopViewAnalyticsEvent();
    this.deliveryMethodService.selectDeliveryMethodForShopPage(this.shop);
    this.setTotalPriceSubscription();
    this.setCartItemsSubscription();
    this.checkForChainFailedClosestShopFlow();
    if (!this.shop.chainView) this.setAddressSubscription();
    if (!this.shop.chainView) this.setTimeslotSubscription();
    this.fetchShopItems();
    this.shopPageService.initialAggresiveRatingJsonLdShop(this.shop);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    this.shopService.clearOrders();
    this.timeslotsService.clearTimeslots(); // runs after checkout resolver
    this.daasAvailabilityService.removeNotification();
    this.urgentConditionNotificationService.removeShopNotification();
    deleteJsonLd();
  }

  private setShopResolverData(): void {
    this.shopResolverData = this.activatedRoute.snapshot.data.shopResolverData as ShopResolverData;
  }

  private checkForChainFailedClosestShopFlow(): void {
    if (!this.shop.chainView) return;
    const failedClosest = (this.activatedRoute.snapshot.data.shopResolverData as ShopResolverData).failedClosest;
    if (failedClosest) this.shopPageService.openUnreachableAddressDelivery();
  }

  private clearSubscriptions(): void {
    if (this.totalPriceSubscription) this.totalPriceSubscription.unsubscribe();
    if (this.addressSubscription) this.addressSubscription.unsubscribe();
    if (this.timeslotSubscription) this.timeslotSubscription.unsubscribe();
    if (this.shopItemsSubscription) this.shopItemsSubscription.unsubscribe();
    if (this.cartItemsSubscription) this.cartItemsSubscription.unsubscribe();
  }

  private setAddressSubscription(): void {
    this.addressSubscription = this.globalStateService.address$.subscribe((address) => {
      this.address = address;
      if (!this.address) this.canContinue = true;
      if (!this.address && !this.authenticationService.isAuthenticated) {
        this.shopPageService.showAddAddressNotification();
      }
      this.checkAddressAvailability();
    });
  }

  private setTimeslotSubscription(): void {
    this.timeslotSubscription = this.timeslotsService.timeslot$.subscribe((timeslot) => {
      this.timeslot = timeslot;
      this.getDaasAvailability();
      this.checkTimeslotAvailability();
    });
  }

  // we only show this notification if there is not any daas dialog or notification shown
  private showShopPageUrgentConditionNotification(): void {
    if (this.shop.chainView) return;
    const operatingState = this.shopService.getShop().operatingState;
    if (operatingState !== 'OPEN') return;
    this.urgentConditionNotificationService.showUrgentConditionsNotification('Shop');
  }

  private getDaasAvailability(): void {
    this.daasAvailabilityService.clearDaasAvailability(this.shop);
    const address = this.globalStateService.getAddress();
    const method = this.deliveryMethodService.getDeliveryMethod();
    if (!shouldFetchDaasAvailability(this.shop, address, method, this.timeslot)) {
      delete this.daasAvailability;
      const updatedShop = shopDefaultDecorator(this.shop, translate, this.timeslot);
      this.shopService.setShop(updatedShop);
      this.showShopPageUrgentConditionNotification();
      return;
    }
    this.loaderService.setState(true);

    const daasAvailabilityOptions: DaasAvailabilityFetchOptions = {
      origin: 'shop',
      deliveryETA: this.shop.projectedDeliveryETA,
      latitude: this.address?.latitude,
      longitude: this.address?.longitude
    };

    this.daasAvailabilityService
      .fetchDaasAvailability(this.shop, daasAvailabilityOptions)
      .pipe(
        tap((availability) => this.daasAvailabilityService.postFetchActions(this.shop, availability)),
        finalize(() => this.loaderService.setState(false))
      )
      .subscribe({
        next: (availability) => {
          this.daasAvailability = availability;

          const defaultShop = shopDefaultDecorator(this.shop, translate, this.timeslot);
          /* That behavior is only needed in the Shop Page. */
          const updatedDaasShop = decorateShopWithDaasOperatingState(
            defaultShop,
            availability,
            translate,
            this.timeslot
          );
          this.shopService.setShop(updatedDaasShop);

          if (!this.daasAvailabilityService.getNotificationRef() && !this.daasAvailabilityService.getDialogRef()) {
            this.showShopPageUrgentConditionNotification();
          }
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private setTotalPriceSubscription(): void {
    this.totalPriceSubscription = combineLatest({
      deliveryMethod: this.deliveryMethodService.deliveryMethod,
      cart: this.cartService.cart$,
      shop: this.shopService.shop.pipe(tap((shop) => (this.shop = shop)))
    }).subscribe(({ deliveryMethod, cart, shop }) => {
      this.cartItemsPrice = cart.itemsFinalPrice;
      this.cartTotalPrice = this.cartItemsPrice + getShopDeliveryFee(shop, deliveryMethod, this.cartItemsPrice);
    });
  }

  private setCartItemsSubscription(): void {
    this.cartItemsSubscription = this.cartService.cart$.subscribe((cart) => {
      const previousQuantity = this.cartTotalQuantity;
      const currentQuantity = cart.itemsQuantity;
      this.handleCartAnimations(previousQuantity, currentQuantity);
      this.cartTotalQuantity = currentQuantity;
      this.ctaShow = cartHasInactiveOffers(cart) || currentQuantity > 0;
      this.setBenefitsData();
    });
  }

  private setBenefitsData(): void {
    const campaigns = this.campaignEligibilityService.getConsumedPromoCampaigns();
    this.multiplierSum = getMultiplierSum(campaigns);
    this.pointsSum = getPointsSum(campaigns);
  }

  private handleCartAnimations(previousQuantity: number, currentQuantity: number): void {
    if (this.cartDialogOpen || currentQuantity <= (previousQuantity ?? 0)) return;
    this.cartButtonComponent?.twist();
  }

  private updateShop(shop: Partial<Shop>): void {
    const updatedShop = { ...this.shopService.getShop(), ...shop };
    const normalizedShop = normalizeShop(updatedShop);
    this.shopService.setShop(normalizedShop);
  }

  public onCartSubmit(): void {
    this.shopPageService.checkCheckoutRedirectionEligibility();
    this.triggerAnalyticsEvent();
  }

  public onMobileCartOpen(): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog',
      data: {
        isOpenForDelivery: this.canContinue
      }
    };

    this.cartDialogOpen = true;
    this.dialogService
      .openDialog(CartDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: { submit: boolean }) => {
        this.cartDialogOpen = false;
        if (data?.submit) this.onCartSubmit();
      });
  }

  private fetchShopItems(): void {
    this.shopService.setFetchShopItemsCompleted(false);
    this.loaderService.setState(true);
    forkJoin({
      items: this.shopService.getShopItems(this.shop).pipe(catchError((error) => throwError(error))),
      orders: iif(
        () => this.userService.isGuest,
        of([] as Order[]),
        this.shopService.fetchOrders$(this.shop._id).pipe(
          map((orders) => orders.map((order) => this.ordersService.orderDecorator(order))),
          catchError(() => of([] as Order[]))
        )
      ),
      shopSuggestionBanners: this.shopMenuPageService.getShopSuggestionBanners(this.shop),
      coupons: this.shopMenuPageService.getShopSuggestionCoupons$(this.shop)
    })
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
        }),
        map(({ items, orders, shopSuggestionBanners, coupons }) => {
          this.shopService.setShopSuggestionBanners(shopSuggestionBanners);
          this.shopService.setCoupons(coupons);
          const updatedItems = this.shopPageService.generateShopPageItems(this.shop, items, orders);
          return { items: updatedItems, orders };
        })
      )
      .subscribe({
        next: ({ items, orders }) => {
          this.breadCrumbService.addBreadcrumbs({ shopName: this.shop.name });
          this.breadCrumbService.addBreadcrumbs({ location: this.shop?.locationName });
          this.shopService.setItems(items);
          if (!this.shop.chainView) this.shopPageService.initializeCart();
          this.shopService.setFetchShopItemsCompleted(true);
          this.shopService.setOrders(orders);
          setPrerenderReady(true);
        },
        error: (error: Error | APIError) => {
          this.sentryService.captureException(error, {
            domain: 'Shop Page',
            domainDetails: 'Shop Page Fetch Shop Items',
            severity: 'error'
          });
        }
      });
  }

  private checkTimeslotAvailability(): void {
    const timeslotStatus = getTimeslotsStatus(this.shop, this.timeslot);
    switch (timeslotStatus.code) {
      case 'NO_TIMESLOTS':
        this.timeslotDialogsService.showNoTimeslotSupportDialog();
        break;
      case 'TIMESLOT_INCLUDED':
        this.timeslotDialogsService.showTimeslotIncludedDialog(this.shop, timeslotStatus.nextAvailableTimeslot);
        break;
      case 'FUTURE_TIMESLOT_AVAILABLE':
        this.timeslotDialogsService.showFutureTimeslotAvailableDialog(this.shop, timeslotStatus.nextAvailableTimeslot);
        break;
      case 'TIMESLOTS_UNAVAILABLE':
        this.timeslotDialogsService.showTimeslotsUnavailableDialog();
    }
  }

  private checkAddressAvailability(): void {
    if (!this.address) return;
    this.loaderService.setState(true);
    this.shopsService
      .canDeliverToAddress(this.shop._id, this.address)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (shop) => {
          this.shopService.setCanDeliverToAddress(shop.found);
          this.canContinue = shop.found;
          if (!shop?.found) this.shopPageService.openUnreachableAddressDelivery();
          this.updateShop(shop);
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private triggerAnalyticsEvent(): void {
    const options = {
      totalPrice: this.cartTotalPrice,
      shop: this.shop,
      cart: this.cartService.getCart(),
      purchaseEvent: this.analyticsService.getPurchaseEvent(this.shop._id),
      categories: this.shopService.getShopItemsFromMemory().categories
    };
    const gaConfig = getBeginCheckoutGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('begin_checkout', gaConfig);
  }

  private triggerShopViewAnalyticsEvent(): void {
    const { _id: shopId, name: shop_name } = this.shop;
    const purchaseEvent = this.analyticsService.getPurchaseEvent(shopId);
    if (!purchaseEvent) return;
    const gaConfig = {
      item_list_id: '',
      item_list_name: purchaseEvent.itemListName,
      index: purchaseEvent.index,
      shop_name
    };
    this.analyticsService.addGACustomEvent('view_shop_page', gaConfig);
  }
}
