import { Injectable } from '@angular/core';
import {
  DiscoverFilteringOption,
  DiscoverSearchItems,
  DiscoverSearchResultItem,
  DiscoverSearchShop,
  DiscoverSortingOption,
  Shop
} from '@box-types';
import { DiscoverSearchService } from '@box-discover/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { discoverSortingOptions } from '@box-configs/discover-sorting.options';
import { CoreService } from '@box-core/services';
import { generateDiscoverFilteringOptions } from '@box/utils';
import { map } from 'rxjs/operators';

@Injectable()
export class DiscoverShopsService {
  private readonly sortingOptionsSource = new BehaviorSubject<DiscoverSortingOption[]>(discoverSortingOptions);
  private readonly filteringOptionsSource = new BehaviorSubject<DiscoverFilteringOption[]>([]);

  public readonly sortingOptions$ = this.sortingOptionsSource.asObservable();
  public readonly filteringOptions$ = this.filteringOptionsSource.asObservable();

  constructor(private discoverSearchService: DiscoverSearchService, private coreService: CoreService) {}

  public initializeFilteringOptions(shops: Shop[]): void {
    const tags = this.coreService.tags.getValue();
    const filteringOptions = generateDiscoverFilteringOptions(shops, tags);
    this.setFilteringOptions(filteringOptions);
  }

  public getSortingOptions(): DiscoverSortingOption[] {
    return this.sortingOptionsSource.getValue();
  }

  public setFilteringOptions(filteringOptions: DiscoverFilteringOption[]): void {
    this.filteringOptionsSource.next(filteringOptions);
  }

  public getFilteringOptions(): DiscoverFilteringOption[] {
    return this.filteringOptionsSource.getValue();
  }

  public getCheckedFilteringOptions(): DiscoverFilteringOption[] {
    return this.getFilteringOptions().filter((filter) => filter.checked);
  }

  public getSelectedSortingOption(): DiscoverSortingOption {
    return this.getSortingOptions().find((option) => option.checked);
  }

  public getCheckedFilteringOptions$(): Observable<DiscoverFilteringOption[]> {
    return this.filteringOptions$.pipe(
      map((filters) => {
        return filters.filter((filter) => filter.checked);
      })
    );
  }

  public selectSortingOption(option: DiscoverSortingOption): void {
    const currentOptions = this.sortingOptionsSource.getValue();
    this.sortingOptionsSource.next(currentOptions.map((o) => ({ ...o, checked: o.slug === option.slug })));
  }

  public changeFilteringOption(option: DiscoverFilteringOption, checked: boolean): void {
    const currentOptions = this.filteringOptionsSource.getValue();
    const currentOption: DiscoverFilteringOption = currentOptions.find((o) => o.slug === option.slug);
    if (currentOption) currentOption.checked = checked;
    this.setFilteringOptions(currentOptions);
  }

  public selectFilteringOptions(options: DiscoverFilteringOption[]): void {
    const currentOptions = this.filteringOptionsSource.getValue();
    const selectedSlugs = options.map((option) => option.slug);
    const updateOptions = currentOptions.map((currentOption) => ({
      ...currentOption,
      checked: selectedSlugs.includes(currentOption.slug)
    }));
    this.setFilteringOptions(updateOptions);
  }

  public generateDiscoverSearchItems(
    sortedShops: Shop[],
    shopsWithItems?: DiscoverSearchShop[]
  ): DiscoverSearchItems<DiscoverSearchResultItem>[] {
    const shopsInResponse: DiscoverSearchItems<DiscoverSearchResultItem>[] = [];
    const shopsNotInResponse: DiscoverSearchItems<DiscoverSearchResultItem>[] = [];
    sortedShops?.map((shop) => {
      const shopWithItems = shopsWithItems?.find(
        (shopWithItems) => shopWithItems.collectionType === shop.collectionType
      );
      if (shopWithItems) {
        shopsInResponse.push(this.generateDiscoverSearchItem(shop, shopWithItems));
      } else {
        shopsNotInResponse.push({ shop, items: [] });
      }
    });
    return [...shopsInResponse, ...shopsNotInResponse];
  }

  private generateDiscoverSearchItem(
    shop: Shop,
    searchShop: DiscoverSearchShop
  ): DiscoverSearchItems<DiscoverSearchResultItem> {
    const offerItems = this.discoverSearchService.generateDiscoverSearchOfferItems(searchShop);
    const productItems = this.discoverSearchService.generateDiscoverSearchProductItems(searchShop);
    const items = [...offerItems, ...productItems];
    return { shop, items };
  }
}
