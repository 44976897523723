import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DiscoverFilter } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'discover-filter',
  templateUrl: './discover-filter.component.html',
  styleUrls: ['./discover-filter.component.scss']
})
export class DiscoverFilterComponent implements OnInit {
  @HostBinding('class') public hostClass = 'discover-filter';
  @Input() public discoverFilter: DiscoverFilter;
  @Output() private discoverFilterToggle: EventEmitter<DiscoverFilter> = new EventEmitter<DiscoverFilter>();

  public imageUrl: string;
  public altText: string;
  public disabled: boolean;

  ngOnInit(): void {
    this.imageUrl = generateImageSrc(this.discoverFilter.webImage);
    this.altText = this.discoverFilter.title;
    this.disabled = this.discoverFilter.shopsCount === 0;
  }

  public onFilterClick(): void {
    if (this.disabled) return;
    this.discoverFilterToggle.emit(this.discoverFilter);
  }
}
