import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService, TimeslotsService } from '@box-core/services';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';
import { Cuisine, DeliveryAnchor, Shop, APIError, CollapsibleTile, Brand } from '@box-types';
import {
  deleteJsonLd,
  DiscoverTilesOptions,
  generateCuisineLocationsDeliveryAnchors,
  generateShopsDeliveryAnchors,
  generateShopsTitleDescription,
  setPrerenderReady
} from '@box/utils';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CuisinePageService } from './cuisine-page.service';
import { translate as t } from '@box-core/services/language.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'cuisine',
  templateUrl: './cuisine.page.html',
  styleUrls: ['./cuisine.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CuisinePageService]
})
export class CuisinePage implements OnInit, OnDestroy {
  public cuisine: Cuisine;
  public shops: Shop[] = [];
  public loading: boolean;
  public locationsAnchors: DeliveryAnchor[];
  public newShopsAnchors: DeliveryAnchor[];
  public chainShopsAnchors: DeliveryAnchor[];
  public shopsTitleDescription: string;
  public showBrands: boolean;
  public tiles: CollapsibleTile<Brand>[];
  public brandsTilesOptions: DiscoverTilesOptions;

  private pageSubscription: Subscription;

  constructor(
    private timeslotsService: TimeslotsService,
    private changeDetectorRef: ChangeDetectorRef,
    private cuisinePageService: CuisinePageService,
    private breadCrumbService: BreadCrumbService,
    private analyticsService: AnalyticsService,
    private globalStateService: GlobalStateService
  ) {}

  @HostBinding('class') public pageWrapper = 'page-wrapper';

  ngOnInit(): void {
    setPrerenderReady(false);
    this.initPage();
  }

  ngOnDestroy(): void {
    if (this.pageSubscription) this.pageSubscription.unsubscribe();
    deleteJsonLd();
  }

  private initPage(): void {
    this.loading = true;
    this.cuisine = this.cuisinePageService.getCuisine();
    this.pageSubscription = combineLatest({
      address: this.globalStateService.address$,
      timeslot: this.timeslotsService.timeslot$
    })
      .pipe(
        tap(() => {
          this.loading = true;
          this.changeDetectorRef.detectChanges();
        }),
        switchMap(({ address }) =>
          forkJoin({
            locations: this.cuisinePageService.fetchLocationsByPostalCode(address),
            shops: this.cuisinePageService.fetchCuisineShops(this.cuisine)
          }).pipe(map(({ locations, shops }) => ({ locations, shops })))
        )
      )
      .subscribe({
        next: ({ locations, shops }) => {
          if (!shops.length) this.cuisinePageService.handleEmptyStatePage();
          this.cuisinePageService.setCuisinePageMetaTags(this.cuisine);
          this.breadCrumbService.addBreadcrumbs({ cuisine: this.cuisine.name });
          const newShops = shops.filter((shop) => shop.isNew);
          const chainShops = shops.filter((shop) => shop.chain);
          this.cuisinePageService.setShops(shops);
          this.shops = this.cuisinePageService.getShops();
          this.shopsTitleDescription = generateShopsTitleDescription(this.shops?.length, t);
          this.locationsAnchors = generateCuisineLocationsDeliveryAnchors(this.cuisine, locations);
          this.newShopsAnchors = generateShopsDeliveryAnchors(newShops);
          this.chainShopsAnchors = generateShopsDeliveryAnchors(chainShops);
          this.setCuisineBrandsData();
          this.loading = false;
          this.changeDetectorRef.detectChanges();
          setPrerenderReady(true);
        },
        error: (error: APIError) => this.cuisinePageService.handleCuisinePageError(error)
      });
  }

  private setCuisineBrandsData(): void {
    this.tiles = this.cuisinePageService.generateTiles(this.cuisine);
    this.brandsTilesOptions = { title: 'see_the_stores_by_variety', size: 'medium' };
    this.showBrands = this.tiles?.length > 0;
  }

  public onTileToggle(tile: CollapsibleTile<Brand>): void {
    const activeBrand = tile?.item;
    this.cuisinePageService.setCheckedBrand(activeBrand);
    this.shops = this.cuisinePageService.getFilteredShopsByBrand();
    this.shopsTitleDescription = generateShopsTitleDescription(this.shops?.length, t);
    this.changeDetectorRef.detectChanges();
  }

  public onShopClick(event: { shop: Shop; index: number }): void {
    this.analyticsService.updatePurchaseEvent(event.shop._id, {
      index: event.index,
      itemListName: this.cuisine.key,
      creativeSlot: 'cuisine_page',
      promotionName: this.cuisine.name
    });
  }
}
