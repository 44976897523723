import { Component, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SEOService, DiscoverFiltersService, AnalyticsService } from '@box-core/services';
import { DiscoverBrandsService, DiscoverCuisinesService, DiscoverLoaderService } from '@box-discover/services';
import { DiscoverBusinessVerticalService } from '@box-discover/services/discover-business-vertical.service';
import { SeoOptions, DiscoverFilter, BusinessVertical, GAPromotionConfig } from '@box-types';
import { deleteJsonLd, filterShopsByMainOrSecondaryCuisines } from '@box/utils';
import { Subscription, take } from 'rxjs';
import { DiscoverPageService } from './discover-page.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { LocationsService } from '@box-delivery/services';
import { getDiscoverPageCreativeSlot } from '@box/utils/src/discover/discover-page-analytics.utils';

@Component({
  selector: 'discover-page',
  templateUrl: './discover.page.html',
  styleUrls: ['./discover.page.scss'],
  providers: [DiscoverPageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverPage implements OnInit, OnDestroy {
  public businessVertical: BusinessVertical;
  public showBrands: boolean;
  public loadingData = true;
  public discoverFilters: DiscoverFilter[];

  private cuisinesSubscription: Subscription;
  private loaderSubscription: Subscription;
  private businessVerticalSubscription: Subscription;
  private filterSubscription: Subscription;

  constructor(
    private router: Router,
    private seoService: SEOService,
    private discoverPageService: DiscoverPageService,
    private discoverBrandsService: DiscoverBrandsService,
    private discoverLoaderService: DiscoverLoaderService,
    private discoverCuisinesService: DiscoverCuisinesService,
    private discoverFiltersService: DiscoverFiltersService,
    private discoverBusinessVerticalService: DiscoverBusinessVerticalService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private globalStateService: GlobalStateService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.setMetaTags();
    /*
    We need to revisit this block of code on Freshen Up v2
    Due to the latest changes on Freshen Up v1, we added skeleton elements
    separately on Discover Page and Discover Search Page. That way, the initial
    subscription on DiscoverPageService (init() method), is redirecting the user
    back to the default state when we visit the page directly or refreshing the page.
    Skipping the initial state will fix the issue temporarily, but this is just
    a small fix to solve the production issue. Please have a look at this during
    or after the Freshen Up v2 Feature
    @faropoulos @vasiliadis
    */
    this.discoverLoaderService.loadingData.pipe(take(2)).subscribe((value) => {
      if (value === false) this.discoverPageService.init();
    });
    this.setFoodCuisinesSubscription();
    this.setFiltersSubscription();
    this.setBusinessVerticalSubscription();
    this.setLoaderSubscription();
  }

  ngOnDestroy(): void {
    this.cuisinesSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.businessVerticalSubscription?.unsubscribe();
    this.loaderSubscription?.unsubscribe();
    this.discoverPageService.destroy();
    deleteJsonLd();
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: 'discover_', url: this.router.url };
    this.seoService.setTags(options);
  }

  private setLoaderSubscription(): void {
    this.loaderSubscription = this.discoverLoaderService.loadingData.subscribe((value) => {
      this.loadingData = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setBusinessVerticalSubscription(): void {
    this.businessVerticalSubscription = this.discoverBusinessVerticalService.businessVertical.subscribe(
      (businessVertical) => {
        this.businessVertical = businessVertical;
        this.changeDetectorRef.detectChanges();
        this.triggerAnalyticsEvent(businessVertical);
      }
    );
  }

  private setFoodCuisinesSubscription(): void {
    this.cuisinesSubscription = this.discoverCuisinesService.checkedFoodCuisine$.subscribe((cuisine) => {
      if (!cuisine) {
        this.showBrands = false;
        this.changeDetectorRef.detectChanges();
        return;
      }

      /* same calculation is happening in discover.page.service applyFoodFiltersAndSort.
         one possible solution is to zip([filteredSortedShops,checkedFoodCuisines$]) in discover this.discoverPageService
         and take advantage of the filterAndSortDiscoverShops invocation,
         but this would result in the brands reacting to all shops filtering including the one happening via tag selection */
      const cuisineFilteredShops = filterShopsByMainOrSecondaryCuisines(this.globalStateService.getShops(), [cuisine]);
      const brands = this.discoverBrandsService.getBrandsForCuisine(cuisine);
      const filteredBrands = this.discoverBrandsService.decorateFilterAndSortBrands(brands, cuisineFilteredShops);
      this.discoverBrandsService.setFilteredBrands(filteredBrands);
      this.showBrands = filteredBrands?.length > 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setFiltersSubscription(): void {
    this.filterSubscription = this.discoverFiltersService.discoverFilters$.subscribe((discoverFilters) => {
      this.discoverFilters = discoverFilters;
      this.changeDetectorRef.detectChanges();
    });
  }

  private triggerAnalyticsEvent(businessVertical: BusinessVertical): void {
    const isLandingPage = this.locationsService.isSeoLocationPage();
    const creativeSlot = getDiscoverPageCreativeSlot(businessVertical, isLandingPage);
    const gaConfig = {
      creative_name: '',
      creative_slot: creativeSlot,
      promotion_id: '',
      promotion_name: 'tabs'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }
}
