import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DiscoverSearchItems, DiscoverSearchItemsOptions, DiscoverSearchResultItem, Shop } from '@box-types';

const defaultOptions: DiscoverSearchItemsOptions = { minimumSearchItems: 3, maximumSearchItems: 10 };

@Component({
  selector: 'discover-shop-result-items',
  templateUrl: './discover-shops-result-items.component.html',
  styleUrls: ['./discover-shops-result-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoverShopResultItemsComponent implements OnInit, AfterViewInit {
  @HostBinding('class') public hostClass = 'discover-search-result-items';
  @Input() public searchShop: DiscoverSearchItems<DiscoverSearchResultItem>;
  @Input() public options: DiscoverSearchItemsOptions = defaultOptions;
  @Input() public disableAnimation = false;
  @Output() private itemSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() private shopSelect = new EventEmitter<Shop>();

  public shop: Shop;
  public items: DiscoverSearchResultItem[];
  public showExpansionButton: boolean;
  public showShopButton: boolean;
  public firstGroup: DiscoverSearchResultItem[];
  public secondGroup: DiscoverSearchResultItem[];
  public expanded: boolean;
  public expandButtonText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.shop = this.searchShop.shop;
    this.items = this.searchShop.items;
    this.showExpansionButton = this.items.length > this.options.minimumSearchItems;
    this.showShopButton = this.items.length > this.options.maximumSearchItems;
    this.firstGroup = this.items.slice(0, this.options.minimumSearchItems);
    this.secondGroup = this.items.slice(this.options.minimumSearchItems, this.options.maximumSearchItems);
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  public onShopCardClick(shop: Shop): void {
    this.shopSelect.emit(shop);
  }

  public onItemClick(itemID: string): void {
    this.itemSelect.emit(itemID);
  }

  public onExpansionToggle(): void {
    this.expanded = !this.expanded;
    this.changeDetectorRef.detectChanges();
  }
}
