<div
  heroOpacity
  class="shop-page-hero"
  [style.background-image]="shopHeroImage"
  [style.background-color]="shopHeroBackgroundColor"
>
  <div sectionSlice size="66%" class="shop-page-hero-slice"></div>
</div>
<section class="shop-page-content-section">
  <div class="container">
    <div class="shop-page-container">
      <div class="shop-page-content">
        <router-outlet></router-outlet>
      </div>
      <div class="shop-page-cart-container">
        <cart [disabled]="!canContinue" (cartSubmit)="onCartSubmit()"></cart>
      </div>

      <cart-button
        #cartButtonComponent
        class="shop-page-cart-button-mobile"
        (click)="onMobileCartOpen()"
        [@cartShow]="ctaShow"
        [pointsSum]="pointsSum"
        [multiplierSum]="multiplierSum"
        [quantity]="cartTotalQuantity"
        [price]="cartTotalPrice / 100"
      >
        {{ 'cart_' | translate }}
      </cart-button>
    </div>
  </div>
</section>
<shop-extra-info [options]="shopResolverData"></shop-extra-info>
