import { Component, Input } from '@angular/core';
import { FAQquestion } from '@box-types';

@Component({
  selector: 'faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQsComponent {
  @Input() public faqQuestions: FAQquestion[];
}
