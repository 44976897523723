import { Overlay, OverlayConfig, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { HomeSearchResultsComponent } from '@box-core/components';
import { AnalyticsService, HomeSearchService } from '@box-core/services';
import { Router } from '@angular/router';
import { Shop, GASearchConfig } from '@box-types';
import { LocationsService } from '@box-delivery/services';

const MOBILE_BREAKPOINT = 512;
const MOBILE_BOTTOM_NAV_WITH_PADDING = 68;
const MOBILE_MAIN_SECTION_PADDING = 24;

@Component({
  selector: 'home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private resultsOverlayRef: OverlayRef;
  private rulerSubscription: Subscription;
  private searchResultsSubscription: Subscription;
  private searchElement: HTMLElement;

  constructor(
    private element: ElementRef,
    private overlay: Overlay,
    private viewportRuler: ViewportRuler,
    private router: Router,
    private homeSearchService: HomeSearchService,
    private analyticsService: AnalyticsService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.createResultsOverlay();
  }

  ngAfterViewInit(): void {
    this.searchElement = this.element?.nativeElement as HTMLElement;
  }

  ngOnDestroy(): void {
    if (this.searchResultsSubscription) this.searchResultsSubscription.unsubscribe();
    if (this.rulerSubscription) this.rulerSubscription.unsubscribe();
    this.resultsOverlayRef.dispose();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: { target: HTMLElement }): void {
    const clickedInputElement = this.searchElement.contains(event.target);
    const clickedOverlayElement = this.resultsOverlayRef.hostElement.contains(event.target);
    if (!(clickedInputElement || clickedOverlayElement)) {
      this.closeResults();
    }
  }

  private createResultsOverlay(): void {
    const overlayConfig: OverlayConfig = {
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withPositions([
          { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }
        ]),
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.close()
    };

    this.resultsOverlayRef = this.overlay.create(overlayConfig);
  }

  public onFocus(searchTerm: string): void {
    this.resultsOverlayRef.updateSize(this.getOverlayDimensions());
    const overlayIsClosed = !this.resultsOverlayRef.hasAttached();
    const searchHasCompleted = this.homeSearchService.searchStatus.getValue() === 'COMPLETED';
    const searchInputFilled = Boolean(searchTerm?.length);
    if (overlayIsClosed && searchHasCompleted && searchInputFilled) {
      this.openResults();
    }
  }

  public onEscape(): void {
    this.closeResults();
  }

  public onClear(): void {
    this.homeSearchService.setSearchTerm('');
    this.closeResults();
  }

  public onSearch(searchTerm: string): void {
    const trimmedTerm = searchTerm.trim();
    if (trimmedTerm?.length < 3) return;
    this.searchForTerm(trimmedTerm);
    this.triggerAnalyticsEvent(trimmedTerm);
    if (!this.resultsOverlayRef.hasAttached()) return this.openResults();
  }

  private searchForTerm(term: string): void {
    this.homeSearchService.setSearchTerm(term);
    this.homeSearchService.setSearchStatus('PENDING');

    this.searchResultsSubscription = this.homeSearchService.fetchSearchShops(term).subscribe((shops: Shop[]) => {
      this.homeSearchService.setSearchResults(shops);
      this.homeSearchService.setSearchStatus('COMPLETED');
    });
  }

  private openResults(): void {
    this.resultsOverlayRef.attach(new ComponentPortal(HomeSearchResultsComponent));
    this.rulerSubscription = this.viewportRuler
      .change()
      .pipe(auditTime(16))
      .subscribe(() => this.resultsOverlayRef.updateSize(this.getOverlayDimensions()));
  }

  private getOverlayDimensions(): { width: number; maxHeight: number } {
    return {
      width: this.getWidth(),
      maxHeight: this.getMaxHeight()
    };
  }

  private getMaxHeight(): number {
    const screenHeight = this.viewportRuler.getViewportSize().height;
    const distanceFromBottom = this.searchElement.getClientRects()[0]?.bottom || 0;
    return screenHeight - distanceFromBottom - MOBILE_BOTTOM_NAV_WITH_PADDING;
  }

  private getWidth(): number {
    const searchInputWidth = this.searchElement?.offsetWidth;
    const screenWidth = this.viewportRuler.getViewportSize().width;
    if (screenWidth <= MOBILE_BREAKPOINT) return screenWidth - 2 * MOBILE_MAIN_SECTION_PADDING;
    return searchInputWidth;
  }

  private closeResults(): void {
    this.resultsOverlayRef.detach();
    if (this.rulerSubscription) this.rulerSubscription.unsubscribe();
  }

  public onKeydownEnter(searchTerm: string): void {
    if (searchTerm?.length < 3) return undefined;
    const queryParams = { vertical: 'food', query: searchTerm };
    void this.router.navigate(['/discover'], { queryParams });
  }

  private triggerAnalyticsEvent(searchTerm: string): void {
    const source = this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home';
    const gaConfig = {
      search_term: searchTerm,
      source
    } as GASearchConfig;
    this.analyticsService.addGAEcommerceEvent('search', gaConfig);
  }
}
