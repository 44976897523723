<div class="page-container discover-shell-page-container">
  <section class="page-section navigation-section">
    <div class="container navigation-container">
      <ng-container *ngIf="loadingData; else normalComponents">
        <div *ngIf="!isMobileScreen" class="discover-navigation--skeleton"></div>
        <div class="discover-search--skeleton"></div>
      </ng-container>
      <ng-template #normalComponents>
        <box-navigation [options]="boxNavigationOptions" *ngIf="!isMobileScreen"></box-navigation>
        <discover-search-input *ngIf="!isSeoLocationPage" [placeholder]="searchPlaceholder"></discover-search-input>
      </ng-template>
    </div>
  </section>
  <ng-container *ngIf="!showSearchPage; else searchPage">
    <discover-page></discover-page>
  </ng-container>
  <ng-template #searchPage>
    <discover-search-page></discover-search-page>
  </ng-template>
</div>
