import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscoverSearchResultItem } from '@box-types';

@Component({
  selector: 'discover-search-result-item',
  templateUrl: './discover-search-result-item.component.html',
  styleUrls: ['./discover-search-result-item.component.scss']
})
export class DiscoverSearchResultItemComponent implements OnInit {
  @Input() public item: DiscoverSearchResultItem;
  @Output() public add: EventEmitter<DiscoverSearchResultItem> = new EventEmitter<DiscoverSearchResultItem>();
  public showPrice = true;

  ngOnInit(): void {
    this.showPrice = this.shouldShowPrice();
  }

  private shouldShowPrice(): boolean {
    return this.item?.price !== 0 || this.item.isOffer === false;
  }

  public onAdd(): void {
    this.add.emit(this.item);
  }
}
