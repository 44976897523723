<ng-container *ngIf="loading; else contentLoaded">
  <section *ngIf="heroImage">
    <div class="promo-page-hero--skeleton">
      <div sectionSlice size="66%" class="promo-page-hero--skeleton-slice"></div>
    </div>
  </section>
  <section class="section promo-page-section">
    <div class="container">
      <h1 class="promo-page-title h1--skeleton"></h1>
    </div>
  </section>
  <section class="section promo-page-section">
    <div class="container">
      <skeleton-shops></skeleton-shops>
    </div>
  </section>
</ng-container>
<ng-template #contentLoaded>
  <div
    *ngIf="heroImage"
    heroOpacity
    class="promo-page-hero"
    [style.background-image]="heroImage"
    [style.background-color]="heroBackgroundColor"
  >
    <div sectionSlice size="66%" class="promo-page-hero-slice"></div>
  </div>
  <section
    class="section promo-page-section promo-page-section-content"
    [class.promo-page-section-content-no-image]="!heroImage"
  >
    <div class="container">
      <h1 class="promo-page-title ods-typography-display-m">{{ title }} | {{'delivery_order_online' | translate}}</h1>
      <h2
        *ngIf="description"
        class="ods-typography-subtitle-m"
        [ngClass]="coupon ? 'promo-page-description-with-coupon' : 'promo-page-description'"
        [innerHTML]="description"
      ></h2>
      <promo-coupon-clipboard *ngIf="coupon" class="promo-page-coupon" [coupon]="coupon"></promo-coupon-clipboard>
    </div>
  </section>
  <section *ngIf="relatedShops?.length > 0" class="section promo-page-section">
    <div class="container">
      <discover-sorting-info></discover-sorting-info>
      <shops
        (shopClick)="onShopClick($event)"
        [shops]="relatedShops"
        [hasDivider]="buttonTitle?.length > 0"
        [class.promo-page-shops-with-button]="buttonTitle"
      ></shops>
    </div>
  </section>
  <section *ngIf="buttonTitle" class="section promo-page-section">
    <div class="container">
      <button box-button theme="primary" (click)="onCtaClick()">{{ buttonTitle }}</button>
    </div>
  </section>
  <section *ngIf="faq?.length>0" class="section promo-page-section">
    <div class="container">
      <h2 class="promo-page-section-title ods-typography-title-l">{{ faqTitle }}</h2>
      <faqs class="promo-page-faqs" [faqQuestions]="faq"></faqs>
    </div>
  </section>
  <section *ngIf="seoText?.length>0" class="section promo-page-section">
    <div class="container">
      <h1 class="promo-page-section-title ods-typography-title-l">{{ seoTitle }}</h1>
      <h2 class="promo-page-seo-text ods-typography-body-m-regular" [innerHTML]="seoText"></h2>
    </div>
  </section>
</ng-template>
