<div class="discover-shop-result-items-wrapper">
  <div class="discover-shop-result-items-shop">
    <shop-tile-compact
      [shop]="shop"
      (click)="onShopCardClick(shop)"
      [disableAnimation]="disableAnimation"
      class="hoverable"
    ></shop-tile-compact>
  </div>
  <div *ngIf="items" class="discover-shop-result-items-container">
    <discover-search-result-item
      *ngFor="let item of firstGroup; let i = index"
      [item]="item"
      (click)="onItemClick(item.itemId)"
      (add)="onItemClick(item.itemId)"
      class="hoverable"
    ></discover-search-result-item>
    <ng-container *ngIf="expanded">
      <discover-search-result-item
        *ngFor="let item of secondGroup; let i = index"
        [item]="item"
        (click)="onItemClick(item.itemId)"
        (add)="onItemClick(item.itemId)"
        class="hoverable"
      ></discover-search-result-item>
    </ng-container>
  </div>
  <div class="discover-shop-result-items-buttons">
    <button
      *ngIf="showExpansionButton && !expanded"
      box-button
      theme="insignificant"
      class="discover-shop-result-items-button"
      size="small"
      (click)="onExpansionToggle()"
    >
      {{ 'see_more_products' | translate }}
    </button>
    <button
      *ngIf="showShopButton && expanded"
      box-button
      theme="insignificant"
      class="discover-shop-result-items-button"
      size="small"
      [routerLink]="['/delivery', shop.locationKey, shop.vanity_url]"
    >
      {{ 'see_the_shop' | translate }}
    </button>
  </div>
</div>
