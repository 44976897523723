export const enTranslations: Record<string, string> = {
  order_now: 'Order now',
  order_: 'Order',
  open_: 'Open',
  start_ordering: 'Start ordering',
  download_android_app: 'Download the BOX Android App',
  connect_register: 'Login/Sign Up',
  appetite_for: 'Do you have an appetite for',
  delivery_where_you_always_win: 'The delivery that always rewards you!',
  box_dishes_more_shops:
    'Now with BOX you have food from even more stores at your door, full of points that you know only BOX can redeem, in GB and discounts.',
  see_here_how: 'See here how',
  discover_more_than_shops: 'Discover over 13,000',
  shops_in_x_cities: 'stores, in 100 cities',
  make_purchase_online: 'Do your shopping online at',
  more_than_x_items: 'over 15,000 products',
  place_your_address: 'Enter your address',
  have_shops: 'Do you have a store?',
  join_team: 'Join our team',
  download_app_for_mobile: 'Download the BOX app on your mobile',
  discover_cuisines: 'Discover our cuisines',
  find_my_location: 'Find my location',
  no_results_found: 'Oops, no stores found!',
  try_typing_address_in_form: 'Try typing your address in the format "Mesogeion Avenue 2, 11527, Athens".',
  enter_comment_in_next_steps: 'You can add comments in the next step',
  order_exclamation: 'Order!',
  gemh_: 'GEMI (General Commercial Registry)',
  faqs_: 'FAQs',
  our_team_is_here_to_help:
    'Our team is at your disposal every day from 07:00 to 03:00 to help you with any questions you may have.',
  data_privacy_policy: 'Data Protection Policy',
  organism_title: 'Name: HELLENIC TELECOMMUNICATIONS ORGANIZATION S.A.',
  useful_: 'Useful',
  order_completion: 'Complete order',
  terms_of_use: 'Terms of Use',
  follow_on_social: 'Follow BOX on social media!',
  take_box_with_you: 'Take BOX with you!',
  address_khfisias: 'Address: Kifisias Ave. No. 99, Marousi',
  cookies_settings: 'Cookie Settings',
  communication_: 'Contact',
  in_num_cities: 'In 100 cities',
  afm_: 'VAT Number',
  shops_for: 'Stores for',
  new_shops_in_area: 'New stores in the area',
  results_sorting: 'Sort results',
  order_online: 'Order Online',
  shops_in_area: 'Delivery Stores in the area',
  most_popular_shops: 'The most popular stores',
  find_suitable_cuisines_for_area: 'Find cuisines that suit you in the area',
  everything_to_know_about_area: 'What you need to know about the area',
  looking_for_more_shops_in_the_area: 'Looking for different flavors in the area?',
  you_can_find_shops_by_pressing:
    "Don't worry though, you can discover thousands of store options and dozens of choices in your area by clicking",
  is_it_your_type: 'Do you like it?',
  here_: 'Here',
  delivery_new_shops_in_box: 'Delivery - New stores on BOX',
  delivery_areas: 'Delivery - Areas',
  online_delivery: 'Online Delivery',
  online_delivery_areas: 'Online Delivery Areas',
  seo_location_cuisine_3:
    '_CUISINE_NAME delivery _LOCATION_NAME, _CUISINE_NAME online delivery _LOCATION_NAME, _CUISINE_NAME delivery offers ${locationName}',
  seo_location_cuisine_2:
    '_CUISINE_NAME Delivery _LOCATION_NAME. Find offers & stores for _CUISINE_NAME delivery in _LOCATION_NAME. Order online and earn GB and points.',
  seo_location_cuisine_1: '_CUISINE_NAME Delivery _LOCATION_NAME | Order Online',
  seo_text_3: '_NAME Delivery. Find delivery offers & stores in _NAME. Order online and earn GB and points.',
  seo_text_2:
    '_NAME delivery, _NAME delivery near me, _NAME online delivery, _NAME online order, _NAME delivery offers',
  seo_text_1: '_NAME Delivery. Find offers & stores for _NAME delivery. Order online and earn GB and points.',
  seo_text_4: '_NAME Delivery | Order Online',
  the_only_rewarding_delivery: 'THE ONLY FOOD DELIVERY APP THAT <br />\nREWARDS YOU EVERY TIME YOU <br />\nORDER!',
  download_box: 'DOWNLOAD THE BOX!',
  box_in_100_cities: 'BOX it in 100 cities!',
  cookies_part1:
    'Cookies and other similar tracking mechanisms (hereinafter referred to as cookies) are important for the proper functioning of www.box.gr and to improve your online experience. We use cookies for:',
  cookies_part2:
    'to log in to the website or to provide an internet service you requested (<strong>necessary and functionality cookies</strong>). Without these cookies, it is technically impossible to connect to our website or provide a service you requested (e.g., cookies for recording your purchases in our online store). <strong>For this reason, these cookies are always enabled.</strong>',
  cookies_part3:
    'collecting <strong>aggregated information</strong> that helps us understand how users use our site and improve its function, structure, and content (<strong>performance cookies</strong>).',
  cookies_part4:
    'customizing advertising content (<strong>advertising targeting cookies</strong>) to reflect your specific needs and interests and to measure and/or improve the effectiveness of our advertising campaigns.',
  cookies_part5:
    'Click <strong>“Accept cookies”</strong> to <strong>activate all cookies</strong> or <strong>“Reject cookies”</strong> to <strong>activate only the “necessary and functionality cookies”</strong> category, or select <strong>“More information”</strong> to choose which cookies you want to use.',
  exclusively_for: 'Exclusive for _SEGMENT_USERS!',
  our_wu_users: 'What’s Up Student users',
  doy_: 'Tax Office',
  cannot_find_address: 'Address not found',
  missing_elements_from_address:
    'Unfortunately, the selected address does not have complete information. Please, enter another address to proceed.',
  won_the_first: 'You just won the first',
  last_week_u_won: 'Last week, you saved',
  last_month_u_won: 'Last month, you saved',
  delivery_estimation_almost_at: '_DELIVERY_ESTIMATION_MINUTES’, around _DELIVERY_ESTIMATION_TIME',
  delivery_tomorrow_almost_start_end_slot: 'Tomorrow, around _DELIVERY_START_SLOT-_DELIVERY_END_SLOT',
  delivery_today_almost_start_end_slot: 'Today, around _DELIVERY_START_SLOT-_DELIVERY_END_SLOT',
  online_delivery_you_always_win: 'BOX Online Delivery | The delivery that always rewards you',
  box_the_only_rewarding_delivery_app:
    'BOX. The only food delivery app that rewards you. Download it, order, and earn MB and discounts!',
  download_box_and_earn: 'Download BOX, Order Online, Earn Discounts & MB!',
  order_now_exclamation: 'Order now!',
  afm_field_is_invalid: 'The VAT field is incorrect',
  doy_field_is_required: 'The Tax Office field is <strong>mandatory</strong>',
  deliveryman_tip_selection_range:
    'The tip will be received entirely by the delivery agent! You can choose from _CURRENCY0.2 to _CURRENCY20.',
  child_smile: 'The Smile of the Child',
  one_minute: '1 minute',
  deliveryman_tip: 'Tip for the delivery agent',
  box_hour_ended: 'The BOX Hour has ended',
  the_coupon_is_valid_for_over_minimum_price: 'The coupon is valid for orders over minimum_price',
  cannot_redeem: 'Redemption failure',
  cancel_: 'Cancel',
  change_: 'Change',
  change_unavailable_product_with: 'Replace unavailable product with',
  changing_the_time_of_a_scheduled_order: 'Change scheduled order time',
  other_: 'Other',
  search_shop_or_product: 'Search for store or product',
  search_shop_dish: 'Search for store, dish, or ingredient',
  search_: 'Search',
  card_search: 'Card search',
  discover_: 'Discover',
  waiting_restaurant_order_acceptance: 'We are waiting for your order to be accepted by the store.',
  products_refresh: 'Product renewal',
  disable_: 'Inactive',
  reopens_date_at: 'Reopens _DATE at _FUTURE_OPEN_TIME',
  reopens_tomorrow_at: 'Reopens Tomorrow at _FUTURE_OPEN_TIME',
  reopens_at: 'Reopens at _FUTURE_OPEN_TIME',
  copy_code_to_clipboard: 'Copy the coupon code to clipboard',
  copy_to_clipboard: 'Copy to clipboard',
  delivery_fee: 'Delivery fee',
  reviews_: 'Ratings',
  win_from_the_discount: 'You earn from the discount',
  delivery_receipt: 'Delivery Receipt',
  i_accept_the_gift: 'I accept the gift',
  accept_cookies: 'Accept Cookies',
  save_: 'Save',
  save_options: 'Save Options',
  save_as: 'Save as',
  exclusive_offer: 'Exclusive offer',
  reject_cookies: 'Reject Cookies',
  send_: 'Send',
  send_again: 'Resend',
  send_again_in: 'Resend to',
  payment_failure: 'Payment failure',
  later_: 'Later',
  number_: 'Number',
  address_number: 'Address Number',
  card_number: 'Card number',
  home_page: 'Home',
  tomorrow_: 'Tomorrow',
  the_page_is_currently_unavailable: 'The page is currently unavailable.',
  remove_card: 'Remove _CARD_NAME',
  remove_: 'Remove',
  remove_from_cart: 'Remove from cart',
  got_: 'You received',
  insert_address_text: 'Enter your address and start boxing!',
  help_us_get_better: 'Help us get better!',
  we_found: 'We found',
  thats_why_your_voucher_cannot_be_activated: 'This coupon cannot be activated for you',
  to_participate_you_need_to_connect: 'To participate in the contest, you need to log in to the app.',
  for_more_info: 'For more information',
  for_you: 'For you',
  for_mobile_usage: 'for your mobile phone',
  write_your_comment_here: 'Write your comment here',
  no_saved_cards_found: 'No saved cards found.',
  no_results_found_for: 'No results found for _SEARCH_TERM',
  coupons_not_found: 'No coupons found.',
  see_shops_dishes_ingredients: 'See stores, dishes, and ingredients',
  discover_shops: 'Discover stores',
  unavailable_coupons_not_found: 'No unavailable coupons found.',
  no_products_found_in_this_category: 'No products found in this category.',
  contact_data: 'Contact information',
  we_dont_recognize_this_coupon: 'We do not recognize this coupon.',
  do_not_accept_the_gift: 'I do not accept the gift',
  no_reviews_found_for_this_product: 'No reviews found for this product.',
  you_did_not_receive_the: 'Didn’t receive the',
  no_saved_cards: 'You have no saved cards',
  not_enough_bonus_points_for_redemption: 'You do not have enough Bonus points to redeem!',
  cannot_redeem_cart_is_less_than:
    'Redemption cannot proceed because the value of your cart is less than _EURO_DISCOUNT. You can add more products to the cart to get it.',
  win_1_eur_for_4_order_eur:
    'Redeem them during the submission of your order and earn _CURRENCY1 for every _CURRENCY4 order!',
  data_protection_terms_updated: 'The Data Protection Terms have been updated',
  u_can_see_updates_in_data_protection_terms_page:
    'You can always see the changes in the "Data Protection Terms" category in your profile.',
  use_terms_updated: 'The Terms of Use have been updated',
  u_can_see_updates_in_use_terms_page: 'You can always see the changes in the "Terms of Use" category in your profile.',
  personalization_terms_updated: 'The Personalization Terms have been updated',
  u_can_see_updates_in_personalization_page:
    'You can always see the changes in the "Personalization Terms" category in your profile.',
  see_updates_by_pressing_learn_more: 'You can see the changes by clicking "Learn more".',
  learn_more: 'Learn more',
  after_every_order_u_can_get: 'With the completion of each order, you can get:',
  after_order_u_can_get: 'Additionally, with the completion of each order, you can get:',
  after_first_order_u_get: 'Upon completing your first order you will receive:',
  you_didnt_cover_the_min_order: 'You didn’t meet the minimum order',
  no_reviews_for_this_shop: 'There are no reviews for this store.',
  see_details: 'In detail',
  see_other_shops: 'See other stores',
  see_in_depth: 'See Details',
  see_all_shops: 'See all stores',
  see_more_products: 'See more products',
  see_more_offers: 'See more offers',
  see_them_all: 'See all',
  see_the_shop: 'View the store',
  create_new_card: 'Create New Card',
  read_and_accept_the: 'I have read and accept the',
  delete_: 'Delete',
  delete_address: 'Delete address',
  delete_profile: 'Delete profile',
  contests_: 'Contests',
  delivery_: 'Delivery',
  delivery_to_address: 'Delivery to _ADDRESS_TYPE',
  diet_: 'Nutrition',
  cookies_management: 'Manage cookies and similar technologies',
  addresses_: 'Addresses',
  address_: 'Address',
  activity_: 'Activity',
  gift_: 'GIFT!',
  here_you_earn: 'Here you earn',
  i_am_sure: 'I am sure',
  coupon_insertion: 'Enter coupon code',
  insert_new_coupon: 'Enter new coupon code',
  are_u_sure_u_discard_the_present: 'Are you sure you don’t want to accept your gift?',
  discount_: 'DISCOUNT',
  minimum_order_title: 'Minimum order',
  minimum_order: 'Minimum order:',
  expired_: 'Expired',
  enable_: 'Active',
  active_until: 'Active until',
  activate_: 'Activate',
  redeeming_: 'Redeem',
  redeem_: 'Redeem',
  redeem_points_while_waiting_or_at_the_end_of_the_order_and_get_gb:
    'Redeem points during the wait or at the end of the order and get GB for your mobile',
  redeem_points_when_sending_your_order_and_earn_discounts: 'Redeem points when sending your order and earn discounts',
  redeem_your_coupon: 'Redeem your coupon',
  coupon_redemption: 'Coupon redemption',
  points_redemption: 'Points redemption',
  pending_points_redemption: 'Pending points redemption',
  order_progress: 'Order progress',
  smart_replacement: 'Smart Replacement',
  edit_: 'Edit',
  confirm_: 'OK',
  data_confirmation: 'Confirm data',
  last_name: 'Last name',
  contact_us: 'Contact us',
  choose_a_category: 'Choose a category',
  choose_where_you_wantto_take_your_meal: 'Choose where you want to pick up your meal',
  choices_: 'Options',
  payment_method_choice: 'Payment method selection',
  time_selection: 'Time selection',
  back_: 'Return',
  back_to_home_page: 'Return to home',
  next_step: 'Next step',
  work_: 'Work',
  restaurants_: 'Restaurants',
  thanks_for_your_tip: 'Thank you for the tip!',
  thank_u_very_much: 'Thank you very much!',
  apply_: 'Apply',
  device_mode_applied: 'Device display mode applies',
  you_have: 'You have',
  you_have_already_earned_6x_points: 'You have already earned 6x points',
  do_you_have_any_allergy: 'Do you have a store?',
  the_cookies_rejection_was_saved: 'The rejection of cookies has been saved.',
  acceptance_of_cookies_was_saved: 'The acceptance of cookies has been saved.',
  your_critic_makes_us_better: 'Your feedback helps us improve!',
  address_not_found: 'The address was not found',
  the_address_is_too_far_away: 'The address is too far',
  my_address: 'My address',
  your_address: 'Your address',
  contest_participation_at: 'Your entry has been registered for the contest on _REGISTRATION_DATE',
  irreversible_process: 'The action is irreversible',
  or_select_ingredients: 'or select ingredients',
  your_review: 'Your review',
  my_order: 'My order',
  your_order_has_been_rejected: 'Your order was not accepted.',
  your_order_has_been_accepted: 'Your order has been accepted!',
  your_order_is_on_its_way: 'Your order is on its way!',
  your_order_is_very_closed: 'Your order is very close!',
  your_order_is_being_prepared: 'Your order is being prepared!',
  gift_rejected: 'The gift collection has been canceled.',
  happy_hour_offer_is_out_of_hours: 'The happy hour offer is out of hours',
  the_delivery_time_you_have_selected_is_unavailable:
    'The delivery time you selected is not available. You can schedule an order',
  days_: 'days',
  day_: 'day',
  birth_date: 'Date of birth',
  expiration_date: 'Expiration date',
  we_will_replace_the_product_with_one_of_equivalent_quality_and_value:
    'We will replace the product with one of equivalent quality and value',
  we_will_remove_the_product_from_your_order_and_the_final_cost:
    'We will remove the product from your order and the final cost',
  it_will_be_ready_for_pickup: 'It will be ready for pickup:',
  at_your_door: 'It will be at your door',
  do_u_want_to_rate: 'Would you like to rate your experience on the BOX app?',
  tell_us_what_you_liked_more: 'Tell us what you liked the most?',
  tell_us_what_you_did_not_like: 'Tell us what went wrong? What can we improve?',
  your_opinion_is_precious: 'Your opinion is valuable.',
  choose_number_where_gb_will_go_to:
    'Select the number you want your GB to be credited to with the redemption of your points!',
  i_will_take_from_the_shop: 'I will pick up from the store',
  i_will_take_with_delivery: 'I will pick up with delivery',
  we_will_call_you_to_choose_the_replacement_product_together:
    'We will call you to choose the replacement product together',
  do_you_want_us_to_notice_anything: 'Do you want us to look after something?',
  i_want_to_participate: 'I want to participate',
  order_history: 'History',
  valid_: 'Valid:',
  terms_apply: 'Terms apply',
  maybe_later: 'Maybe later',
  and_earn: 'and win',
  welcome_to_box: 'Welcome to BOX',
  your_phone_is_needed_to_contact_with_you:
    'Your mobile number will be needed to contact you via SMS in case you are one of the winners and will not be used for any other purpose.',
  to_participate_accept_terms_and_give_phone:
    'To participate in the contest, you will need to accept the contest terms and enter your mobile phone number in the field below.',
  no_change: 'No change',
  register_to_box_to_invite_friends: 'Sign up/log in to BOX to invite friends and earn points',
  supermarket_cards: 'Supermarket Cards',
  shops_: 'stores',
  download_huawei_app: 'Download the BOX Huawei App',
  download_ios_app: 'Download the BOX iOS App',
  navigate_to_restaurant: 'We are on our way to the restaurant!',
  something_went_wrong: 'Something went wrong!',
  get_a_discount_up_to: 'Win a discount of up to',
  win_with_every_order: 'Win with every order',
  win_multiple_points: 'Win multiple points',
  mobile_: 'Mobile',
  ok_: 'Ok',
  mobile_phone: 'Mobile phone',
  close_: 'Close',
  closed_: 'Closed',
  call_: 'Call',
  share_location: 'Share location',
  name_at_bell: 'Bell',
  verification_code: 'Confirmation code',
  less_: 'Less',
  account_: 'Account',
  due_to_increased_traffic_unfortunately_the_shop_was_closed: 'Due to high demand, unfortunately the store is closed.',
  sorry_page_not_found: 'Sorry, the page was not found',
  with_your_order_you_won: 'With your order you earned',
  maximum_product_purchase_limit: 'Maximum product purchase limit',
  maximum_delivery_limit: 'Maximum delivery limit',
  cash_: 'Cash',
  cash_or_pos_upon_receipt: 'Cash or POS upon receipt',
  try_again: 'Try again',
  try_again_in_a_bit: 'Try again in a while.',
  help_us_highlight_the_best_shops: 'Until your meal arrives, help us showcase the best stores!',
  unavailable_: 'Not available',
  unavailable_products: 'Unavailable products',
  no_delivery_times_available: 'Unavailable delivery times',
  unavailable_delivery_time: 'Unavailable delivery time',
  invalid_email: 'Invalid Email',
  dont_forget: "Don't forget!",
  maybe_the_next_day: 'Maybe the next day?',
  an_order_with_a_card: 'An order with a card',
  you_just_won: 'You just won',
  you_can_find_the_friends_invitation_in_your_profile_at_any_time:
    'You can find the Friends Invitation in your Profile at any time',
  you_can_close_page: 'You can close the page',
  you_can_order_from_other_shops: 'You can order from other stores.',
  yes_: 'Yes',
  yes_want: 'Yes, I want to!',
  unlock_it_by_placing_orders_over: 'Unlock it by placing orders over',
  mobile_number_is_invalid: 'The number is not valid',
  contest_expiration_at: 'Contest end: _REMAINING_TIME',
  contest_expired: 'The contest has ended',
  deliveryman_is_navigating_to_restaurant: 'The delivery agent is on the way to the restaurant!',
  deliveryman_is_waiting_to_receive_your_order: 'The delivery agent is waiting to pick up your order!',
  the_code_is: 'The code is:',
  verification_code_error_text: 'The confirmation code must be 4 digits!',
  your_referral_code_is: 'Your code is',
  coupon_code_has_been_copied_to_the_clipboard: 'The coupon code has been copied to the clipboard.',
  my_account: 'My account',
  products_volume: 'Product volume',
  instructions_: 'Instructions',
  street_: 'Street',
  my_numbers: 'My numbers',
  where_shoud_we_bring: 'Where should we bring it to you?',
  my_addresses: 'My addresses',
  your_orders: 'Your orders',
  the_points_you_earn_are: 'The points you earn are:',
  terms_of_personalization: 'Personalization Terms',
  terms_: 'Terms of use and personalization',
  contest_terms: 'Contest Terms',
  floor_: 'Floor',
  not_now: 'Not now',
  no_do_not_want: "No, I don't want",
  address_comments_example: 'e.g., Building B, entrance from the side, beware of dog...',
  order_and_win: 'Order and win',
  orders_: 'Orders',
  order_delivery_in: 'Delivery of order to:',
  please_try_again_later: 'Please try again later',
  please_add_it: 'Please add it again',
  receiving_your_order: 'We are receiving your order!',
  click_here: 'Click here',
  click_here_to_add_comment: 'Click here to add a comment',
  environmental_fee: 'Environmental Fee',
  description_: 'Description',
  area_: 'Area',
  more_: 'More',
  tell_us_what_you_thing_and_earn: 'Tell us your opinion and win',
  back_to: 'Back',
  back_to_products: 'Back to products',
  back_to_shop: 'Back to store',
  type_your_comment_here: 'Type your comment here',
  type_your_code_here: 'Type your code here',
  informations_: 'Info',
  info_for_shop: 'Store information',
  informations_and_settings: 'Information and settings',
  shop_info: 'Store info',
  coupon_info: 'Coupon information',
  payment_: 'Payment',
  payment_with: 'Payment by',
  payment_with_card: 'Payment by card',
  payment_with_cash: 'Payment by cash',
  town_: 'City',
  town_area: 'City / Area',
  points_: 'Points',
  points_uppercase: 'POINTS',
  points_lowercase: 'Points',
  the_points: 'points',
  when_do_you_want_to_receive: 'When do you want to receive it?',
  where_can_i_find_code: 'Where can I find a code?',
  where_should_we_deliver_it: 'Where should we bring it?',
  accept_gift_until_deadline: 'You need to accept the gift by _ACCEPTANCE_DEADLINE',
  scheduled_: 'Scheduled',
  system_default: 'System default',
  previous_: 'Previous',
  products_: 'products',
  add_new_address: 'Add new address',
  add_products_and_earn: 'Add products and win',
  add_your_location: 'Add your location',
  addition_: 'Add',
  add_new_with_other_options: 'Add new with different options',
  add_to_cart: 'Add to cart',
  products_addition: 'Add ingredients',
  invite_friends: 'Invite friends',
  bring_a_friend: 'Bring a friend',
  privacy_policy: 'Data Protection',
  recent_orders: 'Recent orders',
  offers_: 'offers',
  temporarily_closed: 'Temporarily Closed',
  recommendations_: 'Recommendations',
  recommended_: 'Recommend',
  i_suggest: 'I recommend:',
  profile_: 'Profile',
  what_did_you_like: 'What did you like?',
  what_did_you_not_like: "What didn't you like?",
  how_was_the_box_delivery: 'How was the delivery from BOX?',
  how_was_the_delivery: 'How was the delivery?',
  how_was_your_order: 'How was your order?',
  what_do_u_think_about_box: 'Do you like BOX?',
  settings_: 'Settings',
  bags_with_charge: 'Bags with extra charge',
  its_worth_it_for_you: "It's worth it for you",
  in_progress: 'In progress',
  thank_you_for_choosing_us: 'Thank you for choosing BOX!',
  today_: 'Today',
  dark_mode: 'Dark mode',
  home_: 'Home',
  send_it: 'Send it!',
  invoice_details: 'Billing Details',
  user_details: 'User Details',
  congrats_: 'Congratulations!',
  paticipations_: 'entries',
  participation_: 'participation',
  login_to_continue: 'Log in to continue!',
  login_with_your_mobile_or_email:
    "Log in with your mobile or email. If you don't have an account, we'll create one in a minute!",
  combine_and_win: 'Combine and win',
  next_: 'Continue',
  continue_to_shop: 'Continue to the store',
  continue_without_card: 'Continue without a card',
  total_: 'Total',
  products_total: 'Total products',
  you_will_soon_receive_order_progress_notification:
    'You will soon receive a notification about the progress of your order!',
  coming_soon: 'Coming soon!',
  drag_the_bar_and_start_earning: 'Slide the bar and start winning!',
  comment_: 'Comment',
  my_products: 'My products',
  sorting_: 'Sort',
  postal_code: 'ZIP Code',
  final_order_price: 'Final order price',
  final_products_price: 'Final product price',
  contact_by_phone: 'Contact by phone',
  company_telephone: 'Company number',
  shop_telephone: 'Store Phone',
  box_hour_ended_for_today: 'BOX Hour ended for today',
  my_box: 'My BOX',
  gift_will_be_given_to_next_candidate: 'The gift will be received by the next runner-up.',
  your_cart_is_empty: 'Your cart is empty!',
  the_shop_does_not_serve_scheduled_orders: 'The store does not serve scheduled orders.',
  the_shop_cannot_serve_you: 'The store cannot serve you',
  the_shop_closed: 'The store is closed',
  the_shop_can_serve_scheduled_order: 'The shop can serve scheduled order.',
  the_shop_may_not_be_serving_you: 'The store may not serve you, log in to see stores in your area!',
  closed_shop: 'The store is temporarily unavailable.',
  the_coupon_does_not_meet_all_redemption_criteria_on_this_order:
    'The coupon does not meet all redemption criteria for this order.',
  email_field_is_required: 'The Email field is required',
  number_field_is_required: 'The Number field is <strong>mandatory</strong>',
  card_number_field_accepts_only_numbers: 'The Card Number field accepts only numbers',
  card_number_field_is_required: 'The Card Number field is required',
  address_field_is_invalid: 'The Address field is incorrect',
  activity_field_is_required: 'The Activity field is <strong>mandatory</strong>',
  comapny_name_field_is_required: 'The Business Name field is <strong>mandatory</strong>',
  name_at_bell_max_chars: 'The Bell field cannot exceed',
  name_at_bell_required: 'The Bell field is required',
  street_field_is_required: 'The Street field is required',
  floor_required: 'The Floor field is required',
  area_field_is_invalid: 'The Area field is not correct',
  town_area_required: 'The City / Area field is required',
  town_field_is_invalid: 'The City field is not correct',
  postal_code_field_is_invalid: 'The Postal Code field is not correct',
  company_telephone_field_is_required: 'The Company Phone field is <strong>mandatory</strong>',
  tk_required: 'The ZIP Code field is required',
  order_has_maximum_discount: 'Your order amount has already received the maximum possible discount.',
  the_product_includes_env_fee: 'The product includes an environmental fee',
  select_receive_time: 'Select desired pick-up time',
  asap_: 'As soon as possible',
  asap_extended: 'As soon as possible',
  not_more_than_500_characters: 'Your comment must not exceed 500 characters!',
  not_more_than_1500_characters: 'Your comment must not exceed 1500 characters!',
  contact_telephone_is_invalid: 'The contact number is not correct',
  contact_telephone_required: 'The contact number is required',
  its_in_your_list_of_active_coupons: "It's on your active coupons list!",
  submit_: 'Submit',
  order_cost_calculation: 'Order cost calculation',
  filters_: 'Filters',
  make_your_first_order_and_participate: 'Place your first order now and start collecting entries.',
  first_step_is_done: 'The first step is done!',
  make_it_your_own: 'Make it as you like',
  characters_: 'characters',
  service_fee: 'Service charge',
  delivery_time: 'Delivery time',
  without_expiration: 'Without expiration',
  no_: 'No',
  no_comment: 'No comment',
  shopping_: 'Stores',
  hours_: 'hours',
  hour_: 'hour',
  delvery_hours: 'Delivery Hours',
  discount_lowercase: 'Discount',
  more_products: 'more products',
  from_points_and_coupons: 'from points and coupons',
  first_name: 'Name',
  cosmote_id_email: 'Email Cosmote id',
  cosmote_id_mobile: 'Mobile Cosmote id',
  mobile_numbers_included_in_your_cosmote_id: 'mobile numbers included in your COSMOTE id',
  friends_: 'friends',
  you_can_invite_up_to: 'You can invite up to',
  cookies_toggle_title_1: 'Necessary and Functional',
  cookies_toggle_title_2: 'Performance',
  cookies_toggle_title_3: 'Targeting/advertising',
  cookies_toggle_title_4: 'Experience optimization',
  cookies_toggle_description_1:
    'These cookies are technically necessary to connect to the website or to provide the internet service you requested. Cookies in this category cannot be disabled. If you set your browser to block these cookies, the website or some parts of it will not work.',
  cookies_toggle_description_2:
    'They help us understand how visitors interact with the pages by collecting aggregated information that does not directly identify any visitor.',
  cookies_toggle_description_3:
    'They are used to provide personalized content to the visitor and conduct or monitor targeted advertising campaigns.',
  cookies_toggle_description_4:
    'They allow the website to recall information that the visitor has entered in fields to facilitate their navigation.',
  add_new_card_info_text: 'You can add and save cards for online payments during the completion of an order',
  add_new_sm_card_info_text: 'You can add and save supermarket cards during your order in stores that support them',
  delete_profile_text:
    'Here you can delete your BOX profile. This action is permanent and irreversible. After deleting your profile, you will need to create an account again.',
  language_: 'Language: English (EN)',
  my_profile: 'My Profile',
  contests_text: 'Here you can view the contests you won and get all the necessary information',
  rewards_coupons: 'I have a gift coupon',
  supermarket_cards_text:
    'You can see your supermarket cards, your points, use them for online shopping, but also in your favorite supermarket chain’s physical stores',
  payment_cards: 'Payment Cards',
  payment_cards_text:
    'You can add and save cards for online payments during the completion of your order. You can remove a card by clicking “Delete”',
  contact_data_text: 'You can edit your mobile number so we can contact you about your order.',
  user_details_text: 'You can edit your personal details whenever you want',
  where_should_we_deliver_them: 'Where to bring them to',
  add_new_address_text: 'Add the address where you want to receive your food',
  comments_: 'Comments',
  tk_: 'ZIP Code',
  you_have_totally_won: 'You have earned in total',
  account_personalisation_terms_text:
    "We want to inform you via email and mobile about all the news from BOX, so you don't miss",
  account_personalisation_terms_option_1: 'I agree to receive suggestions based on my preferences.',
  account_personalisation_terms_option_2: 'I do not agree to receive suggestions based on my preferences.',
  new_shops_to_try: 'New stores you want to try!',
  discounts_coupons_and_offers: 'Discounts, coupons, and great offers',
  button_for_referral_code_copy: 'Button to copy the Reference Code',
  mobile_confirmation: 'Mobile verification',
  phone_verification_dialog_description_1:
    'We have sent you a code via SMS to _FORMATTED_PHONE, please enter this code here',
  phone_verification_dialog_description_2: 'Add the mobile number you use for identification.',
  for_every_problem_call_our_team: 'For any issues, you can call our team at',
  every_day_time_for_help: ', every day, 07:00 - 03:00, to help you.',
  delete_profile_dialog_text_1:
    'You are about to delete your BOX profile. \nThis action is permanent and irreversible.',
  delete_profile_dialog_text_2: 'After deleting your profile, you will need to create an account again.',
  delete_profile_dialog_text_3: 'Do you want to delete your profile?',
  are_you_sure_for_address_deletion: 'Are you sure you want to delete this address?',
  you_do_not_have_previous_orders: 'You have no previous orders.',
  choose_and_start_now: 'Choose what you like from BOX stores and start ordering now!',
  dont_worry_for_closed_shop: "Don't worry, you can always order from the best stores BOX has to offer!",
  you_have_won: 'You have earned:',
  thank_you_for_choosing_box: 'Thank you for choosing BOX!',
  contact_with_shop: 'Contact the store',
  thank_you: 'Thank you!',
  tell_us_your_opinion_title: 'Tell us your opinion!',
  tell_us_your_opinion: 'Give us your feedback',
  take_away_from_shop: 'pickup from the store',
  points_analysis: 'Points analysis',
  points_from_the_shop: 'points from _SHOP_NAME',
  your_order: 'Your order',
  shipped_at_your_door: 'Arrived at your door on:',
  it_will_be_ready_for_pickup_in: 'It will be ready for pickup in:',
  it_will_be_at_your_door: 'It will be at your door:',
  it_will_be_at_your_door_in: 'It will be at your door in:',
  upon_completion_of_your_order_you_won: 'Congratulations! With the completion of your order you earned…',
  earn_better_gb_packs_from_whatsup_student_for_the_msisdn_number:
    'Earn better GB bundles from What’s Up Student for number _MSISDN_NUMBER!',
  you_will_be_informed_when_you_get_the_gb_on_the_number:
    'You will be notified by SMS once you get the GB on the number',
  choose_the_package_you_want_and_you_will_have_it_after_completing_your_order:
    'Choose the bundle you want now and you’ll have it immediately after completing your order.',
  you_will_get_the_gb_on: 'You will receive the GB at',
  i_want_it: 'I want it',
  in_female: 'at',
  your_: 'your',
  watch_your_order_live_on_the_map: 'you will be able to track your order live on the map!',
  your_order_couldnt_be_served_due_to_deliveryman_unavailability:
    'Unfortunately, your order could not be fulfilled due to the unavailability of a delivery agent.',
  dont_worry_the_refund_has_already_been_processed: "Don't worry, a refund has already been scheduled.",
  dont_worry_your_money_will_be_returned:
    "Don't worry! Your money will be released immediately and returned to your card.",
  want_to_earn_more: 'Want to earn more?',
  code_: 'Code:',
  in_box_you_always_win: 'With BOX you always win!',
  the_available_packages_title: 'The available segment_title bundles',
  enter_your_coupon_code_and_earn: 'Type your coupon code and earn a discount on your order or bonus points!',
  you_have_to_redeem: 'Available for redemption:',
  start_adding_products_and_earn_points: 'Start adding the products you like and earning points with your order!',
  this_action_will_remove_the_offer_from_the_cart: 'This action will remove the offer from the cart. Are you sure?',
  this_action_will_remove_the_product_from_the_cart: 'This action will remove the product from the cart. Are you sure?',
  offer_cost_is_excluded: '*The offer cost is excluded',
  you_have_free_delivery: 'You have free delivery!',
  fall_short_one_for_free_delivery: 'Add _PRICE_TEXT more to reach free delivery.',
  fall_short_many_for_free_delivery: 'Add _PRICE_TEXT more to reach free delivery.',
  fall_short_many_for_delivery: 'Add _PRICE_TEXT more to reach for delivery with only _NEXT_FEE_TIER_TEXT.',
  plus_points: '_POINTS points',
  x_points: 'x_POINTS points',
  fall_short_one_for_min_delivery_price: 'Add _PRICE_TEXT more to reach the minimum order value!',
  fall_short_many_for_min_delivery_price: 'Add _PRICE_TEXT more to reach the minimum order value!',
  product_maximum_purchase_limit_reached: 'You have reached the maximum purchase limit of this product.',
  products_maximum_allowed_volume_limit_reached:
    'You have reached the maximum allowable product volume for Express delivery!',
  should_add_one_more_ingredient_for_order_proceed:
    'You need to add _INGREDIENT_NUM more ingredient to proceed with your order.',
  should_add_more_ingredients_for_order_proceed:
    'You need to add _INGREDIENT_NUM more ingredients to proceed with your order.',
  should_add_ingredients_in_products_for_order_proceed:
    'You need to add ingredients to the products products_names to proceed with your order',
  you_can_only_add_one_dfy_offer_per_order:
    'You can only add one DFY offer per order. If you want to continue adding another DFY offer, the one already in your cart will be automatically removed.',
  cart_: 'Cart',
  delivery_in_area: 'Delivery in the area',
  shop_will_give_you_all_info:
    'The store will provide you with all the information about the food and its preparation.',
  if_you_have_allergies_contact_with_shop:
    'If you have allergies or other dietary restrictions and want to be sure before you BOX, contact the store at',
  reviews_counter: 'Reviews (_REVIEWS_COUNTER)',
  delivery_fee_colon: 'Delivery fee:',
  afm_colon: 'VAT Number:',
  gemh_colon: 'GEMI:',
  want_: 'I want...',
  in_the_mood_for: 'I crave...',
  with_brew: '…with variety…',
  and_I_want_it_to_have: '…and I want it to have…',
  shop_address: 'Store address:',
  company_headquarters: 'Business Address:',
  company_name: 'Business name',
  company_name_colon: 'Business name:',
  delivery_method_in_time: '_DELIVERY_METHOD in _TIME',
  delivery_in_time: 'Delivery in _TIME',
  take_away_in_time: 'Take away in _TIME',
  takeaway_: 'Takeaway',
  the_takeaway_choice_from: 'The takeaway option from',
  the_shop_is_unavailable: 'the store is not available',
  all_: 'All',
  earn_: 'Win',
  last_redemption: 'Last redemption:',
  you_have_a_balance: 'You have a balance:',
  search_category_or_product: 'Search category or product...',
  search_product_or_ingredient: 'Search product or ingredient...',
  includes_fee: 'Includes fee',
  you_have_chosen: 'You have selected:',
  order_from_shop_and_give_your_reviews: 'Order from the store so you can give your own reviews and earn points!',
  you_can_see_more_info: 'You can see more information',
  we_provide_this_offer_in_partnership_with_dfy:
    'We offer this promotion in collaboration with DEALS for YOU. At the completion of your order, you will be asked for a DEALS for YOU code.',
  learn_how_to_win_more: 'Learn here how to earn more',
  until_now_you_have_won: 'So far you have earned',
  the_points_capital: 'points',
  the_shop_does_not_serve_this_address:
    "The store does not serve this address, don't worry, you can find many more options in your area.",
  the_shop_cannot_serve_you_due_to_unavailable_time:
    'Due to unavailable time, the store cannot serve you. You can order from another store.',
  for_the_date: 'for _DATE',
  you_can_add_only_one_dfy_offer_per_order: 'You can add only one "DEALS For YOU" offer per order.',
  register_login: 'Sign Up/Log In',
  your_changes_have_been_saved: 'Your changes have been saved.',
  are_you_sure_you_want_to_remove_your_card: 'Are you sure you want to remove your card?',
  reenter_it_the_next_time_you_wish_to_order_from_a_store:
    'You will need to enter it again the next time you wish to order from store _SHOP_NAME',
  'no_stores_were_found_for_this particular_chain_at_your_address':
    'No stores found for the specific chain at your address.',
  product_search: 'Search product…',
  no_delivery_times_available_from_this_store_at_the_moment:
    'There are currently no available delivery times from this store.',
  selected_time_for_scheduling_an_order_is_unavailable:
    'The time you selected for the scheduling the order is not available from the store.',
  you_can_choose_another_delivery_time: 'You can choose a different delivery time or continue to the menu.',
  you_can_find_other_shops_and_order_from_box:
    'However, you can find other stores, order through BOX and continue to benefit!',
  are_you_sure_your_address_doesnt_include_a_number:
    "Are you sure your address doesn't include a number? You can add delivery instructions in the comments of your order.",
  and_up_to_x_additional_ingredients: '(And up to _MAX_ITEMS additional ingredients)',
  the_x_free: '(the _FREE_CHOICES for free)',
  the_x_free_many: '(the _FREE_CHOICES for free)',
  and_up_to_x_additional_ingredients_the_x_free:
    '(And up to _MAX_ITEMS additional ingredients, the _FREE_CHOICES for free)',
  and_up_to_x_additional_ingredients_the_x_free_many:
    '(And up to _MAX_ITEMS additional ingredients, the _FREE_CHOICES for free)',
  up_to_x_time_per_order: 'Up to _MAX_ITEMS_PER_ORDER time per order',
  up_to_x_times_per_order: 'Up to _MAX_ITEMS_PER_ORDER times per order',
  with_minimum_order_price: 'With a minimum order of _PRICE',
  above_min_price_free_delivery: '‎• Over _LAST_TIER_MIN_PRICE_TEXT: Free delivery',
  above_min_price_delivery: '‎• Over _LAST_TIER_MIN_PRICE_TEXT: Delivery _LAST_TIER_FEE',
  below_above_limit_delivery_fee: '‎• _BOTTOM_LIMIT_TEXT - _UPPER_LIMIT_TEXT: Delivery _FEE_TEXT',
  below_limit_delivery_fee: '‎• Below _UPPER_LIMIT_TEXT: Delivery _FEE_TEXT',
  the_final_delivery_cost_depends_on_your_cart_amount:
    'The final delivery cost (_DEL_FEES_TEXT_SUM) depends on your cart amount:',
  contact_telephone: 'Contact phone',
  type_a_contact_telephone: 'Type a contact number',
  the_characters: 'The characters',
  are_invalid: 'are not valid',
  must_select_payment_with_card_for_points_redemption: 'You must choose card payment to redeem your points.',
  points_redemption_is_not_combined_with_the_selected_coupon:
    'Points redemption cannot be combined with the coupon already selected.',
  if_you_prefer_points_redemption_remove_coupon: 'If you prefer points redemption, you will need to remove the coupon.',
  connect_your_card_with_the_box: 'You have _CARD_NAME. Link it with BOX and enjoy all the benefits!',
  redeem_points_and_earn_discount: 'Redeem _POINTS points _CARD_NAME and get a _PRICE_CURRENCY discount!',
  redeeming_points_and_earning_discount: 'You redeem _POINTS points _CARD_NAME and get a _PRICE_CURRENCY discount!',
  a_previous_requested_order_has_not_yet_been_completed:
    'A previous order for which you have requested point redemption has not yet been delivered. You can redeem your points for a new order after the previous one is delivered.',
  and_you_win: 'and you win',
  you_have_a_discount: 'You have a discount!',
  to_get_the_discount_we_set_payment_by_card_as_the_payment_method:
    'To get the discount, we have set card payment as the payment method and pre-filled the discount coupon.',
  box_points_and: 'BOX points &',
  points_for_your_order: 'points for your order!',
  with_: 'with',
  pay_online_with_your_card: 'Pay online with your card!',
  choose_the_amount_you_wish_to_donate:
    'Choose the amount you wish to donate from 0.5_CURRENCY to 20_CURRENCY and it will be added to the final price of your order.',
  unfortunately_the_store_does_not_serve_this_address:
    'Unfortunately, the store does not serve this specific address. You can choose a serviceable address or delete this order and start a new one.',
  invoice_issue: 'Issue Invoice',
  bag_quantity_env_fee: '_BAGS_QUANTITY bag (environmental fee _PRICE_TEXT_CURRENCY / bag)',
  bags_quantity_env_fee: '_BAGS_QUANTITY bags (environmental fee _PRICE_TEXT_CURRENCY / bag)',
  points_and_dont_worry_about_carrying_cash_anymore: 'points and never worry about having cash on you again!',
  card_addition: 'Add card',
  you_must_select_payment_by_card_to_complete_a_discount_coupon:
    'You must choose to pay by card to complete a discount coupon.',
  do_not_forget: "Don't forget!",
  please_type_your_code_in_coupon_field: 'Please enter your code in the "Coupon" field',
  you_must_enter_a_valid_dfy_code:
    'To get the DEALS For YOU offer added to your products, you must enter a valid DEALS For YOU code.',
  coupon_discount_will_be_removed_from_your_cart: 'By clicking OK, the coupon discount will be removed from your cart.',
  redeem_your_coupon_box_or_other: 'Redeem your coupon, BOX or other!',
  redeem_your_coupon_dfy_or_other: 'Redeem your coupon, Deals for You or other!',
  the_new_final_price_of_your_order_is:
    'Prices for some products in your cart have been updated. The new final price of your order is _PRICE_TEXT.',
  payment_by_card_is_required_for_takeaway_orders:
    'For take away orders, card payment is required, so we have automatically changed your payment method',
  min_order_price_for_delivery_is: 'The minimum order price for delivery is _PRICE_TEXT.',
  min_order_price_for_takeaway_is: 'The minimum order price for store pickup is _PRICE_TEXT.',
  your_cart_is_not_sufficient_to_continue_with_a_delivery:
    'Your cart is not sufficient to proceed with delivery. Add more products from the store or choose store pickup.',
  your_cart_is_not_sufficient_to_continue_with_a_takeaway:
    'Your cart is not sufficient to proceed with store pickup. Add more products from the store or choose delivery.',
  missing_information_from_your_address: 'Missing details from your address',
  add_new_card_with_save_in_next_step: 'Add a new card with\nsaving, in the next step',
  add_new_card_without_save_in_next_step: 'Add a new card without\nsaving, in the next step',
  the_card_pan: 'The card _CARD_PAN',
  up_to_gb_for_your_mobile_phone: 'Up to _GB_REWARD_NUMBERGB for your mobile',
  your_available_points_balance_is_not_sufficient:
    'Your available points balance is not sufficient to choose this amount for redemption.',
  your_cart_must_have_at_least_wanted_price:
    'To get a _MAX_POINTS_CURRENCY discount, your cart must be at least _WANTED_PRICE_CURRENCY.',
  order_tomorrow_from_to_time_and_earn:
    'You can order tomorrow between _TIME_FROM and _TIME_TO and earn _MULTIPLIER times the points!',
  you_have_already_earned_6x_points_from_your_first_order_in_the_shop:
    'You have already earned 6x points from your first order at the store.',
  earn_discount_benefits: 'You earn a discount of _BENEFITS_TEXT',
  redeem_coupon_and_earn_benefits: 'Redeem the coupon _COUPON_CODE and earn _BENEFITS_TEXT!',
  from_: 'from',
  you_earn_lowercase: 'You win',
  you_earn: 'You win',
  points_for: 'points for',
  your_order_from_the_shop: 'your order from the store',
  your_order_from: 'your order from',
  the_shop: 'the store',
  card_confirmation: 'Confirm card',
  please_fill_in_your_missing_credit_or_debit_card_details:
    'Please fill in the missing details of your credit or debit card.',
  choose_address: 'Choose address',
  delete_confirmation: 'Confirm deletion',
  floor_text: 'Floor: _FLOOR',
  name_at_bell_text: 'Bell: _NAME_AT_BELL',
  the_bank_could_not_process_the_payment_request:
    "The bank could not process the payment request. But don't worry, if you used points, they were not redeemed, and if you used a coupon, it will be available again for use in 30 minutes.",
  which_products_would_you_recommend_to_your_friends: 'Which products would you recommend to your friends?',
  which_dish_would_you_recommend_to_your_friends: 'Which dish would you recommend to your friends?',
  box_is_spreading:
    'BOX is expanding! We will be able to serve your area very soon! Choose another address or add a new one to see stores that serve you.',
  your_card: 'Your _CARD_NAME',
  found_x_offers: 'We found _OFFERS_COUNT offers',
  found_x_shops: 'We found _SHOPS_COUNT stores',
  found_x_items: 'We found _ITEMS_COUNT products',
  we_found_your_card: 'We found your _CARD_NAME! You can use it for your order at BOX to:',
  your_card_is_ready: 'Your _CARD_NAME is ready! You can use it for your order at BOX to:',
  alternatively_: 'Alternatively',
  type_your_email_for_connecting_your_card: 'Enter your Email to link it with BOX and enjoy all the benefits!',
  type_your_card_number_for_connecting_your_card: 'Enter its number to link it with BOX and enjoy all the benefits!',
  you_already_have_a_shop_card: 'Do you already have a _SHOP_NAME card?',
  collect_and_redeem_points_from_supermarkets: 'You collect & redeem points from _SHOP_NAME supermarkets.',
  you_can_also_use_it_in_physical_supermarkets: 'You use it in _SHOP_NAME physical supermarkets.',
  we_will_create_a_new_card_for_you_to: 'We will create a new card for you, to:',
  you_can_also_use_it_in_the_online_supermarkets: 'You use it in _SHOP_NAME online supermarkets.',
  dont_worry_you_can_complete_your_order_normally:
    "Don't have a _SHOP_NAME card? Don’t worry, you can complete your order as usual!",
  if_you_get_a_card_in_the_future:
    'If you get a card in the future, you can enter it later to enjoy all the benefits and discounts from _SHOP_NAME supermarkets.',
  enter_the_mobile_phone_and_create_new_card:
    'Enter the mobile phone number with which you want your new _SHOP_NAME _CARD_NAME to be created.',
  enter_the_email_and_create_new_card:
    'Enter the email with which you want your new _SHOP_NAME _CARD_NAME to be created.',
  redeem_your_card_points_and_get_discounts:
    'Redeem your _CARD_NAME points and earn discounts on your orders at _SHOP_NAME supermarkets!',
  collect_points_from_your_orders_in_supermarkets: 'Collect points from your orders at _SHOP_NAME supermarkets!',
  we_have_sent_you_a_code_to_email:
    'We have sent you a code to <strong>_EMAIL</strong>, please enter this code in the field below',
  we_have_sent_you_a_code_with_sms:
    'We have sent you a code via SMS to <strong>_PHONE_NUMBER</strong>, please enter this code in the field below.',
  for_any_issue_regarding_the_confirmation_of_your_card:
    'For any issues related to your card verification, you can contact _SHOP_NAME supermarkets at _SHOP_NUMBER.',
  type_the_pin_code: "Enter your card's PIN code in the field below.",
  didnt_you_get_the_text: 'Didn’t receive the SMS?',
  forgot_your_password: 'Forgot your password?',
  didnt_you_get_the_email: 'Didn’t receive the email?',
  up_to_max_ingredients_free: 'Up to _MAX_ITEMS ingredients, _FREE_CHOICES for free',
  up_to_max_ingredients_free_many: 'Up to _MAX_ITEMS ingredients, _FREE_CHOICES for free',
  up_to_max_ingredients: 'Up to _MAX_ITEMS ingredients',
  free_choices: '_FREE_CHOICES for free',
  free_choices_many: '_FREE_CHOICES for free',
  you_have_exceeded_the_max_ingredients_number: 'You have exceeded the maximum number of ingredients (_MAX_ITEMS).',
  you_have_exceeded_the_max_ingredients_category_number:
    'You have exceeded the maximum number of ingredients (_MAX_ITEMS) in this category.',
  you_first_have_to_remove_some_of_the_already_selected:
    'To add this ingredients, you must first remove one of the already selected items.',
  you_will_need_to_enable_location_sharing: 'You need to enable location sharing in your browser settings.',
  we_havent_been_able_to_get_your_location_try_again: 'We couldn’t find your location, please try again.',
  your_browser_cannot_access_your_location: 'Your browser does not have access to your location.',
  something_went_wrong_when_connecting_to_your_cosmote_id:
    'Something went wrong while connecting to your Cosmote ID. Please try again later.',
  by_date: 'Until _DATE',
  expired_at_date: 'Expired on _DATE',
  redeemed_at_date: 'Redeemed on _DATE',
  choose_replacement_method: 'Choose replacement method',
  when_a_product_you_have_ordered_is_not_available: 'When a product you have ordered is not available, do you prefer:',
  we_have_sent_you_a_code_by_email_to: 'We’ve sent you a code via email to _EMAIL, please enter this code here.',
  with_the_discount_coupon_the_new_price_of_your_cart_is:
    'With the discount coupon, the new price of your cart is _CART_PRICE.',
  redeem_dfy_code_and_get_the_pizza_fan_offer: 'Redeem a Deals for You code and get the Pizza Fan offer!',
  redeem_the_code_and_get_the_pizza_fan_offer: 'You redeem the code _COUPON_CODE and get the Pizza Fan offer!',
  to_get_discount_your_cart_must_be_at_least:
    'To get a _COUPON_DISCOUNT discount, your cart must be at least _MINIMUM_PRICE.',
  order_tracking: 'Order tracking',
  for_every_points_you_redeem_you_get_one_euro_discount:
    'For every _POINTS points you redeem, you earn 1_CURRENCY off for every _EURO_REDEMPTION_RATE_CURRENCY ordered',
  the_minimum_order_value_is: 'The minimum order value is',
  dont_worry_you_have_chosen_the_order:
    'But don’t worry! Because you’ve chosen our <strong>exclusive offer</strong>, you can send your order as usual!',
  dfy_offers_are_available_for_all_cosmote_customers:
    'Deals for You offers are available to all COSMOTE mobile, fixed line, and COSMOTE TV customers.',
  you_can_take_your_code_from_my_cosmote_app:
    "You can get your code from the MY COSMOTE APP, WHAT'S UP APP, or by sending an empty SMS to 19019 (charge 0.20_CURRENCY, VAT included).",
  dfy_offer: 'Deals For You Offer',
  please_choose_a_payment_method: 'Please select a payment method.',
  your_password_was_copied_to_the_clipboard: 'Your code has been copied to the clipboard.',
  your_new_card_has_been_created: 'Your new _CARD_NAME has been created. You can view it',
  '30_percent_discount_has_expired': 'The 30% discount has expired, stay tuned for new discounts!',
  search_shop: 'Search store',
  card_number_trimmed: 'Card Number:',
  box_points: 'BOX points',
  box_coupons: 'BOX coupons',
  gigabyte_packs: 'Gigabyte bundles',
  add_your_card_when_sending_your_order_and_earn:
    'Add your card when sending your order and earn +_CLAIMABLE_POINTS points.',
  claimable_points_html_template: '<span class="rewards-card-highlighted">+_CLAIMABLE_POINTS points</span>',
  earned_points_html_template: 'You earned <span class="rewards-card-highlighted">_EARNED_POINTS points</span>',
  points_for_every_order_html_template:
    '<span class="rewards-card-highlighted">x_MULTIPLIER points</span> for every order!',
  order_something_new: 'Order something new',
  earned_from_box_html_template:
    'You earned <span class="rewards-card-highlighted">+_POINTS points</span> from the BOX',
  earned_from_friends_html_template:
    'You earned <span class="rewards-card-highlighted">_EARNED_EUROS</span> from _REFERRALS friends.',
  for_every_friend_html_template: '<span class="rewards-card-highlighted">_BENEFIT</span> for each friend!',
  the_more_you_order_the_more_you_earn:
    'The more you order, the more you earn! Place orders and earn points for discounts and GB',
  earned_from_explore_many_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT Explore orders.',
  earned_from_explore_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT Explore order.',
  earned_from_happy_hour_many_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT Happy Hour orders.',
  earned_from_happy_hour_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT Happy Hour order.',
  earned_from_orders_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT orders.',
  earned_from_order_html_template:
    'You have earned <span class="rewards-card-highlighted">_AMOUNT</span> from _COUNT order.',
  user_points_for_orders: '_USER_POINTS_CURRENCY for orders.',
  at_: 'at',
  the_options_we_recommend_from_the_shop: 'The options we recommend from _SHOP_NAME are: _DISHES.',
  shop_rating_in_location: 'The store _SHOP_NAME in the area _LOCATION was rated _RATING stars. What do you think?',
  x_hours: '_HOURS hours',
  x_hour: '_HOURS hour',
  x_minutes: '_MINUTES minutes',
  redeem_points_and_earn_up_to_benefits_discount: 'Redeem points and get up to _BENEFITS_TEXT discount.',
  min_order_price_is_needed_to_obtain_the_offer: '_MIN_ORDER_PRICE is required to get the offer.',
  you_will_continue_your_order_without_the_offer:
    'You will continue your order without the offer _OFFER_NAME because you did not meet the minimum order requirement, shall we proceed?',
  you_will_continue_your_order_without_the_offers:
    'You will continue your order without the offers _OFFERS_NAME because you did not meet the minimum order requirement, shall we proceed?',
  until_date: 'Until _DATE',
  terms_of_use_anchor: '<a href="_TERMS_URL" target="_blank" rel="noopener noreferrer">terms of use</a>',
  i_agree_with_terms_of_use_html_template:
    'I agree to the _TERMS_ANCHOR of _SHORT_CARD_NAME and wish for my information to be transmitted to Supermarkets _SM_NAME for the recording of points and the application of discounts that I may be entitled to.',
  i_wish_to_keep_the_privilege_card_saved:
    'I wish for the _SHORT_CARD_NAME rewards card to remain saved on the BOX platform for future orders.',
  i_agree_to_sent_my_details_to_supermarkets:
    'I agree to the _TERMS_ANCHOR and for my details to be sent to Supermarkets _SM_NAME for the completion of my order.',
  processing_of_personal_data_anchor:
    '<a href="_TERMS_URL" target="_blank" rel="noopener noreferrer">processing of personal data</a>',
  i_have_been_informed_about_processing_of_my_personal_data:
    'I have been informed about my _DATA_TERMS_ANCHOR by the Store regarding the issuance and use of the _SHORT_CARD_NAME. I have been informed and agree to the _TERMS_ANCHOR of _SHORT_CARD_NAME.',
  i_wish_to_provide_and_transmit_my_data_to_supermarkets:
    'I wish for my details to be provided and transmitted to Supermarkets _SM_NAME for the creation of my new card.',
  seo_default_description:
    'Order food online from BOX. Shop online. Collect points from your order and redeem them. Are you ready to BOX?',
  seo_default_keywords:
    'online delivery app, online order app, delivery application, online food order, delivery offers',
  redeem_points_and_earn_discount_2: 'Redeem _POINTS points and get _DISCOUNT discount!',
  and_earn_x_discount: 'and you earn a _DISCOUNT_CURRENCY discount!',
  personal_data: 'Personal data',
  terms_and_conditions_of_use: 'Terms and conditions of use',
  privacy_update: 'Personal data protection notice',
  trackers_management: 'Manage trackers',
  and_get_a_discount: 'and earn a discount!',
  cookies_info: 'Information about cookies',
  cookies_info_description:
    'We use cookies on our website for its proper functioning, to provide personalized features, to improve and tailor advertising and other content to reflect your specific needs and interests. We also use cookies to gather statistical information that allows us to understand how users use our website and help improve its structure and content. Recipients of the data resulting from the use of cookies may also include companies managing the effectiveness of advertising campaigns to serve this purpose.',
  necessary_cookies: 'Necessary and Functionality Cookies',
  necessary_cookies_description:
    "These cookies are technically necessary to connect to the website or to provide the internet service you have requested. This category includes cookies used to identify or maintain content that the subscriber or user inputs during a connection (session) to a website throughout the duration of that connection, such as the \"shopping cart,\" for the subscriber's or user's connection to services requiring authentication, for user security, for performing technical load distribution (load balancing) on a website connection, for resolving technical errors of the website, for maintaining the user's choices regarding the presentation of the website, e.g., language selection, search history storage, etc., as well as cookies activated to enable the chat option provided through a website. These cookies cannot be disabled. If you set your browser to block these cookies, the website or certain parts of it will not function.",
  performance_cookies: 'Performance Cookies',
  performance_cookies_description:
    'These cookies collect information about how visitors use the website, for example, how many users visited it, which pages of the site they visit most often, etc. These cookies collect aggregate information that does not directly identify any visitor. They are used exclusively to improve the functionality of a website. For this purpose, we use Google Analytics and Hotjar mechanisms.',
  advertising_cookies: 'Advertising Targeting Cookies',
  advertising_cookies_description:
    'These cookies are used to provide content that is more relevant to you and your interests. They can be used to send targeted advertising/offers, limit ad views, or measure the effectiveness of an advertising campaign. They can also be used to remember through which website you learned about and visited our site, to determine which online marketing channels are most effective. These cookies recognize you with technical data such as your IP address, browser, and device type. For this purpose, we use Facebook Pixel mechanisms.',
  customer_experience_cookies: 'Customer Experience Optimization Cookies',
  customer_experience_cookies_description:
    'These cookies allow the website to remember the information you have entered in specific fields (e.g., address, taste preferences, last payment method) to facilitate immediate search results and order food. Thus, you will not need to re-enter these specific entries, which will appear pre-filled during further - but also future - navigation of the site, improving your experience.',
  delete_cookies: 'How can I delete cookies?',
  delete_cookies_description_1:
    'You can delete cookies and disable their use. Select the browser you use and follow the instructions:',
  delete_cookies_description_2: "If you use another browser, consult the provider's information.",
  tracker_: 'Tracker',
  duration_: 'Duration',
  data_controller: 'Data Controller',
  recipients_: 'Recipients',
  participations_: 'Participations',
  delivery_order_online: 'Order Online',
  view_not_available_coupons: 'View not available coupons',
  coupon_code_invalid_chars_message: 'The code you entered contains invalid characters: INVALID_CHARS.',
  comment_has_invalid_chars_message: 'The comment contains invalid characters: INVALID_CHARS.',
  always_: 'Always',
  see_the_video: 'Watch the video',
  eat_and_win_with_box: 'With BOX, you eat and win.',
  yes_we_are_not_exaggerating: 'Yes. Without exaggeration.',
  because_with_every_order_points_coming:
    'Because with every order, \nyou keep earning <b>points</b>, \nwhich mean <b>€ for discounts</b>\nwhenever you get hungry!',
  delivery_suspended_due_to_extraordinary_circumstances: 'Delivery suspended due to extraordinary circumstances',
  not_accepting_orders_currently: 'Not accepting orders currently',
  outside_working_hours: 'Outside working hours',
  other_stores: 'Other stores (_SHOP_NAME)',
  found_one_shop: 'We found 1 store',
  see_the_stores_by_variety: 'See the stores by variety',
  delivery_currently_not_possible: 'At the moment, delivery from this store is not possible',
  order_from_store_to_rate: 'Order from the store so you can give your own reviews!',
  applicable_laws_compliance:
    'The company is committed to offering only products or services that comply with the rules of EU law.',
  cookies_and_similar_technologies: 'Cookies and similar technologies',
  settings_for_cookies_and_similar_technologies: 'Settings for cookies and similar technologies',
  accept_all: 'Accept All',
  only_required: 'Only Required',
  only_selected: 'Only Selected',
  on_: 'On',
  off_: 'Off',
  always_on: 'Always on',
  read_more: 'Read more',
  privacy_policy_inform: 'Privacy Policy',
  partner_list: 'Partner List (_PARTNERS_NUMBER)',
  partner_list_title: 'List of partners-acceptors and trackers used on the Website',
  redeemed_: 'Redeemed',
  day_shorthand: 'd',
  hour_shorthand: 'h',
  your_coupon_has_expired: 'Your coupon has expired',
  in_shop: 'In SHOP_NAME',
  the_review_will_be_displayed_publicly:
    'The review will be displayed publicly along with the user’s details (first name and last name’s initial letter).',
  delivery_details: 'Delivery details',
  documents_: 'Documents',
  phone_: 'Phone',
  gaty_number: 'General Authorization No 95-001/2024 for the provision of Postal Services',
  full_name: 'Full name',
  gemh_number: 'GEMI Number (Business Registration Number)',
  brand_name: 'Name',
  creation_date: 'Creation date',
  print_: 'Print',
  select_document: 'Select documents',
  order_again: 'Order again',
  for_your_order: 'For your order!',
  for_your_order_on: 'For your order on:',
  shipped_at_your_door_at: 'Delivered to your door on:',
  the_order: 'Order',
  administrator_: 'Administrator',
  recipient_: 'Recipient',
  store_: 'Store',
  document_type_uppercase: 'DOCUMENT TYPE',
  delivery_details_uppercase: 'DELIVERY DETAILS',
  the_order_uppercase: 'ORDER',
  code_uppercase: 'CODE',
  creation_date_uppercase: 'CREATION DATE',
  recipient_uppercase: 'RECIPIENT',
  full_name_uppercase: 'FULL NAME',
  address_uppercase: 'ADDRESS',
  phone_uppercase: 'PHONE',
  store_uppercase: 'STORE',
  brand_name_uppercase: 'NAME',
  gemh_number_uppercase: 'BUSINESS REGISTRATION NUMBER',
  organism_title_uppercase: 'HELLENIC TELECOMMUNICATIONS ORGANIZATION S.A.',
  gaty_number_uppercase: 'GENERAL AUTHORIZATION No 95-001/2024 FOR THE PROVISION OF POSTAL SERVICES',
  updated_history: 'Updated history',
  by_clicking_on_an_order_you_can_see_all_its_details: 'By clicking on an order, you can view all its details.',
  delivery_suspended_temporarily: 'Delivery suspended temporarily',
  in_progress_it_will_be_at_your_door_at_about: 'In progress, it will be at your door at about:',
  scheduled_for: 'Scheduled for:',
  gaty_number_compact: 'General Authorization No 95-001/2024 for the provision of Postal Services',
  terms_and_conditions_for_the_provision_of_postal_services:
    'Terms and Conditions for the provision of Postal Services',
  charter_of_obligations_towards_consumers: 'Charter of Obligations towards Consumers',
  policy_against_misuse: 'Policy against Misuse',
  complaint_handling_policy: 'Complaint Handling Policy',
  box_terms_of_use: 'BOX Terms of Use',
  terms_and_policies: 'Terms & Policies',
  transparency_report: 'Transparency report',
  transparency_report_uppercase: 'TRANSPARENCY REPORT',
  you_can_view_the_transparency_report: 'You can view the 2024 Transparency report'
};
