import { CommonModule, registerLocaleData, DecimalPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { LOCALE_ID, NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';
import localeEl from '@angular/common/locales/el';
import localeElExtra from '@angular/common/locales/extra/el';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { SkeletonWidgetModule } from '@box-skeleton-widget/skeleton-widget.module';
import { ContestWidgetModule } from '@box-contest-widget/contest-widget.module';
import { RewardsWidgetModule } from '@box-rewards-widget/rewards-widget.module';
import { RatingWidgetModule } from '@box-rating-widget/rating-widget.module';
import { OrdersWidgetModule } from '@box-orders-widget/orders-widget.module';
import { ShopsWidgetModule } from '@box-shops-widget/shops-widget.module';
import { SharedModule } from '@box-shared/shared.module';
import { CouponWidgetModule } from '@box-coupon-widget/coupon-widget.module';
import { CartWidgetModule } from '@box-cart-widget/cart-widget.module';
import { FoodWidgetModule } from '@box-food-widget/food-widget.module';
import { MarketWidgetModule } from '@box-market-widget/market-widget.module';
import { DiscoverWidgetModule } from '@box-discover-widget/discover-widget.module';

import * as Interceptors from './interceptors';
import * as Providers from './providers';
import * as Components from './components';
import * as Pages from './pages';
import * as Guards from '../delivery/guards';
import * as DeliveryServices from '../delivery/services';
import * as CoreServices from './services';
import * as DiscoverServices from '../discover/services';
import * as Resolvers from '../delivery/resolvers';
import * as DeliveryComponents from '../delivery/components';
import * as DeliveryPages from '../delivery/pages';
import * as DiscoverComponents from '../discover/components';
import * as DiscoverPages from '../discover/pages';

@NgModule({
  declarations: [
    // Existing Core Components
    Components.BoxShellComponent,
    Components.BoxHeaderComponent,
    Components.BoxHeaderDeliveryDetailsComponent,
    Components.BoxNavigationMenuComponent,
    Components.BoxCosmoteIdButtonComponent,
    Components.PromoBannerComponent,
    Components.PromoBannerDetailsDialogComponent,
    Components.PromoBannersComponent,
    Components.HomeSectionInfoDialogComponent,
    Components.HomeShopsSectionComponent,
    Components.ContestsSectionComponent,
    Components.BoxMaintenanceDialogComponent,
    Components.GBPromoDialogComponent,
    Components.GDPRConsentDialogComponent,
    Components.CollectionCampaignsDialogComponent,
    Components.OnBoardingDialogComponent,
    Components.HomeDiscoverFiltersComponent,
    Components.HomeDiscoverFilterComponent,
    Components.HomeCuisinesComponent,
    Components.HomeSearchComponent,
    Components.HomeSearchResultsComponent,
    Components.NotificationComponent,
    Components.NotificationsComponent,
    Components.BreadcrumbComponent,
    Components.WhatsNewDialogComponent,
    Components.HomeBrandsComponent,
    Components.HomeCouponsSectionComponent,

    // Existing Core Pages
    Pages.FourOhFourPage,
    Pages.HomePage,
    Pages.DownloadPage,
    // Merged Discover Pages
    DiscoverPages.DiscoverPage,
    DiscoverPages.DiscoverSearchPage,

    // Merged Delivery Pages
    DeliveryPages.LocationsPage,
    DeliveryPages.LocationCuisinePage,
    DeliveryPages.CuisinePage,
    DeliveryPages.ShopMenuPage,
    DeliveryPages.ShopPage,
    DeliveryPages.PromoPage,

    //Merged Discover Components
    DiscoverComponents.DiscoverFilterComponent,
    DiscoverComponents.DiscoverFiltersComponent,
    DiscoverComponents.DiscoverShopsComponent,
    DiscoverComponents.DiscoverActiveFiltersComponent,
    DiscoverComponents.DiscoverSearchInputComponent,
    DiscoverComponents.DiscoverShellComponent,
    DiscoverComponents.DiscoverFoodCuisinesComponent,
    DiscoverComponents.DiscoverGroceriesCuisinesComponent,
    DiscoverComponents.DiscoverSearchNavigationComponent,
    DiscoverComponents.DiscoverSearchResultItemsComponent,
    DiscoverComponents.DiscoverSearchResultItemComponent,
    DiscoverComponents.DiscoverShopResultItemsComponent,
    DiscoverComponents.DiscoverBrandsComponent,
    DiscoverComponents.DiscoverShopsFiltersDialogComponent,

    // Merged Delivery Components
    DeliveryComponents.DeliveryAnchorsComponent,
    DeliveryComponents.DeliveryCuisineHeroComponent,
    DeliveryComponents.SameMYODialogComponent,
    DeliveryComponents.ShopMenuNavComponent,
    DeliveryComponents.ShopMenuNavItemComponent,
    DeliveryComponents.ShopMenuNavMobileComponent,
    DeliveryComponents.ShopMenuNavBottomSheetComponent,
    DeliveryComponents.ShopMenuNavBottomSheetItemComponent,
    DeliveryComponents.ShopMenuSearchComponent,
    DeliveryComponents.ShopBoxHourBannerComponent,
    DeliveryComponents.ShopSuggestionBannerComponent,
    DeliveryComponents.ShopRemindersComponent,
    DeliveryComponents.ShopSuggestionDialogComponent,
    DeliveryComponents.ShopSuggestionBannerInfoDialogComponent,
    DeliveryComponents.ShopMenuItemsComponent,
    DeliveryComponents.CategoriesMenuItemsComponent,
    DeliveryComponents.ShopExtraInfoComponent,
    DeliveryComponents.ShopDescriptionComponent,
    DeliveryComponents.ShopPageInfoComponent,
    DeliveryComponents.ShopPageDeliveryMethodComponent,
    DeliveryComponents.ShopPageDetailsComponent,
    DeliveryComponents.ShopPageDetailsDialogComponent,
    DeliveryComponents.ShopPageReviewsComponent,
    DeliveryComponents.ProductMYOComponent,
    DeliveryComponents.ProductMYODialogComponent,
    DeliveryComponents.ProductMYOReviewsComponent,
    DeliveryComponents.ProductMYOSelectionsComponent,
    DeliveryComponents.ProductMYOSliderComponent,
    DeliveryComponents.ProductMYOChoiceComponent,
    DeliveryComponents.ProductMYODetailsComponent,
    DeliveryComponents.OfferWizardComponent,
    DeliveryComponents.OfferWizardDialogComponent,
    DeliveryComponents.OfferWizardDetailsComponent,
    DeliveryComponents.OfferWizardDFYDialogComponent,
    DeliveryComponents.OfferWizardGroupsComponent,
    DeliveryComponents.OfferWizardProductComponent,
    DeliveryComponents.UnavailableItemsDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    PlatformModule,
    SkeletonWidgetModule,
    RatingWidgetModule,
    ContestWidgetModule,
    RewardsWidgetModule,
    ShopsWidgetModule,
    OrdersWidgetModule,
    CouponWidgetModule,
    CartWidgetModule,
    FoodWidgetModule,
    MarketWidgetModule,
    DiscoverWidgetModule
  ],
  exports: [
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    PlatformModule,

    // Core Components
    Components.BoxShellComponent,
    Components.BoxHeaderComponent,
    Components.BoxHeaderDeliveryDetailsComponent,
    Components.BoxNavigationMenuComponent,
    Components.BoxCosmoteIdButtonComponent,
    Components.PromoBannerComponent,
    Components.PromoBannerDetailsDialogComponent,
    Components.PromoBannersComponent,
    Components.HomeShopsSectionComponent,
    Components.ContestsSectionComponent,
    Components.BoxMaintenanceDialogComponent,
    Components.GBPromoDialogComponent,
    Components.CollectionCampaignsDialogComponent,
    Components.OnBoardingDialogComponent,
    Components.NotificationComponent,
    Components.NotificationsComponent,

    // Core Pages
    Pages.FourOhFourPage,
    Pages.HomePage,
    Pages.DownloadPage
  ],
  providers: [
    // Localization
    { provide: LOCALE_ID, useValue: 'el-GR' },

    // Interceptors
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { ...new MatDialogConfig(), autoFocus: false, restoreFocus: false }
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.CosmoteIDInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ErrorInterceptor, multi: true },

    // Core Services
    { provide: ErrorHandler, useClass: Providers.GlobalErrorHandler },

    // Angular Pipes
    DecimalPipe,
    CurrencyPipe,
    DatePipe,

    // Merged Delivery Guards & Services
    Guards.LocationGuard,
    DeliveryServices.LocationsService,
    DeliveryServices.BrandsService,
    DeliveryServices.OffersService,
    DeliveryServices.ProductsService,
    DeliveryServices.ShopPageService,
    DeliveryServices.ShopMenuNavService,
    DeliveryServices.ShopMenuPageService,
    DeliveryServices.ShopMenuDialogService,
    DeliveryServices.ShopMenuAvailabilityService,
    CoreServices.CampaignEligibilityService,

    //Merged Discover Services
    DiscoverServices.DiscoverBrandsService,
    DiscoverServices.DiscoverSearchService,
    DiscoverServices.DiscoverCuisinesService,
    DiscoverServices.DiscoverBusinessVerticalService,
    DiscoverServices.DiscoverLoaderService,
    DiscoverServices.DiscoverShopsService,

    // Merged Delivery Resolvers
    Resolvers.CuisineResolver,
    Resolvers.ShopResolver,
    Resolvers.ChainMainShopResolver,
    Resolvers.PromoPageResolver
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeEl, 'el-GR', localeElExtra);
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the FoodModule only');
    }
  }
}
