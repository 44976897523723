import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SentryService, SEOService } from '@box-core/services';
import { LocationsService } from '../../services';
import { SeoOptions, Location, APIError } from '@box-types';
import { groupLocations, deleteJsonLd } from '@box/utils';
import { finalize, map } from 'rxjs/operators';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';

@Component({
  selector: 'locations-page',
  templateUrl: './locations.page.html',
  styleUrls: ['./locations.page.scss']
})
export class LocationsPage implements OnInit, OnDestroy {
  public loading: boolean;
  public groupedLocations: { [key: string]: Location[] };

  constructor(
    private router: Router,
    private seoService: SEOService,
    private sentryService: SentryService,
    private locationsService: LocationsService,
    private breadCrumbService: BreadCrumbService
  ) {}

  @HostBinding('class') public pageWrapper = 'page-wrapper';

  ngOnInit(): void {
    this.setMetaTags();
    this.initLocations();
    this.breadCrumbService.clearBreadcrumbs();
  }

  ngOnDestroy(): void {
    deleteJsonLd();
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: 'online_delivery_areas', url: this.router.url };
    this.seoService.setTags(options);
  }

  private initLocations(): void {
    this.loading = true;
    this.locationsService
      .fetchLocations()
      .pipe(
        finalize(() => (this.loading = false)),
        map((locations) => groupLocations(locations))
      )
      .subscribe({
        next: (groupedLocations) => (this.groupedLocations = groupedLocations),
        error: (error: APIError) =>
          this.sentryService.captureException(error, {
            domain: 'Delivery',
            domainDetails: 'Locations Page',
            severity: 'warning'
          })
      });
  }
}
