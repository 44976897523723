import { Injectable } from '@angular/core';
import {
  ConfigurationService,
  PrivacyConsentService,
  SentryService,
  SEOService,
  ShopsService,
  TimeslotsService,
  UserService
} from '@box-core/services';
import { Location, Cuisine, SeoOptions, Shop, Address, APIError, CollapsibleTile, Brand } from '@box-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  brandToCollapsibleTile,
  filterShopsByMainOrSecondaryCuisines,
  setRedirectPrerenderMetaElements,
  sortCuisineShops,
  sortShopsBasedOnOperatingState
} from '@box/utils';
import { BrandsService, LocationsService } from '../../services';
import orderBy from 'lodash-es/orderBy';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class CuisinePageService {
  private readonly defaultAddress: Address = { longitude: 23.7275388, latitude: 37.9838096, postalCode: '10560' };
  private readonly shopsSource: BehaviorSubject<Shop[]> = new BehaviorSubject<Shop[]>([]);
  private readonly checkedBrandSource = new BehaviorSubject<Brand>(null);
  public readonly checkedBrand$ = this.checkedBrandSource.asObservable();

  constructor(
    private router: Router,
    private seoService: SEOService,
    private userService: UserService,
    private shopsService: ShopsService,
    private sentryService: SentryService,
    private activatedRoute: ActivatedRoute,
    private timeslotsService: TimeslotsService,
    private locationsService: LocationsService,
    private privacyService: PrivacyConsentService,
    private languageService: LanguageService,
    private brandsService: BrandsService,
    private configService: ConfigurationService
  ) {}

  public getCuisine(): Cuisine {
    return this.activatedRoute.snapshot.data['cuisine'] as Cuisine;
  }

  public setCheckedBrand(brand: Brand): void {
    this.checkedBrandSource.next(brand);
  }

  public getCheckedBrand(): Brand {
    return this.checkedBrandSource.getValue();
  }

  public setShops(shops: Shop[]): void {
    this.shopsSource.next(shops);
  }

  public getShops(): Shop[] {
    return this.shopsSource.getValue();
  }

  public setCuisinePageMetaTags(cuisine: Cuisine): void {
    const t = this.languageService.getTextByKey.bind(this.languageService);
    const replacementDictionary = { _NAME: cuisine?.name };
    const options: SeoOptions = {
      title: t('seo_text_4', replacementDictionary),
      description: t('seo_text_1', replacementDictionary),
      keywords: t('seo_text_2', replacementDictionary),
      url: this.router.url
    };
    this.seoService.setTags(options);
  }

  public handleCuisinePageError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Cuisine Page',
      severity: 'warning'
    });
    return void this.router.navigate(['/delivery']);
  }

  public handleLocationsError(error: Error | APIError): void {
    this.sentryService.captureException(error, {
      domain: 'Delivery',
      domainDetails: 'Cuisine Page Locations',
      severity: 'warning'
    });
  }

  public fetchLocationsByPostalCode(address: Address): Observable<Location[]> {
    const postalCode = this.getNeighbouringLocationsPostalCode(address);
    return this.locationsService
      .fetchLocationsByPostalCode(postalCode)
      .pipe(map((locations) => orderBy(locations, 'priority', 'asc')));
  }

  public fetchCuisineShops(cuisine: Cuisine): Observable<Shop[]> {
    return this.shopsService.getShops$().pipe(
      map((shops) => {
        const filteredShops = filterShopsByMainOrSecondaryCuisines(shops, [cuisine]);
        const promotedChainTag = this.configService.getConfiguration()?.shops?.sortingTag;
        const timeslot = this.timeslotsService.getTimeslot();
        const sortingOptions = { cuisine, promotedChainTag, timeslot };
        const defaultSortedShops = sortCuisineShops(filteredShops, sortingOptions);
        return sortShopsBasedOnOperatingState(defaultSortedShops);
      })
    );
  }

  private getNeighbouringLocationsPostalCode(address: Address): string {
    if (!address) return this.defaultAddress.postalCode;
    const acceptedUX = this.privacyService.getPrivacyConsent()?.ux;
    const isGuest = this.userService.isGuest;
    if (!isGuest || acceptedUX) return address.postalCode;
    return this.defaultAddress.postalCode;
  }

  public handleEmptyStatePage(): void {
    setRedirectPrerenderMetaElements(window.location.origin + '/delivery');
    return void this.router.navigate(['/delivery']);
  }

  public generateTiles(cuisine: Cuisine): CollapsibleTile<Brand>[] {
    const cuisineBrands = this.brandsService.getBrandsForCuisine(cuisine);
    const sortedBrands = this.brandsService.decorateFilterAndSortBrands(cuisineBrands, this.getShops());
    return sortedBrands.map((brand) => brandToCollapsibleTile(brand));
  }

  public getFilteredShopsByBrand(): Shop[] {
    const activeBrand = this.getCheckedBrand();
    const shops = this.getShops();
    return this.brandsService.getFilteredShopsByBrand(activeBrand, shops);
  }
}
