<div class="food-item-left">
  <div class="food-item-header">
    <promo-badge *ngIf="item.badgeOptions" [options]="item.badgeOptions"></promo-badge>
    <div class="ods-typography-body-m-bold">{{ item.name }}</div>
  </div>
  <div *ngIf="item.description" class="food-item-description ods-typography-body-s-regular">{{ item.description }}</div>
  <product-description-badges
    *ngIf="item.tags || item.brand"
    [brand]="item.brand"
    [tags]="item.tags"
  ></product-description-badges>
  <div *ngIf="showPrice" class="food-item-price ods-typography-body-m-regular">
    {{ item.price | currencyPipe }}
  </div>
  <div *ngIf="showPrice && item.preOfferPrice > item.price" class="food-item-price ods-typography-body-m-regular">
    {{ item.preOfferPrice | currencyPipe }}
  </div>
</div>
<div class="food-item-right" [class.food-item-right-with-image]="item.thumbnail">
  <figure *ngIf="item.thumbnail" class="food-item-image">
    <img loading="lazy" [src]="item.thumbnail" [alt]="item.name" />
  </figure>
  <box-quantity (add)="onAdd()"></box-quantity>
</div>
