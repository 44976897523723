<div class="box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title | translate }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <collapsible-tile
    class="discover-tiles-collapsed-tile"
    *ngIf="activeTile"
    [tile]="activeTile"
    [collapsed]="true"
    (click)="onTileToggle(activeTile)"
  ></collapsible-tile>

  <div [swiper]="swiperOptions" class="discover-tiles-swiper" *ngIf="groups.length > 0 && !activeTile">
    <div class="swiper-wrapper discover-tiles-wrapper">
      <div
        class="swiper-slide discover-tiles-group-slide discover-tiles-group-slide-{{ size }}"
        *ngFor="let group of groups; trackBy: trackByGroup"
      >
        <collapsible-tile
          *ngFor="let tile of group.tiles; trackBy: trackByKey"
          visibilityObserver
          [triggerOnce]="true"
          [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
          (enteredViewport)="onTileEnteredViewport(tile)"
          class="collapsible-tile-size--{{ size }}"
          [tile]="tile"
          [size]="size"
          (click)="onTileToggle(tile)"
        ></collapsible-tile>
      </div>
    </div>
  </div>
</div>
