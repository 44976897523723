<div class="skeleton-shop-card-with-items">
  <div class="skeleton-shop-card">
    <div class="skeleton-shop-card-details">
      <div class="skeleton-shop-card-name"></div>
      <div class="skeleton-shop-card-categories-text"></div>
    </div>
    <div class="skeleton-shop-card-image"></div>
  </div>
  <div class="skeleton-shop-items">
    <div class="skeleton-shop-items-item"></div>
    <div class="skeleton-shop-items-item"></div>
    <div class="skeleton-shop-items-item"></div>
    <div class="skeleton-shop-items-button"></div>
  </div>
</div>
