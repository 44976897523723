import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'skeleton-cuisines',
  templateUrl: './skeleton-cuisines.component.html',
  styleUrls: ['./skeleton-cuisines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonCuisinesComponent {
  @HostBinding('class') public hostClass = 'skeleton-cuisines';
}
