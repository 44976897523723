import { Injectable } from '@angular/core';
import { BusinessVertical } from '@box-types';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DiscoverBusinessVerticalService {
  public businessVertical = new BehaviorSubject<BusinessVertical>('food');

  public setBusinessVertical(businessVertical: BusinessVertical): void {
    if (!businessVertical) return;
    this.businessVertical.next(businessVertical);
  }

  public getBusinessVertical(): BusinessVertical {
    return this.businessVertical.getValue();
  }

  public appendBusinessVerticalQueryString(queryParams: URLSearchParams): void {
    const vertical = this.getBusinessVertical();
    if (vertical) queryParams.set('vertical', vertical);
  }

  public getVerticalFromQueryString(queryParams: URLSearchParams): BusinessVertical {
    const value = queryParams.get('vertical');
    if (!this.isBusinessVerticalType(value)) return this.getBusinessVertical();
    return value as BusinessVertical;
  }

  private isBusinessVerticalType(value: string): boolean {
    return ['food', 'groceries'].includes(value);
  }
}
