import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { ShopService, AddressesService, ShopsService, CoreService, CartService } from '@box-core/services';
import { Shop, Chain } from '@box-types';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { ShopResolverData } from '@box-delivery/delivery.types';
import { normalizeShop, getShopCartMaxVolume, setPrerenderReady, decorateShopForChainView } from '@box/utils';
import { ShopMenuNavService } from '@box-delivery/services';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class ChainMainShopResolver implements Resolve<ShopResolverData> {
  private coreChain: Chain;

  constructor(
    private router: Router,
    private coreService: CoreService,
    private shopService: ShopService,
    private cartService: CartService,
    private shopsService: ShopsService,
    private addressesService: AddressesService,
    private timeslotsService: TimeslotsService,
    private shopMenuNavService: ShopMenuNavService,
    private globalStateService: GlobalStateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ShopResolverData> {
    setPrerenderReady(false); // it becomes ready when we get the shop items inside the shop page
    this.coreChain = this.findChainBySlug(route);
    if (!this.coreChain) return void this.router.navigate(['/404']);

    const address = this.globalStateService.getAddress();
    if (!address && this.coreChain.vanityUrl && this.coreChain.chainKey) return this.getMainShopFlow();
    if (address && this.coreChain.chainKey) return this.goToClosestShopFlow();
    if (!address && !this.coreChain.vanityUrl && this.coreChain.slug) return this.askForAddressAndFindClosestShop();
  }

  private getMainShopFlow(failedClosest?: boolean): Observable<ShopResolverData> {
    return forkJoin({
      mainShop: this.shopsService.fetchShopByVanityUrl$(this.coreChain.vanityUrl),
      shopsWithChain: this.shopsService.getShopsByChainKey(this.coreChain.chainKey)
    }).pipe(
      map(({ mainShop, shopsWithChain }) => {
        const shopWithChainView = decorateShopForChainView(mainShop, this.coreChain);
        const normalizedShop = normalizeShop(shopWithChainView);
        const decoratedShop = this.shopService.shopPromoDecorator(normalizedShop);
        return { shop: decoratedShop, shopsWithChain, failedClosest };
      }),
      tap(({ shop }) => {
        // todo refactor shop service shouldn't be global but is used in checkout
        this.shopService.clearServiceStateWhenGoingToDifferentShop(shop);
        this.shopMenuNavService.clearState();
        this.shopService.setShop(shop);
        const maxVolume = getShopCartMaxVolume(shop);
        this.cartService.initializeCart({ shop, maxVolume });
        this.timeslotsService.setTimeslots((shop.timeslots ?? []).filter((t) => t.isAvailable));
      }),
      catchError(() => {
        void this.router.navigate(['/']);
        return of(null as ShopResolverData);
      })
    );
  }

  // we execute this flow when user has an address
  private goToClosestShopFlow(): Observable<ShopResolverData> {
    return this.shopsService.getClosestShopByChainKey(this.coreChain.chainKey).pipe(
      switchMap((shop) => {
        if (shop) {
          this.goToShopPage(shop);
          return of({ shop, failedClosest: false });
        }

        // if the address has no surrounding shops
        if (this.coreChain.vanityUrl && this.coreChain.chainKey) {
          return this.getMainShopFlow(true);
        } else {
          void this.router.navigate(['/']);
          return of(null as ShopResolverData);
        }
      })
    );
  }

  private askForAddressAndFindClosestShop(): Observable<ShopResolverData> {
    return this.addressesService.initiateAddSmallAddressDialogFlow$().pipe(
      switchMap((response) => {
        if (!response?.address) {
          void this.router.navigate(['/404']);
          return of(null);
        }
        return this.goToClosestShopFlow();
      })
    );
  }

  private goToShopPage(shop: Shop): void {
    if (!shop) return;
    const vanityUrl = shop.vanity_url;
    const locationKey = shop.locationKey;
    if (!vanityUrl || !locationKey) return;
    void this.router.navigate(['/delivery', locationKey, vanityUrl]);
  }

  private findChainBySlug(route: ActivatedRouteSnapshot): Chain {
    const chainSlug = route.params.chainSlug as string;
    const coreChains = this.coreService.chains.getValue();
    return coreChains.find((chain) => chain.slug === chainSlug);
  }
}
