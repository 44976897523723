import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription, combineLatest, forkJoin, of, zip, distinctUntilChanged, first, Observable } from 'rxjs';
import { catchError, finalize, map, tap, startWith, skip, take } from 'rxjs/operators';
import {
  OrdersService,
  ShopsService,
  PromoBannersService,
  CampaignTimerService,
  ContestsService,
  DialogService,
  HomeSectionsService,
  TimeslotsService,
  AnalyticsService,
  CouponsService,
  GlobalPopupsService
} from '@box-core/services';
import {
  saveHomeViewBAFBannerSeen,
  removeCollectionCampaignsSession,
  HomeSection,
  getShopsArrayIdentifier,
  generateLocationShopsDeliveryAnchors,
  generateLocationCuisinesDeliveryAnchors,
  setPrerenderReady
} from '@box/utils';
import {
  Shop,
  PromoCampaign,
  Contest,
  APIError,
  GAPromotionConfig,
  SingleBannerConfiguration,
  PromoBanner,
  Coupon,
  DeliveryAnchor,
  Location,
  Cuisine,
  BoxNavigationOptions
} from '@box-types';
import { DiscoverFiltersService } from '@box-core/services/discover-filters.service';
import { HomePageService } from './home-page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HomePagePopupsService } from '@box-core/pages/home/home-page-popups.service';
import { UrgentConditionNotificationService } from '@box-core/services/urgent-condition-notification.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { LocationsService } from '@box-delivery/services';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'home-page',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  providers: [HomePageService, HomePagePopupsService]
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') public pageWrapper = 'page-wrapper';
  public isAreaSupported: boolean;
  public homeSections: HomeSection[];
  public promoCampaigns: PromoCampaign[];
  public shops: Shop[];
  public loading: boolean;
  public isMobileScreen: boolean;
  public coupons: Coupon[];
  public cuisines: Cuisine[] = [];
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1;
  public cuisinesAnchors: DeliveryAnchor[];
  public newShopsAnchors: DeliveryAnchor[];
  public chainShopsAnchors: DeliveryAnchor[];
  public location: Location;
  public isSeoLocationPage: boolean;
  public boxNavigationOptions: BoxNavigationOptions;

  private shopsSubscription: Subscription;
  private homeSubscription: Subscription;
  private breakPointObserverSubscription: Subscription;
  private collectionCampaignsDialogSubscription: Subscription;
  private pageSubscription: Subscription;
  private cuisinesSubscription: Subscription;
  private cuisinesAndBrandsSubscription: Subscription;
  private homePagePopupsSubscription: Subscription;

  constructor(
    private globalPopupsService: GlobalPopupsService,
    private dialogService: DialogService,
    private ordersService: OrdersService,
    private shopsService: ShopsService,
    private promoBannersService: PromoBannersService,
    private breakpointObserver: BreakpointObserver,
    private discoverFiltersService: DiscoverFiltersService,
    private campaignTimerService: CampaignTimerService,
    private homePageService: HomePageService,
    private contestsService: ContestsService,
    private router: Router,
    private homeSectionsService: HomeSectionsService,
    private timeslotsService: TimeslotsService,
    private analyticsService: AnalyticsService,
    private homePagePopupsService: HomePagePopupsService,
    private urgentConditionNotificationService: UrgentConditionNotificationService,
    private couponsService: CouponsService,
    private globalStateService: GlobalStateService,
    private locationsService: LocationsService,
    private breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    setPrerenderReady(false);
    this.isSeoLocationPage = this.locationsService.isSeoLocationPage();
    this.homePageService.setMetaTags();
    this.setBreakPointObserverSubscription();
    this.setHomePagePopupsSubscription();
    this.setCollectionCampaignsDialogSubscription();
    this.setHomeSubscription();
    this.couponsService.removeLastSelectedCouponFromStorage();
    this.setBoxNavigationOptions();
  }

  ngAfterViewInit(): void {
    this.triggerAnalyticsEvent();
  }

  ngOnDestroy(): void {
    this.shopsSubscription?.unsubscribe();
    this.homeSubscription?.unsubscribe();
    this.collectionCampaignsDialogSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
    this.homePagePopupsSubscription?.unsubscribe();
    this.pageSubscription?.unsubscribe();
    this.cuisinesAndBrandsSubscription?.unsubscribe();
    this.cuisinesSubscription?.unsubscribe();

    this.urgentConditionNotificationService.removeHomeNotification();
    this.globalStateService.clearLocation();
  }

  public onNavigateToDiscover(): void {
    this.triggerButtonAnalyticsEvents('select_promotion');
    this.isSeoLocationPage
      ? this.router.navigate(['estiatoria'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['/discover']);
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      this.isMobileScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    });
  }

  private setHomeSubscription(): void {
    this.loading = true;

    forkJoin({
      promoBanners: this.promoBannersService.fetchPromoBanners().pipe(catchError(() => of([] as PromoBanner[]))),
      contests: this.contestsService.getContests().pipe(catchError(() => of([] as Contest[]))),
      orders: this.ordersService.getOrderHistory$(),
      sections: this.homeSectionsService.getHomeSections$(),
      discoverFilters: this.discoverFiltersService.getDiscoverFilters$()
    }).subscribe(({ promoBanners, contests }) => {
      this.shopsSubscription = combineLatest({
        address: this.globalStateService.address$,
        timeslot: this.timeslotsService.timeslot$,
        happyHourState: this.campaignTimerService.whenCampaignIsEnabled$('happy_hour').pipe(startWith(() => null)),
        user: this.globalStateService.user$,
        coupons: this.globalStateService.availableCoupons$
      }).subscribe(({ address, timeslot }) => {
        this.loading = true;

        this.getShops$()
          .pipe(
            catchError((error: APIError) => {
              this.dialogService.openErrorDialog(error);
              return of([] as Shop[]);
            }),
            finalize(() => (this.loading = false)),
            tap((shops: Shop[]) => {
              this.shops = shops;
              this.discoverFiltersService.init(shops, []);
              this.isAreaSupported = shops.length > 0;
              const location = this.globalStateService.getLocation();
              if (this.isSeoLocationPage && location) {
                this.initializeLandingPage(location, shops);
              }
            }),
            map((shops) => {
              const initializedPromoBanners = this.promoBannersService.initializePromoBanners(
                promoBanners,
                address,
                shops
              );
              this.globalStateService.setPromoBanners(initializedPromoBanners);
              return { shops, banners: initializedPromoBanners };
            }),
            map(({ shops }) => {
              const decoratedCoupons = this.globalStateService.getDecoratedCoupons();
              return this.homePageService.decorateFilterAndSortSections(shops, contests, decoratedCoupons);
            })
          )
          .subscribe((homeSections) => {
            this.homeSections = homeSections;
          });
      });
    });
  }

  private initializeLandingPage(location: Location, shops: Shop[]): void {
    this.location = location;
    this.locationsService.setLocationPageMetaTags(this.location);
    this.breadCrumbService.addBreadcrumbs({ location: location.name });
    const newShops = shops.filter((shop) => shop.isNew);
    const chainShops = shops.filter((shop) => shop.chain);
    this.cuisines = this.globalStateService.getCuisines();
    this.cuisinesAnchors = generateLocationCuisinesDeliveryAnchors(this.location, this.cuisines);
    this.chainShopsAnchors = generateLocationShopsDeliveryAnchors(this.location, chainShops);
    this.newShopsAnchors = generateLocationShopsDeliveryAnchors(this.location, newShops);

    setPrerenderReady(true);
  }

  /**
   Is executed after the user changes address and the shops are updated
   and we want this one to only run after the user changes address.
   zip makes sure that both shops and addresses have changed in a pairwise function
   there is a risk the 2 observables, might go out of sync, and the shops correspond to the previous address
   if we don't add the skip 1 rule, the dialog will open when we return to the home page
   the guest user updates their loyalty events on the first visit,
   which results in an update to the shops. But the list of the shops is the same.
   Fot that reason we use a shops array identifier to filter the updates
   */
  private setCollectionCampaignsDialogSubscription(): void {
    const homePagePopupsCompleted$ = this.globalPopupsService.homePopupsServiceState$.pipe(
      first((state) => state === 'completed')
    );
    homePagePopupsCompleted$.subscribe(() => {
      this.collectionCampaignsDialogSubscription = zip([
        this.globalStateService.shops$.pipe(
          distinctUntilChanged((prevShops: Shop[], currShops: Shop[]) => {
            // skip condition
            return getShopsArrayIdentifier(prevShops) === getShopsArrayIdentifier(currShops);
          })
        ),
        this.globalStateService.address$
      ])
        .pipe(skip(1))
        .subscribe(() => {
          // this allows the collections campaign dialog to reappear
          removeCollectionCampaignsSession();
          this.homePageService.showCollectionCampaignsDialog$().subscribe();
        });
    });
  }

  private getShops$(): Observable<Shop[]> {
    if (this.isSeoLocationPage) {
      const locationKey = this.activatedRoute.snapshot.params.locationKey;
      return this.locationsService.getShopsByLocationKey$(locationKey);
    } else {
      return this.shopsService.getShops$();
    }
  }

  private setHomePagePopupsSubscription(): void {
    this.homePagePopupsSubscription = zip([
      this.globalStateService.address$,
      this.globalStateService.shops$.pipe(skip(1)),
      this.globalStateService.user$,
      this.globalStateService.promoBanners$.pipe(skip(1)),
      this.globalStateService.orderHistory$,
      this.globalStateService.promoCampaigns$
    ])
      .pipe(take(1))
      .subscribe(() => {
        this.homePagePopupsService.initializePopups();
      });
  }

  public onRemoveSection(section: HomeSection): void {
    const inform$ = () => {
      if (section.type !== 'singleBanner') return of(null);
      return this.dialogService
        .openInfoDialog({
          title: 'BOX',
          messages: ['you_can_find_the_friends_invitation_in_your_profile_at_any_time']
        })
        .afterClosed()
        .pipe(tap(() => saveHomeViewBAFBannerSeen()));
    };

    inform$().subscribe(() => {
      this.homeSections = this.homeSections.filter((s) => s._id !== section._id);
    });
  }

  public onCtaButtonClicked(singleBannerConfig: SingleBannerConfiguration): void {
    this.triggerBannerButtonAnalyticsEvents(singleBannerConfig);
  }

  public onSingleBannerEnteredViewport(singleBannerConfig: SingleBannerConfiguration): void {
    this.triggerBannerAnalyticsEvents(singleBannerConfig);
  }

  public onSeeAllButtonEnteredViewport(): void {
    this.triggerButtonAnalyticsEvents('view_promotion');
  }

  private triggerAnalyticsEvent(): void {
    const gaConfig = {
      creative_name: '',
      creative_slot: this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home',
      promotion_id: '',
      promotion_name: 'tabs'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerBannerAnalyticsEvents(singleBannerConfig: SingleBannerConfiguration): void {
    const gaConfig = {
      creative_name: singleBannerConfig.title,
      creative_slot: this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home',
      promotion_id: '',
      promotion_name: singleBannerConfig.slug
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerBannerButtonAnalyticsEvents(singleBannerConfig: SingleBannerConfiguration): void {
    const gaConfig = {
      creative_name: singleBannerConfig.cta,
      creative_slot: this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home',
      promotion_id: '',
      promotion_name: singleBannerConfig.slug
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }

  private triggerButtonAnalyticsEvents(eventName: string): void {
    const gaConfig = {
      creative_name: 'Δες όλα τα καταστήματα',
      creative_slot: this.locationsService.isSeoLocationPage() ? 'discover_landing' : 'discover_home',
      promotion_id: '',
      promotion_name: 'see_all_link'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }

  private setBoxNavigationOptions(): void {
    const locationKey = this.activatedRoute.snapshot.params.locationKey;
    this.boxNavigationOptions = this.locationsService.getBoxNavigationOptions(locationKey);
  }
}
