import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, zip, combineLatest } from 'rxjs';
import { ShopService, DeliveryMethodService, translate } from '@box-core/services';
import { Order, Shop, Category, ShopMenuNavItemChange, ShopMenuNavItem } from '@box-types';
import { ShopMenuNavService, ShopMenuPageService } from '../../services';
import { filter, take, tap, map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ShopPageService } from '@box-delivery/services/shop-page.service';
import { generateShopInfo, getSelectedCategoryById } from '@box/utils';
import { currencyCode } from '@box-core/services/currency.service';

@Component({
  selector: 'shop-menu',
  templateUrl: './shop-menu.page.html',
  styleUrls: ['./shop-menu.page.scss']
})
export class ShopMenuPage implements OnInit, OnDestroy {
  public shop: Shop;
  public categories: Category[] = [];
  public itemsType: 'regular' | 'supermarket';
  public searchResultsIsOpen: boolean;
  public searchPlaceholder: string;
  public description: string;
  public showNavMobile: boolean;
  public fetchShopItemsCompleted: boolean;

  private ordersCategoriesSubscription: Subscription;
  private layoutSubscription: Subscription;
  private deliveryMethodSubscription: Subscription;
  private fetchShopItemsSubscription: Subscription;
  private categoryQueryParamsSubscription: Subscription;
  private scrollOffset: number;

  constructor(
    private shopService: ShopService,
    private activatedRoute: ActivatedRoute,
    private shopMenuNavService: ShopMenuNavService,
    private breakpointObserver: BreakpointObserver,
    private shopPageService: ShopPageService,
    private deliveryMethodService: DeliveryMethodService,
    private shopMenuPageService: ShopMenuPageService
  ) {}

  ngOnInit(): void {
    this.shop = this.shopService.getShop();
    this.itemsType = this.shop.isSuperMarket ? 'supermarket' : 'regular';
    this.searchPlaceholder = this.shop.isSuperMarket ? 'search_category_or_product' : 'search_product_or_ingredient';
    this.setLayoutSubscription();
    this.setDeliveryMethodSubscription();
    this.setOrdersCategoriesSubscription();
    this.setFetchShopItemsSubscription();
    this.showNavMobile = !this.shop.categoryView;
  }

  ngOnDestroy(): void {
    this.layoutSubscription?.unsubscribe();
    this.ordersCategoriesSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
    this.fetchShopItemsSubscription?.unsubscribe();
    this.categoryQueryParamsSubscription?.unsubscribe();
  }

  private setOrdersCategoriesSubscription(): void {
    this.ordersCategoriesSubscription = combineLatest([
      this.shopService.categories.pipe(tap((categories) => (this.categories = categories))),
      this.shopService.orders
    ]).subscribe(([categories, orders]) => {
      this.setMenuNav(categories, orders);
      this.shopMenuNavService.updateSelectedParentCategory(categories);
    });
  }

  private setFetchShopItemsSubscription(): void {
    this.fetchShopItemsSubscription = this.shopService.fetchShopItemsCompleted.pipe().subscribe((completed) => {
      this.setCategoryQueryParamsSubscription();
      this.showMyoIfThereAreQueryParams();
      this.fetchShopItemsCompleted = completed;
    });
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = combineLatest([
      this.shopService.shop,
      this.deliveryMethodService.deliveryMethod
    ]).subscribe(([shop, method]) => {
      this.description = generateShopInfo(shop, method, translate, currencyCode);
    });
  }

  private setLayoutSubscription(): void {
    const layoutChanges = this.breakpointObserver.observe(['(max-width: 992px)']);
    this.layoutSubscription = layoutChanges.subscribe(() => {
      const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 992px)');
      const offsetTarget = isSmallScreen ? 'mobile' : 'desktop';
      this.scrollOffset = this.shopMenuNavService.getScrollOffset(offsetTarget);
    });
  }

  public onMenuNavItemChange(data: ShopMenuNavItemChange): void {
    const { item, subItem } = data;
    if (this.shop.categoryView) return this.onCategoryViewNavItemChange(item, subItem);
    return this.onFoodMenuNavItemChange(item, subItem);
  }

  public onFoodMenuNavItemChange(item: ShopMenuNavItem, subItem?: ShopMenuNavItem): void {
    if (!item?._id) return;
    const target: string = subItem?._id ? subItem._id : item._id;
    this.shopMenuNavService.scrollTo(target, this.scrollOffset);
  }

  public onCategoryViewNavItemChange(item: ShopMenuNavItem, subItem?: ShopMenuNavItem): void {
    if (!item?._id) return;
    const selectedCategories = getSelectedCategoryById(this.categories, item._id, subItem?._id);
    if (this.isExtraCateogryViewNavItem(item?._id)) {
      const target: string = item._id;
      this.shopMenuNavService.scrollTo(target, this.scrollOffset);
      return;
    }
    this.shopMenuNavService.setSelectedParentCategory(selectedCategories?.parentCategory);
    this.shopMenuNavService.setSelectedSubCategory(selectedCategories?.subCategory);
  }

  private isExtraCateogryViewNavItem(itemId: string): boolean {
    if (itemId === 'reminder_category' || itemId === 'orders_category') return true;
    return false;
  }

  private setMenuNav(categories: Category[], orders: Order[]): void {
    if (!categories?.length) return;
    const menuNavItems: ShopMenuNavItem[] = [];
    menuNavItems.push(...this.shopMenuNavService.categoriesToShopMenuNavItems(categories));
    const extraFixedMenuNavItems = this.shopPageService.generateExtraCategories(orders);
    this.shopMenuNavService.initializeMenu(menuNavItems, extraFixedMenuNavItems);
  }

  private showMyoIfThereAreQueryParams(): void {
    if (this.shopService.getShop()?.chainView) return;

    const productId: string = this.activatedRoute.snapshot.queryParams.productId as string;
    const offerId: string = this.activatedRoute.snapshot.queryParams.offerId as string;

    if (!this.shop.categoryView) {
      if (productId) return this.shopMenuPageService.openProductMYOById(productId);
      if (offerId) return this.shopMenuPageService.openOfferWizardById(offerId);
      return;
    }

    // Supermarkets with category view flag do not have the entire items catalog available
    if (productId) return this.shopMenuPageService.getProductAndOpenMyo(productId);
    if (offerId) return this.shopMenuPageService.getOfferAndOpenMyo(offerId);
  }

  private setCategoryQueryParamsSubscription(): void {
    this.categoryQueryParamsSubscription = this.activatedRoute.queryParams
      .pipe(map((params) => params['categoryId'] as string))
      .subscribe((categoryId) => {
        if (!categoryId) {
          this.shopMenuNavService.setSelectedParentCategory(null);
          this.shopMenuNavService.setSelectedSubCategory(null);
          return;
        }
        const previousSelectedParentCategory = this.shopMenuNavService.selectedParentCategory.getValue();
        if (previousSelectedParentCategory?._id === categoryId) return;
        const selectedCategoryOptions = getSelectedCategoryById(this.categories, categoryId);
        this.shopMenuNavService.setSelectedParentCategory(selectedCategoryOptions?.parentCategory);
      });
  }

  public onSearchResultsStateChange(searching: boolean): void {
    this.searchResultsIsOpen = searching;
  }

  public onSuperMarketCategorySelected(value: boolean): void {
    this.showNavMobile = value;
  }
}
